import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonImg,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {downloadOutline} from 'ionicons/icons';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {Paper} from '@material-ui/core';
import _ from 'lodash';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const TicketAttachment = props => {
  const {t} = useTranslation('link_app');
  const {match} = props;

  useTitleEffect(match.params.attachmentId);

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the ticket
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.tickets
      .get(match.params.id, {attachments: true})
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Get attachment
  let attachment;
  if (!_.isEmpty(doc._attachments)) {
    attachment = doc._attachments[match.params.attachmentId];
  }

  return (
    <IonPage>
      <Header title={match.params.attachmentId} backButton />
      <IonContent>
        {attachment ? (
          <div className="container-narrow">
            <Paper style={{marginTop: '32px', marginBottom: '16px'}}>
              <IonList>
                <IonItem lines="none">
                  <div className="inner-wrap">
                    <IonImg
                      src={`data:${attachment.content_type};base64,${attachment.data}`}
                      alt=""
                    />
                  </div>
                </IonItem>
              </IonList>
            </Paper>
            <a
              href={`data:${attachment.content_type};base64,${attachment.data}`}
              download={match.params.attachmentId}>
              <IonButton size="default">
                <IonIcon slot="start" icon={downloadOutline} />
                {t('app.download')}
              </IonButton>
            </a>
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TicketAttachment;
