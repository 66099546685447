/* eslint-disable import/no-webpack-loader-syntax */
import React, {useState, useContext, useEffect} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  IonModal,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import Header from '../../components/navigation/Header';
import {Link} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  NativeSelect,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {trashOutline} from 'ionicons/icons';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import placeTypesData from '../../data/place-types';
import useToast from '../../hooks/useToast';
import useAlert from '../../hooks/useAlert';
import Loading from '../../components/utils/Loading';
import {AuthContext} from '../../components/context/AuthContext';
import ReactMapGL, {NavigationControl, Marker, StaticMap} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import useWindowSize from '../../hooks/useWindowSize';
import {ReactComponent as MarkerIcon} from '../../assets/marker.svg';
import {ReactComponent as ChargerIcon} from '../../assets/charging_icon_maps.svg';
import _ from 'lodash';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Places = props => {
  const {permissions} = props;
  const {t} = useTranslation('link_app');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    place: '',
  });
  const {match} = props;
  const [segment, setSegment] = useState('list');
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });
  const [mapStyle, setMapStyle] = useState('default');
  const MAPBOX_TOKEN =
    'pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw';
  const [, height] = useWindowSize();
  const [modal, setModal] = useState('');
  const toast = useToast();
  const alert = useAlert();

  useTitleEffect(t('nav.places'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.places, match, props.isLoading, setIsAuth]);

  // const places = places

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Filters
  // const [types, setTypes] = useState([])
  // const [operators, setOperators] = useState([])
  const [placeNames, setPlaceNames] = useState([]);
  useEffect(() => {
    // Get properties from all places
    places.forEach(item => {
      if (item.doc) {
        if (
          placeNames.indexOf(item.doc?.name) === -1 &&
          item.doc?.name !== ''
        ) {
          setPlaceNames([...placeNames, item.doc?.name].sort());
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, places]);

  // Filtered places
  let filteredPlaces = places;
  if (search !== '') {
    filteredPlaces = places.filter(item => {
      return (
        item.doc.site_id?.toString().includes(search) ||
        item.doc.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.doc.postal_address?.toLowerCase().includes(search.toLowerCase()) ||
        item.doc.address?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }
  if (filters.place !== '') {
    filteredPlaces = places.filter(item => {
      return item.doc.name === filters.place;
    });
  }

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          place: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  // Handle place delete
  const deletePlace = selectedPlace => {
    if (selectedPlace.type === 'site') {
      databases.places
        .get(selectedPlace._id)
        .then(result => {
          result._deleted = true;
          return databases.places.put(result);
        })
        .then(() => {
          toast(` "${selectedPlace.name}" ${t('toast.removed_from_database')}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_place')} "${selectedPlace.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_place')} "${selectedPlace.name}"`,
            err,
          );
          Sentry.captureException(err);
        });
    } else if (selectedPlace.type === 'warehouse') {
      databases.warehouses
        .get(selectedPlace._id)
        .then(result => {
          result._deleted = true;
          return databases.warehouses.put(result);
        })
        .then(() => {
          toast(` "${selectedPlace.name}" ${t('toast.removed_from_database')}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_place')} "${selectedPlace.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_place')} "${selectedPlace.name}"`,
            err,
          );
          Sentry.captureException(err);
        });
    } else if (selectedPlace.type === 'studio') {
      databases.studios
        .get(selectedPlace._id)
        .then(result => {
          result._deleted = true;
          return databases.studios.put(result);
        })
        .then(() => {
          toast(`"${selectedPlace.name}" ${t('toast.removed_from_database')}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_place')} "${selectedPlace.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_place')} "${selectedPlace.name}"`,
            err,
          );
          Sentry.captureException(err);
        });
    }
  };

  // Set map viewport
  useEffect(() => {
    if (
      segment === 'map' &&
      filteredPlaces.length > 0 &&
      viewport.latitude === 0 &&
      viewport.longitude === 0
    ) {
      const lat = filteredPlaces[0]?.doc?.coordinates
        ? parseFloat(filteredPlaces[0]?.doc?.coordinates[0])
        : false;
      const long = filteredPlaces[0]?.doc?.coordinates
        ? parseFloat(filteredPlaces[0]?.doc?.coordinates[1])
        : false;

      if (long && lat) {
        setViewport({
          zoom: 8,
          latitude: lat,
          longitude: long,
        });
      } else {
        setViewport({latitude: 63.978652, longitude: 25.7587, zoom: 8});
      }
    }
  }, [filteredPlaces, segment, viewport]);

  // Group places by coordinates
  const groupedPlaces = _.groupBy(
    filteredPlaces,
    item => item.doc?.coordinates,
  );

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredPlaces.length - page * rowsPerPage);

  return (
    <IonPage>
      <Header
        title={t('places.places_list')}
        searchBar
        search={search}
        setSearch={setSearch}
        segments={[
          {name: t('tickets.segment_list'), value: 'list'},
          {name: t('tickets.segment_map'), value: 'map'},
        ]}
        segment={segment}
        setSegment={setSegment}
        placeholder={t('places.search')}
        // buttons={[
        // 	{ link: "/places/site/new-site", permission: "places", name: t("places.new_place")},
        // ]}
        permissions={permissions}
      />
      <IonContent>
        {!isFetching ? (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                {clearButton}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                {segment === 'list' && (
                  <TableContainer>
                    <Table className="places-table">
                      <TableHead className="places-table-head">
                        <TableRow>
                          <StyledTableHeadCell>
                            {t('places.id')}
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            <FormControl className="">
                              <InputLabel htmlFor="place">
                                {t('places.place')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.place}
                                onChange={e =>
                                  handleFilterChange(e.target.value, 'place')
                                }
                                inputProps={{
                                  name: 'place',
                                  id: 'place',
                                }}
                                className={
                                  filters.place === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {placeNames.map((item, i) => {
                                  return (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            {t('places.address')}
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            {t('places.postal_address')}
                          </StyledTableHeadCell>
                          <StyledTableHeadCell></StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredPlaces
                          .sort((a, b) => {
                            return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map((place, i) => {
                            return (
                              <TableRow key={i} hover={true}>
                                <TableCell align="left">
                                  <Link to={`/places/${place.doc._id}`}>
                                    {place.doc.site_id !== ''
                                      ? place.doc.site_id
                                      : '-'}
                                  </Link>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Link to={`/places/${place.doc._id}`}>
                                    {place.doc.name !== ''
                                      ? place.doc.name
                                      : '-'}
                                  </Link>
                                </TableCell>
                                {/* <TableCell align="left">
																	<Link to={`/places/${place.doc._id}`}>{place.doc.type !== "" ? t(placeTypesData[placeTypesData.findIndex((x) => x.id === place.doc.type)]?.name) : "-"}</Link>
																</TableCell> */}
                                <TableCell align="left">
                                  <Link to={`/places/${place.doc._id}`}>
                                    {place.doc.address !== ''
                                      ? place.doc.address
                                      : '-'}
                                  </Link>
                                </TableCell>
                                <TableCell align="left">
                                  <Link to={`/places/${place.doc._id}`}>
                                    {place.doc.postal_address !== ''
                                      ? place.doc.postal_address
                                      : '-'}
                                  </Link>
                                </TableCell>
                                <TableCell align="right">
                                  <IonButton
                                    size="default"
                                    fill="clear"
                                    color="medium"
                                    disabled={!permissions?.canDelete}
                                    onClick={() =>
                                      alert(
                                        t('alert.notice'),
                                        `${t(
                                          'alert.are_you_sure_you_want_to_delete',
                                        )} ${t('alert.place')} "${
                                          place.doc.name
                                        }"`,
                                        [
                                          t('app.go_back'),
                                          {
                                            text: 'Ok',
                                            handler: () =>
                                              deletePlace(place.doc),
                                          },
                                        ],
                                      )
                                    }>
                                    <IonIcon
                                      slot="icon-only"
                                      color="medium"
                                      style={{height: '22px'}}
                                      icon={trashOutline}
                                    />
                                  </IonButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={7} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      colSpan={7}
                      rowsPerPageOptions={[5, 10, 15, 100]}
                      component="div"
                      count={places.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                )}
                {segment === 'map' && (
                  <ReactMapGL
                    className="places-map"
                    {...viewport}
                    width="100%"
                    height={`${height - 226}px`}
                    style={{position: 'relative'}}
                    onViewportChange={setViewport}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    mapStyle={
                      mapStyle === 'default'
                        ? 'mapbox://styles/mapbox/outdoors-v11'
                        : 'mapbox://styles/mapbox/satellite-v9'
                    }>
                    {Object.values(groupedPlaces).map((item, i) => {
                      const place = places.find(
                        place => place?.doc?.site_id === item[0]?.doc?.site_id,
                      );
                      if (
                        item.length > 1 &&
                        place &&
                        place.doc?.coordinates &&
                        place.doc?.coordinates[0] !== '' &&
                        place.doc?.coordinates[1] !== ''
                      ) {
                        return (
                          <Marker
                            key={i}
                            latitude={parseFloat(place.doc.coordinates[0])}
                            longitude={parseFloat(place.doc.coordinates[1])}
                            offsetLeft={-20}
                            offsetTop={-48}>
                            <button
                              className="marker-list-indicator primary"
                              onClick={() => setModal(`modal-${i + 1}`)}>
                              <p>{item.length}</p>
                            </button>
                          </Marker>
                        );
                      } else if (
                        place &&
                        place.doc?.coordinates &&
                        place.doc?.coordinates[0] !== '' &&
                        place.doc?.coordinates[1] !== ''
                      ) {
                        return (
                          <Marker
                            key={i}
                            latitude={parseFloat(place.doc.coordinates[0])}
                            longitude={parseFloat(place.doc.coordinates[1])}
                            offsetLeft={-24}
                            offsetTop={-60}>
                            <div className="marker-inner-wrap">
                              <Link to={`/places/${place.doc._id}`}>
                                <MarkerIcon className="map-marker white" />
                                <div className="marker-status">
                                  <ChargerIcon
                                    className="marker-icon"
                                    color="light"
                                  />
                                  {/* <IonIcon className="marker-icon" color="light" icon={radioOutline} /> */}
                                </div>
                                <div className="marker-title">
                                  <p
                                    className={
                                      mapStyle === 'default' ? 'map' : 'sat'
                                    }>
                                    {place.doc.name}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </Marker>
                        );
                      } else return null;
                    })}
                    <NavigationControl
                      style={{top: '16px', right: '16px'}}
                      showCompass={false}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '94px',
                        right: '16px',
                      }}>
                      <div className="mapboxgl-ctrl mapboxgl-ctrl-group overflow-hidden">
                        <button
                          onClick={() => {
                            mapStyle === 'default'
                              ? setMapStyle('satellite')
                              : setMapStyle('default');
                          }}
                          className="mapboxgl-ctrl-map-style">
                          <StaticMap
                            {...viewport}
                            width="40px"
                            height="40px"
                            onViewportChange={setViewport}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            mapStyle={
                              mapStyle === 'satellite'
                                ? 'mapbox://styles/mapbox/outdoors-v11'
                                : 'mapbox://styles/mapbox/satellite-v9'
                            }
                          />
                          {mapStyle === 'default' ? <p>sat</p> : <p>map</p>}
                        </button>
                      </div>
                    </div>
                  </ReactMapGL>
                )}
                {Object.values(groupedPlaces).map((item, i) => {
                  return (
                    <IonModal
                      key={i}
                      isOpen={modal === `modal-${i + 1}`}
                      onDidDismiss={() => setModal('')}
                      cssClass="marker-list-modal">
                      <div className="table-wrap">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('places.place')}</TableCell>
                              <TableCell>{t('places.id')}</TableCell>
                              <TableCell>{t('places.type')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.map((place, i) => {
                              return (
                                <TableRow
                                  onClick={() => setModal('')}
                                  key={i}
                                  component="tr"
                                  hover={true}>
                                  <TableCell component="th" scope="row">
                                    <Link
                                      to={`/places/${place.doc.type}/${place.doc._id}`}>
                                      {place.doc.name !== ''
                                        ? place.doc.name
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/places/${place.doc.type}/${place.doc._id}`}>
                                      {place.doc.site_id !== ''
                                        ? place.doc.site_id
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Link
                                      to={`/places/${place.doc.type}/${place.doc._id}`}>
                                      {place.doc.type !== ''
                                        ? t(
                                            placeTypesData[
                                              placeTypesData.findIndex(
                                                x => x.id === place.doc.type,
                                              )
                                            ]?.name,
                                          )
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                      <IonButton color="danger" onClick={() => setModal('')}>
                        {t('app.close')}
                      </IonButton>
                    </IonModal>
                  );
                })}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Places;
