import React, {useEffect, useState} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonIcon,
} from '@ionic/react';
import {
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {chevronDownOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {getStationName} from './Helpers';
import useConfig from '../../hooks/useConfig';

const PlaceMonitoring = props => {
  const {t} = useTranslation('link_app');
  const match = props.match;

  // API call
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  const [station] = useState(props.station ?? null);
  const [stations, setStations] = useState(null);

  const {place_monitoring: grafanaUrls} = useConfig();

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place)) {
      setPlace(props.place);
    }
  }, [match, props.place]);

  useEffect(() => {
    if (!props.station && !station && place) {
      let allStationsPanels = place.stations.map((station, i) => {
        if (station?.monitoring_panels && station?.monitoring_panels !== '') {
          let panels = Object.entries(station.monitoring_panels);
          panels = panels.filter(val => !val[0].includes('control_room'));
          return {
            name: station.name,
            station_alias: station.station_alias ?? null,
            type: station.type,
            stationPanels: panels,
          };
        }
        return true;
      });
      setStations(allStationsPanels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  const [accordionExpanded, setAccordionExpanded] = React.useState('');

  const handleAccordionsExpanded = (stationName, expanded) => {
    if (expanded) {
      setAccordionExpanded(stationName);
    } else {
      setAccordionExpanded('');
    }
  };

  const GetSinglePanels = React.memo(props => {
    if (props.type === 'kempower') {
      return (
        <div
          key={props.index}
          className={`ticket-monitoring-container-panel ${props.val[0]}`}>
          {props.val[0] === 'connectors' ? (
            props.val[1].map(connector => {
              return Object.keys(connector).map(key => {
                return (
                  <object
                    key={`monitoring-${key}`}
                    className={key}
                    data={`${grafanaUrls[props.type]}${connector[key]}`}
                    title="Monitoring data"></object>
                );
              });
            })
          ) : (
            <object
              key={`monitoring-${props.val[0]}`}
              data={`${grafanaUrls[props.type]}${props.val[1]}`}
              title="Monitoring data"></object>
          )}
        </div>
      );
    } else if (props.type === 'ocpp') {
      return (
        <>
          {props.val[1].map(connector => {
            return (
              <div className={`ticket-monitoring-panels-inner panel-type-ocpp`}>
                {Object.keys(connector).map(key => {
                  return (
                    <object
                      key={`monitoring-${key}`}
                      className={key}
                      data={`${grafanaUrls[props.type]}${connector[key]}`}
                      title="Monitoring data"></object>
                  );
                })}
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <div
          key={props.index}
          className={`ticket-monitoring-container-panel ${props.val[0]}`}>
          <object
            data={`${grafanaUrls[props.type]}${props.val[1]}`}
            title="Monitoring data"></object>
        </div>
      );
    }
  });

  return (
    <IonGrid className="ion-padding-none ion-margin-top">
      {stations &&
        stations.length > 0 &&
        stations.map((station, i) => {
          return (
            <div key={i}>
              <Accordion
                key={station.type + '-' + i}
                onChange={(e, expanded) => {
                  handleAccordionsExpanded(station.name, expanded);
                }}
                expanded={accordionExpanded === station.name}>
                <AccordionSummary
                  expandIcon={
                    <IonIcon
                      className="ion-margin-right-half"
                      slot="start"
                      icon={chevronDownOutline}
                    />
                  }>
                  <IonText
                    className="ion-margin-right"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}>
                    <IonText>
                      <h5 className="ion-margin-top">
                        {getStationName(station)}
                      </h5>
                    </IonText>
                  </IonText>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={`ticket-monitoring-panels panel-type-${station.type}`}>
                    {station?.stationPanels?.length > 0 &&
                      accordionExpanded === station.name &&
                      station?.stationPanels
                        .filter(val => val[1] !== '')
                        .map((val, i) => {
                          return (
                            <GetSinglePanels
                              key={i}
                              val={val}
                              index={i}
                              type={station.type}
                            />
                          );
                        })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Divider className="ion-margin-top ion-margin-bottom" />
            </div>
          );
        })}
      {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};
export default PlaceMonitoring;
