import React, {useState, useEffect, useCallback, memo} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonModal,
} from '@ionic/react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  TextField,
  FormControl,
} from '@material-ui/core';
import {
  chevronDownOutline,
  trashOutline,
  addOutline,
  caretDownOutline,
} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {getStationName} from '../utils/Helpers';
import useConfig from '../../hooks/useConfig';
import * as Sentry from '@sentry/react';

const PlaceChargingStations = props => {
  const {place, savePlaceChanges, permissions} = props;
  const {t} = useTranslation('link_app');

  const [detailValue, setDetailValue] = useState(1);

  const handleChange = newValue => {
    setDetailValue(parseInt(newValue, 10));
  };

  const [currentPlace, setCurrentPlace] = useState();
  useEffect(() => {
    if (currentPlace === undefined) {
      setCurrentPlace({...place, needsInfo: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const [modal, setModal] = useState('');
  const [acMonitoringPanels, setAcMonitoringPanels] = useState({
    control_room: '',
    panel_station_status: '',
    panel_station_amps: '',
    panel_station_kw: '',
    panel_station_kwh: '',
    panel_station_total_kwh: '',
    panel_station_total_time: '',
  });

  const [dcMonitoringPanels, setDcMonitoringPanels] = useState({
    control_room: '',
    control_room_satellite_status: '',
    panel_station_status: '',
    panel_station_amps: '',
    panel_station_kw: '',
    panel_station_kwh: '',
    panel_station_ratio: '',
    connectors: [
      {
        panel_station_connector_status: '',
        panel_station_connector_kw: '',
        panel_station_connector_kwh: '',
      },
    ],
  });

  const [ocppMonitoringPanels, setOcppMonitoringPanels] = useState({
    control_room: '',
    control_room_satellite_status: '',
    connectors: [
      {
        panel_station_connector_status: '',
        panel_station_connector_kwh: '',
        panel_station_connector_voltage: '',
        panel_station_connector_amps: '',
        panel_station_connector_kw: '',
        panel_station_connector_duration: '',
        panel_station_connector_soc: '',
        panel_station_connector_temperature: '',
      },
    ],
  });

  const charging_cable_statuses = {
    0: 'Connection loss',
    1: 'Unavailable',
    2: 'Disabled',
    3: 'Offline',
    4: 'Ready',
    5: 'Cable Connected',
    6: 'Car Connected',
    7: 'Charging',
    8: 'Charged',
    9: 'Cable Error',
    10: 'Voltage Error',
    11: 'Ventilation Error',
    12: 'Car Error',
    13: 'Meter error',
  };

  const getCableStatus = statusId => {
    return charging_cable_statuses[statusId] ?? statusId;
  };

  useEffect(() => {
    const modalInfo = modal.split('-');
    const modalNumber = modalInfo.pop();
    const stationType = modalInfo.pop();
    if (modal !== '' && modal !== 'modal-text-input') {
      if (currentPlace.stations[modalNumber].monitoring_panels) {
        if (stationType === 'uc') {
          setAcMonitoringPanels(
            currentPlace.stations[modalNumber].monitoring_panels,
          );
        } else if (stationType === 'kempower') {
          setDcMonitoringPanels(
            currentPlace.stations[modalNumber].monitoring_panels,
          );
        } else if (stationType === 'ocpp') {
          setOcppMonitoringPanels(
            currentPlace.stations[modalNumber].monitoring_panels,
          );
        }
      } else {
        setAcMonitoringPanels({
          control_room: '',
          panel_station_status: '',
          panel_station_amps: '',
          panel_station_kw: '',
          panel_station_kwh: '',
        });
        setDcMonitoringPanels({
          control_room: '',
          control_room_satellite_status: '',
          panel_station_status: '',
          panel_station_amps: '',
          panel_station_kw: '',
          panel_station_kwh: '',
          panel_station_ratio: '',
          connectors: [
            {
              panel_station_connector_status: '',
              panel_station_connector_kw: '',
              panel_station_connector_kwh: '',
            },
          ],
        });
        setOcppMonitoringPanels({
          control_room: '',
          control_room_satellite_status: '',
          connectors: [
            {
              panel_station_connector_status: '',
              panel_station_connector_kwh: '',
              panel_station_connector_voltage: '',
              panel_station_connector_amps: '',
              panel_station_connector_kw: '',
              panel_station_connector_duration: '',
              panel_station_connector_soc: '',
              panel_station_connector_temperature: '',
            },
          ],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleMonitoringPanelsChange = (e, type) => {
    if (type === 'uc') {
      setAcMonitoringPanels({
        ...acMonitoringPanels,
        [e.target.id]: e.target.value,
      });
    } else if (type === 'kempower') {
      setDcMonitoringPanels({
        ...dcMonitoringPanels,
        [e.target.id]: e.target.value,
      });
    } else if (type === 'ocpp') {
      setOcppMonitoringPanels({
        ...ocppMonitoringPanels,
        [e.target.id]: e.target.value,
      });
    }
  };

  const handleMonitoringPanelsConnectorChange = (e, index, type) => {
    let monitoringPanels =
      type === 'ocpp' ? {...ocppMonitoringPanels} : {...dcMonitoringPanels};
    monitoringPanels.connectors[index][e.target.id] = e.target.value;
    if (type === 'ocpp') {
      setOcppMonitoringPanels(monitoringPanels);
    } else {
      setDcMonitoringPanels(monitoringPanels);
    }
  };

  const saveConnectorName = useCallback(
    (id, connectorId, connectorName) => {
      const editedPlace = {
        ...currentPlace,
        stations: currentPlace.stations.map(station =>
          station.id === id
            ? {
                ...station,
                connector_names: {
                  ...station.connector_names,
                  [connectorId]: connectorName,
                },
              }
            : station,
        ),
      };
      setCurrentPlace(editedPlace);
      setIsEdited(true);
    },
    [currentPlace],
  );

  const saveModalChanges = (id, type) => {
    // const modalNumber = modal.split('-').pop()
    const editedPlace = {
      ...currentPlace,
      stations: currentPlace.stations.map(station =>
        station.id === id
          ? {
              ...station,
              monitoring_panels:
                type === 'uc'
                  ? acMonitoringPanels
                  : type === 'ocpp'
                  ? ocppMonitoringPanels
                  : dcMonitoringPanels,
            }
          : station,
      ),
    };
    setCurrentPlace(editedPlace);
    setIsEdited(true);
    setModal('');
  };

  const addConnector = type => {
    if (type === 'ocpp') {
      setOcppMonitoringPanels({
        ...ocppMonitoringPanels,
        connectors: [
          ...ocppMonitoringPanels.connectors,
          {
            panel_station_connector_status: '',
            panel_station_connector_kwh: '',
            panel_station_connector_voltage: '',
            panel_station_connector_amps: '',
            panel_station_connector_kw: '',
            panel_station_connector_duration: '',
            panel_station_connector_soc: '',
            panel_station_connector_temperature: '',
          },
        ],
      });
    } else {
      setDcMonitoringPanels({
        ...dcMonitoringPanels,
        connectors: [
          ...dcMonitoringPanels.connectors,
          {
            panel_station_connector_status: '',
            panel_station_connector_kw: '',
            panel_station_connector_kwh: '',
          },
        ],
      });
    }
  };

  const removeConnector = (index, type) => {
    let tempConnectors =
      type === 'ocpp'
        ? [...ocppMonitoringPanels.connectors]
        : [...dcMonitoringPanels.connectors];
    if (tempConnectors.length > 1) {
      tempConnectors.splice(index, 1);
      if (type === 'ocpp') {
        setOcppMonitoringPanels({
          ...ocppMonitoringPanels,
          connectors: tempConnectors,
        });
      } else {
        setDcMonitoringPanels({
          ...dcMonitoringPanels,
          connectors: tempConnectors,
        });
      }
    }
  };

  const SmallAccordionDC = props => {
    if (props.item.connectorsList) {
      return Object.keys(props.item.connectorsList).map((key, i) => {
        let connectorName = '';
        if (props.item?.connector_names) {
          connectorName = props.item?.connector_names[key] ?? '';
        }
        return (
          <Accordion key={props.item.id + '-' + key}>
            <AccordionSummary
              className="place-station small-accordion"
              expandIcon={
                <IonIcon
                  className="ion-margin-right-half"
                  slot="start"
                  icon={caretDownOutline}
                  color="primary"
                />
              }
              aria-controls={`panel-${key}`}
              id={`panel-${key}`}>
              <IonText
                className="ion-margin-right"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}>
                {'Latauskaapeli ' + key}
              </IonText>
              <IonText
                style={{
                  paddingLeft: '0.5rem',
                }}>
                {connectorName ? '(' + connectorName + ')' : ''}
              </IonText>
            </AccordionSummary>
            <AccordionDetails>
              <IonGrid className="small-accordion-details ion-padding-none ion-padding-top-none">
                <IonCol className="ion-padding-none">
                  <IonGrid className="place-data-table ion-padding-none">
                    <ChargingStationInfoRow
                      key={'status'}
                      dataKey={'place.status'}
                      value={props.item.connectorsList[key].status ?? '-'}
                    />
                    <ChargingStationInfoRow
                      key={'type'}
                      dataKey={'place.type'}
                      value={props.item.connectorsList[key].type ?? '-'}
                    />
                    <ChargingStationInfoRow
                      key={'max_power'}
                      dataKey={'station.maximum_power'}
                      value={
                        props.item.connectorsList[key].maxPowerKwActual ?? '-'
                      }
                    />
                  </IonGrid>
                </IonCol>
              </IonGrid>
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      return <></>;
    }
  };

  const SmallAccordionAC = memo(props => {
    if (props.item.connectorsList) {
      return Object.keys(props.item.connectorsList).map(key => {
        let connectorName = '';
        if (props.item?.connector_names) {
          connectorName = props.item?.connector_names[key] ?? '';
        }
        return (
          <Accordion key={props.item.id + '-' + key}>
            <AccordionSummary
              className="place-station small-accordion"
              expandIcon={
                <IonIcon
                  className="ion-margin-right-half"
                  slot="start"
                  icon={caretDownOutline}
                  color="primary"
                />
              }
              aria-controls={`panel-${key}`}
              id={`panel-${key}`}>
              <IonText
                className="ion-margin-right"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}>
                {'Latauskaapeli ' + key}
              </IonText>
              <IonText
                style={{
                  paddingLeft: '0.5rem',
                }}>
                {connectorName ? '(' + connectorName + ')' : ''}
              </IonText>
            </AccordionSummary>
            <AccordionDetails>
              <IonGrid className="small-accordion-details ion-padding-none ion-padding-top-none">
                <IonCol className="ion-padding-none">
                  <IonGrid className="place-data-table ion-padding-none">
                    <ChargingStationInfoRow
                      dataKey={'places.status'}
                      value={
                        getCableStatus(props.item.connectorsList[key].status) ??
                        '-'
                      }
                    />
                    <ChargingStationInfoRow
                      dataKey={'station.allocated_current'}
                      value={
                        props.item.connectorsList[key].allocatedCurrent ?? '-'
                      }
                    />
                  </IonGrid>
                </IonCol>
              </IonGrid>
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      return <></>;
    }
  }, []);

  const ChargingStationInfoRow = props => {
    return (
      <IonRow key={props.dataKey}>
        <IonCol size="4">
          <IonText>{t(`${props.dataKey}`)}</IonText>
        </IonCol>
        <IonCol size="8">
          <IonText className="ion-margin-none ion-margin-bottom-half">
            {props.value}
          </IonText>
        </IonCol>
      </IonRow>
    );
  };

  const handleAccordionChange = panel => {
    console.log(panel);
  };

  const {integrator} = useConfig();
  useEffect(() => {
    const checkData = async () => {
      let placeCopy = {...currentPlace};
      let promises = [];
      placeCopy.stations.forEach(station => {
        promises.push(
          fetch(`${integrator}/stations/${station.id}`, {
            method: 'GET',
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          }),
        );
      });

      Promise.all(promises)
        .then(responses => {
          // Take all responses and analyze them with another Promise.all
          return Promise.all(
            responses.map(response => {
              return response.json();
            }),
          );
        })
        .then(data => {
          // Extracted data
          data
            .filter(stationData => stationData !== null)
            .forEach((stationData, i) => {
              const indexOf = placeCopy.stations.findIndex(
                station => station?.id === stationData?.id,
              );
              if (
                stationData.type === 'kempower' ||
                stationData.type === 'ocpp'
              ) {
                placeCopy.needsInfo = false;
                placeCopy.stations[indexOf] = {
                  ...placeCopy.stations[indexOf],
                  connectorsList:
                    stationData.connectors ||
                    placeCopy.stations[indexOf].connectors,
                  status:
                    stationData.status || placeCopy.stations[indexOf].status,
                  model: stationData.model || placeCopy.stations[indexOf].model,
                  manufacturingInfo:
                    stationData.manufacturingInfo ||
                    placeCopy.stations[indexOf].manufacturingInfo,
                  devices:
                    stationData.devices || placeCopy.stations[indexOf].devices,
                };
              } else if (stationData.type === 'uc') {
                placeCopy.needsInfo = false;
                placeCopy.stations[indexOf] = {
                  ...placeCopy.stations[indexOf],
                  connectorsList: stationData.connectors || null,
                  sessions: stationData.sessions || null,
                  totalKWh: stationData.totalKWh || null,
                  totalTime: stationData.totalTime || null,
                  pdid: stationData.pdid || null,
                };
              }
            });
          setCurrentPlace(placeCopy);
        })
        .catch(error => {
          console.log(error);
          Sentry.captureException(error);
        });
    };
    if (
      currentPlace !== undefined &&
      currentPlace._id &&
      currentPlace.needsInfo
    ) {
      checkData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  return (
    <IonGrid className="ion-padding-none">
      <IonRow style={{marginBottom: '12px'}}>
        <IonCol size="12">
          <div className="half-fixed-header">
            <IonSegment
              scrollable
              onIonChange={e => handleChange(e.detail.value)}
              value={detailValue}
              className="segment-light-blue">
              <IonSegmentButton value="1">
                <IonLabel>{t('place.ac_charging')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="2">
                <IonLabel>{t('place.dc_charging')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          {detailValue === 1 && (
            <div className="ion-margin-top">
              {currentPlace?.stations?.map((item, i) => {
                if (item.type === 'uc') {
                  return (
                    <IonRow key={'uc-' + i} style={{marginBottom: '12px'}}>
                      <IonCol size="12">
                        <Accordion
                          className="place-stations-accordion"
                          onChange={() => handleAccordionChange(item.id)}>
                          <AccordionSummary
                            expandIcon={
                              <IonIcon
                                className="ion-margin-right-half"
                                slot="start"
                                icon={chevronDownOutline}
                              />
                            }>
                            <IonText
                              className="ion-margin-right"
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                              }}>
                              <h3 className="ion-margin-none">{item.name}</h3>
                              <p className="ion-margin-none">
                                {getStationName(item)}
                              </p>
                            </IonText>
                          </AccordionSummary>
                          <AccordionDetails>
                            <IonGrid className="ion-padding-none ion-padding-top ion-padding-bottom">
                              <IonCol>
                                <IonGrid className="place-data-table ion-padding-none">
                                  <ChargingStationInfoRow
                                    key={'pdid'}
                                    dataKey={'station.product_id'}
                                    value={item?.pdid ?? '-'}
                                  />
                                  <ChargingStationInfoRow
                                    key={'connectors'}
                                    dataKey={'place.connectors'}
                                    value={item?.connectors ?? '-'}
                                  />
                                  <IonRow key={'station_alias'}>
                                    <IonCol size="4">
                                      <IonText>
                                        {t('place.station_alias')}
                                      </IonText>
                                    </IonCol>
                                    <IonCol size="8">
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <TextField
                                          id="station_alias"
                                          value={item?.station_alias}
                                          onChange={e => {
                                            setCurrentPlace({
                                              ...currentPlace,
                                              stations:
                                                currentPlace.stations.map(
                                                  station =>
                                                    station.id === item.id
                                                      ? {
                                                          ...station,
                                                          station_alias:
                                                            e.target.value,
                                                        }
                                                      : station,
                                                ),
                                            });
                                            setIsEdited(true);
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                    </IonCol>
                                  </IonRow>
                                  <SmallAccordionAC item={item} />
                                  <h3 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                                    {t('station.settings')}
                                  </h3>
                                  <IonRow>
                                    <IonCol size="4">
                                      <label
                                        htmlFor={`monitoring-panel-id-${i}`}>
                                        {t('place.monitoring_panels')}
                                      </label>
                                    </IonCol>
                                    <IonCol size="8">
                                      <IonButton
                                        className="primary"
                                        onClick={() =>
                                          setModal(`modal-uc-${i}`)
                                        }>
                                        {t('place.edit')}
                                      </IonButton>
                                    </IonCol>
                                  </IonRow>
                                  <h3 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                                    {t('station.connector_alias')}
                                  </h3>

                                  {item.connectorsList &&
                                    Object.keys(item?.connectorsList).map(
                                      key => {
                                        let connectorName = '';
                                        if (item?.connector_names) {
                                          connectorName =
                                            item?.connector_names[key] ?? '';
                                        }
                                        return (
                                          <IonRow key={`connector_name-${key}`}>
                                            <IonCol size="4">
                                              <label
                                                htmlFor={`connectors-${i}`}>
                                                {'Latauskaapeli ' + key}
                                              </label>
                                            </IonCol>
                                            <IonCol size="8">
                                              <FormControl className="row-input">
                                                {/* Station Monitoring panels */}
                                                <TextField
                                                  id={`connector_name-${key}`}
                                                  value={connectorName ?? ''}
                                                  onChange={e => {
                                                    saveConnectorName(
                                                      item.id,
                                                      key,
                                                      e.target.value,
                                                    );
                                                  }}
                                                  disabled={
                                                    !permissions?.places
                                                  }
                                                />
                                              </FormControl>
                                            </IonCol>
                                          </IonRow>
                                        );
                                      },
                                    )}
                                </IonGrid>
                              </IonCol>
                            </IonGrid>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="ion-margin-top" />
                      </IonCol>
                      <IonModal
                        key={'modal-uc-' + i}
                        id={`modal-uc-${i}`}
                        isOpen={modal === `modal-uc-${i}`}
                        onDidDismiss={() => setModal('')}
                        cssClass="station-panel-modal">
                        <IonGrid className="ion-margin-none ion-padding">
                          <IonCol>
                            <IonText>
                              Aseta Grafanan Panel ID:t oikeisiin sarakkeisiin.
                            </IonText>
                            <h5 className="ion-margin-top">
                              {t('place.control_room_monitoring')}
                            </h5>
                            <div className="row half ion-margin-top">
                              <FormControl className="row-input">
                                {/* Station Monitoring panels */}
                                <div>
                                  <label htmlFor="control_room">
                                    {t('place.station_status')}
                                  </label>
                                </div>
                                <TextField
                                  id="control_room"
                                  value={acMonitoringPanels.control_room}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div>

                            <h5>{t('place.stations')}</h5>
                            <div className="row half">
                              <FormControl className="row-input">
                                {/* Station Monitoring panels */}
                                <div>
                                  <label htmlFor="panel_station_status">
                                    {t('place.connectors_status')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_status"
                                  value={
                                    acMonitoringPanels.panel_station_status
                                  }
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel-station-kw">
                                    {t('place.station_amps')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_kw"
                                  value={acMonitoringPanels.panel_station_kw}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div>
                            <div className="row half">
                              <FormControl className="row-input">
                                {/* Station Monitoring panels */}
                                <div>
                                  <label htmlFor="panel_station_amps">
                                    {t('place.station_kw')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_amps"
                                  value={acMonitoringPanels.panel_station_amps}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_kwh">
                                    {t('place.connector_kWh')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_kwh"
                                  value={acMonitoringPanels.panel_station_kwh}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div>
                            <div className="row half">
                              <FormControl className="row-input">
                                {/* Station Monitoring panels */}
                                <div>
                                  <label htmlFor="panel_station_total_kwh">
                                    {t('place.total_kwh')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_total_kwh"
                                  value={
                                    acMonitoringPanels.panel_station_total_kwh
                                  }
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_total_time">
                                    {t('place.total_time')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_total_time"
                                  value={
                                    acMonitoringPanels.panel_station_total_time
                                  }
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div>
                          </IonCol>
                        </IonGrid>
                        <IonRow className="justify-content-center align-items-center">
                          <IonButton
                            className="ion-margin"
                            size="medium"
                            color="danger"
                            onClick={() => setModal('')}>
                            {t('app.back')}
                          </IonButton>
                          <IonButton
                            className="ion-margin"
                            size="medium"
                            color="success"
                            onClick={() =>
                              saveModalChanges(item.id, item.type)
                            }>
                            Ok
                          </IonButton>
                        </IonRow>
                      </IonModal>
                    </IonRow>
                  );
                } else {
                  return <div key={i}></div>;
                }
              })}
            </div>
          )}
          {detailValue === 2 && (
            <div className="ion-margin-top">
              {currentPlace?.stations.map((item, i) => {
                if (item.type === 'kempower' || item.type === 'ocpp') {
                  return (
                    <IonRow key={'dc-' + i} style={{marginBottom: '12px'}}>
                      <IonCol size="12">
                        <Accordion className="place-stations-accordion">
                          <AccordionSummary
                            expandIcon={
                              <IonIcon
                                className="ion-margin-right-half"
                                slot="start"
                                icon={chevronDownOutline}
                              />
                            }>
                            <IonText
                              className="ion-margin-right"
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                              }}>
                              <h3 className="ion-margin-none">{item.name}</h3>
                              <p className="ion-margin-none">
                                {getStationName(item)}
                              </p>
                            </IonText>
                          </AccordionSummary>
                          <AccordionDetails>
                            <IonGrid className="ion-padding-none ion-padding-top ion-padding-bottom">
                              <IonCol>
                                <IonGrid className="place-data-table ion-padding-none">
                                  {/* <ChargingStationInfoDC item={item} /> */}
                                  <ChargingStationInfoRow
                                    dataKey={'station.product_id'}
                                    value={
                                      item?.manufacturingInfo?.productId ?? '-'
                                    }
                                  />
                                  <ChargingStationInfoRow
                                    dataKey={'station.product_name'}
                                    value={
                                      item?.manufacturingInfo?.productName ??
                                      '-'
                                    }
                                  />
                                  <ChargingStationInfoRow
                                    dataKey={'station.model'}
                                    value={item?.model ?? '-'}
                                  />
                                  <ChargingStationInfoRow
                                    dataKey={'station.order_number'}
                                    value={
                                      item?.manufacturingInfo?.orderNumber ??
                                      '-'
                                    }
                                  />
                                  <ChargingStationInfoRow
                                    dataKey={'place.connectors'}
                                    value={item?.connectors - 1 ?? '-'}
                                  />
                                  <ChargingStationInfoRow
                                    dataKey={'place.status'}
                                    value={item?.status ?? '-'}
                                  />
                                  <IonRow key={'station_alias'}>
                                    <IonCol size="4">
                                      <IonText>
                                        {t('place.station_alias')}
                                      </IonText>
                                    </IonCol>
                                    <IonCol size="8">
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <TextField
                                          id="station_alias"
                                          value={item?.station_alias}
                                          onChange={e => {
                                            setCurrentPlace({
                                              ...currentPlace,
                                              stations:
                                                currentPlace.stations.map(
                                                  station =>
                                                    station.id === item.id
                                                      ? {
                                                          ...station,
                                                          station_alias:
                                                            e.target.value,
                                                        }
                                                      : station,
                                                ),
                                            });
                                            setIsEdited(true);
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                    </IonCol>
                                  </IonRow>
                                  <SmallAccordionDC item={item} />
                                  <h3 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                                    {t('station.settings')}
                                  </h3>
                                  <IonRow>
                                    <IonCol size="4">
                                      <label
                                        htmlFor={`monitoring-panel-id-${i}`}>
                                        {t('place.monitoring_panels')}
                                      </label>
                                    </IonCol>
                                    <IonCol size="8">
                                      <IonButton
                                        className="primary"
                                        onClick={() =>
                                          setModal(`modal-${item.type}-${i}`)
                                        }>
                                        {t('place.edit')}
                                      </IonButton>
                                    </IonCol>
                                  </IonRow>
                                  <h3 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                                    {t('station.connector_alias')}
                                  </h3>

                                  {item.connectorsList &&
                                    Object.keys(item?.connectorsList).map(
                                      key => {
                                        let connectorName = '';
                                        if (item?.connector_names) {
                                          connectorName =
                                            item?.connector_names[key] ?? '';
                                        }
                                        return (
                                          <IonRow key={`connector_name-${key}`}>
                                            <IonCol size="4">
                                              <label
                                                htmlFor={`connectors-${i}`}>
                                                {'Latauskaapeli ' + key}
                                              </label>
                                            </IonCol>
                                            <IonCol size="8">
                                              <FormControl className="row-input">
                                                {/* Station Monitoring panels */}
                                                <TextField
                                                  id={`connector_name-${key}`}
                                                  value={connectorName ?? ''}
                                                  onChange={e => {
                                                    saveConnectorName(
                                                      item.id,
                                                      key,
                                                      e.target.value,
                                                    );
                                                  }}
                                                  disabled={
                                                    !permissions?.places
                                                  }
                                                />
                                              </FormControl>
                                            </IonCol>
                                          </IonRow>
                                        );
                                      },
                                    )}
                                </IonGrid>
                              </IonCol>
                            </IonGrid>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="ion-margin-top" />
                      </IonCol>
                      <IonModal
                        key={`modal-${item.type}-${i}`}
                        id={`modal-${item.type}-${i}`}
                        isOpen={modal === `modal-${item.type}-${i}`}
                        onDidDismiss={() => setModal('')}
                        cssClass="station-panel-modal">
                        <IonGrid className="ion-margin-none ion-padding">
                          <IonCol>
                            <IonText>
                              Aseta Grafanan Panel ID:t oikeisiin sarakkeisiin.
                            </IonText>
                            <h5 className="ion-margin-top">
                              {t('place.control_room_monitoring')}
                            </h5>
                            <div className="row half ion-margin-top">
                              <FormControl className="row-input">
                                {/* Station Monitoring panels */}
                                <div>
                                  <label htmlFor="control_room">
                                    {t('place.station_status')}
                                  </label>
                                </div>
                                <TextField
                                  id="control_room"
                                  value={
                                    item.type === 'ocpp'
                                      ? ocppMonitoringPanels.control_room
                                      : dcMonitoringPanels.control_room
                                  }
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              {item.type !== 'ocpp' && (
                                <FormControl className="row-input">
                                  {/* Station Monitoring panels */}
                                  <div>
                                    <label htmlFor="control_room_satellite_status">
                                      {t('place.satellite_status')}
                                    </label>
                                  </div>
                                  <TextField
                                    id="control_room_satellite_status"
                                    value={
                                      dcMonitoringPanels.control_room_satellite_status
                                    }
                                    onChange={e => {
                                      handleMonitoringPanelsChange(
                                        e,
                                        item.type,
                                      );
                                    }}
                                    disabled={!permissions?.places}
                                  />
                                </FormControl>
                              )}
                            </div>

                            {/* <h5>{t('place.station_monitoring')}</h5>
                            <div className="row half">
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_status">
                                    {t('place.station_status')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_status"
                                  value={
                                    dcMonitoringPanels.panel_station_status
                                  }
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_kw">
                                    {t('place.station_kw')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_kw"
                                  value={dcMonitoringPanels.panel_station_kw}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div>
                            <div className="row half">
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_amps">
                                    {t('place.station_amps')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_amps"
                                  value={dcMonitoringPanels.panel_station_amps}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                              <FormControl className="row-input">
                                <div>
                                  <label htmlFor="panel_station_ratio">
                                    {t('place.station_ratio')}
                                  </label>
                                </div>
                                <TextField
                                  id="panel_station_ratio"
                                  value={dcMonitoringPanels.panel_station_ratio}
                                  onChange={e => {
                                    handleMonitoringPanelsChange(e, item.type);
                                  }}
                                  disabled={!permissions?.places}
                                />
                              </FormControl>
                            </div> */}

                            {/* Connector monitoring panels */}
                            <h5>
                              {t('place.connector_monitoring')}
                              <IonButton
                                className="station-panel-add-connector-icon"
                                fill="clear"
                                color="danger"
                                onClick={() => addConnector(item.type)}>
                                <IonIcon
                                  slot="icon-only"
                                  color="success"
                                  icon={addOutline}
                                />
                              </IonButton>
                            </h5>
                            {ocppMonitoringPanels.connectors?.length &&
                              ocppMonitoringPanels.connectors.map(
                                (connector, i) => {
                                  return (
                                    <div
                                      key={item.id + '-monitoring_panels-' + i}
                                      className="row quarter grafana-panel-ids">
                                      <FormControl className="row-input">
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_status'
                                            }>
                                            {t(
                                              `place.panel_station_connector_status`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_status'}
                                          value={
                                            connector.panel_station_connector_status
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_kwh'
                                            }>
                                            {t(
                                              `place.panel_station_connector_kwh`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_kwh'}
                                          value={
                                            connector.panel_station_connector_kwh
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_voltage'
                                            }>
                                            {t(
                                              `place.panel_station_connector_voltage`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_voltage'}
                                          value={
                                            connector.panel_station_connector_voltage
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_amps'
                                            }>
                                            {t(
                                              `place.panel_station_connector_amps`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_amps'}
                                          value={
                                            connector.panel_station_connector_amps
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_kw'
                                            }>
                                            {t(
                                              `place.panel_station_connector_kw`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_kw'}
                                          value={
                                            connector.panel_station_connector_kw
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_duration'
                                            }>
                                            {t(
                                              `place.panel_station_connector_duration`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={
                                            'panel_station_connector_duration'
                                          }
                                          value={
                                            connector.panel_station_connector_duration
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_soc'
                                            }>
                                            {t(
                                              `place.panel_station_connector_soc`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={'panel_station_connector_soc'}
                                          value={
                                            connector.panel_station_connector_soc
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <FormControl className="row-input">
                                        {/* Station Monitoring panels */}
                                        <div>
                                          <label
                                            htmlFor={
                                              'panel_station_connector_temperature'
                                            }>
                                            {t(
                                              `place.panel_station_connector_temperature`,
                                            )}
                                          </label>
                                        </div>
                                        <TextField
                                          id={
                                            'panel_station_connector_temperature'
                                          }
                                          value={
                                            connector.panel_station_connector_temperature
                                          }
                                          onChange={e => {
                                            handleMonitoringPanelsConnectorChange(
                                              e,
                                              i,
                                              item.type,
                                            );
                                          }}
                                          disabled={!permissions?.places}
                                        />
                                      </FormControl>
                                      <IonButton
                                        className="station-panel-remove-icon"
                                        fill="clear"
                                        color="danger"
                                        onClick={() =>
                                          removeConnector(i, item.type)
                                        }>
                                        <IonIcon
                                          slot="icon-only"
                                          color="medium"
                                          icon={trashOutline}
                                        />
                                      </IonButton>
                                    </div>
                                  );
                                },
                              )}
                          </IonCol>
                        </IonGrid>
                        <IonRow className="justify-content-center align-items-center">
                          <IonButton
                            className="ion-margin"
                            size="medium"
                            color="danger"
                            onClick={() => setModal('')}>
                            {t('app.back')}
                          </IonButton>
                          <IonButton
                            className="ion-margin"
                            size="medium"
                            color="success"
                            onClick={() =>
                              saveModalChanges(item.id, item.type)
                            }>
                            Ok
                          </IonButton>
                        </IonRow>
                      </IonModal>
                    </IonRow>
                  );
                } else {
                  return <div key={i}></div>;
                }
              })}
            </div>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        {/* <IonCol>
					<IonButton routerLink="/places" className="ion-margin-right" fill="outline" color="danger">
						{t("app.abort")}
					</IonButton>
				</IonCol> */}
        <IonCol>
          <IonButton
            onClick={() => {
              savePlaceChanges(currentPlace);
              setIsEdited(false);
            }}
            fill="solid"
            color="success"
            disabled={!isEdited}>
            {t('app.save')}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default PlaceChargingStations;
