/* eslint-disable import/no-webpack-loader-syntax */
import React, {useState, useContext, useEffect} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonBadge,
  IonButton,
  IonIcon,
  IonModal,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import Header from '../../components/navigation/Header';
import DateTime from '../../components/utils/DateTime';
import Badge from '../../components/utils/Badge';
import StatusBadge from '../../components/utils/StatusBadge';
import {Link} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  NativeSelect,
  TablePagination,
} from '@material-ui/core';
import {
  trashOutline,
  pauseCircleOutline,
  checkmarkDoneCircleOutline,
  playCircleOutline,
  stopCircleOutline,
  checkmarkCircleOutline,
  fileTrayFullOutline,
} from 'ionicons/icons';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import Loading from '../../components/utils/Loading';
import _ from 'lodash';
import useAlert from '../../hooks/useAlert';
import useToast from '../../hooks/useToast';
import {AuthContext} from '../../components/context/AuthContext';
import ReactMapGL, {NavigationControl, Marker, StaticMap} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import useWindowSize from '../../hooks/useWindowSize';
import MarkerIcon from '../../components/utils/Marker';
import SlaDiff from '../../components/utils/SlaDiff';
import {UserContext} from '../../components/context/UserContext';
import {StartSlaCounter, GetSla} from '../../components/utils/SlaComponent';
import {GetNewTicketStatus} from '../../components/tickets/TicketStatusController';
import {StatusOptions} from '../../components/tickets/TicketStatusController';
import useTitleEffect from '../../hooks/useTitle';

import * as Sentry from '@sentry/react';

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Tickets = props => {
  const {t} = useTranslation('link_app');
  const [user] = useContext(UserContext);
  const {match, permissions} = props;
  const [segment, setSegment] = useState('list');
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });
  const [mapStyle, setMapStyle] = useState('default');
  const MAPBOX_TOKEN =
    'pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw';
  const [, height] = useWindowSize();
  const [modal, setModal] = useState('');
  const toast = useToast();
  const alert = useAlert();

  useTitleEffect(t('nav.tickets'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Status options
  // const status_options = StatusOptions.filter((status) => status.value !== "faulted")
  const status_options = StatusOptions;

  // Filters state
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    error: '',
    sla: '',
    isActive: '',
    operator: '',
    year: '',
    month: '',
    order: '',
    fmService: '',
    placeNames: '',
    chargingStation: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch tickets
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        setTickets(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Fetch places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.places, match, props.isLoading, setIsAuth]);

  // Fetch studios
  const [studios, setStudios] = useState([]);
  useEffect(() => {
    databases.studios
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setStudios(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.studios, match, props.isLoading, setIsAuth]);

  // Fetch all guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.guides]);

  // Filter options
  const [statuses, setStatuses] = useState([]);
  const [errors, setErrors] = useState([]);
  const [slas, setSlas] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [placeNames, setPlaceNames] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);

  // Get place, contact ids, errors etc from ticket
  useEffect(() => {
    tickets.forEach(item => {
      if (
        statuses.indexOf(item.doc?.ticket_status) === -1 &&
        item.doc?.ticket_status !== ''
      ) {
        if (!item.doc?.ticket_status || item.doc?.ticket_status === 'faulted') {
          if (statuses.indexOf('faulted') === -1) {
            setStatuses([...statuses, 'faulted']);
          }
        } else {
          setStatuses([...statuses, item.doc?.ticket_status]);
        }
      }
      if (
        errors.findIndex(error => error.id === item.doc?.error?.id) === -1 &&
        !_.isEmpty(item.doc?.error) &&
        item.doc?.error.name !== ''
      ) {
        setErrors([...errors, item.doc?.error]);
      }
      if (
        slas.indexOf(item.doc?.sla) === -1 &&
        item.doc?.sla !== '' &&
        item.doc?.sla !== undefined
      ) {
        setSlas([...slas, item.doc?.sla]);
      }
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station?.id?.toString() ===
              item.doc?.errors[0].STATION_ID?.toString() ||
            station?.id?.toString() === item.doc?.station?.id?.toString(),
        ),
      );
      if (place) {
        if (
          placeNames.indexOf(place.doc?.name) === -1 &&
          place.doc?.name !== ''
        ) {
          setPlaceNames([...placeNames, place.doc?.name].sort());
        }
        const station = place.doc?.stations.find(
          station =>
            station.id?.toString() ===
              item.doc?.errors[0].STATION_ID?.toString() ||
            station.id?.toString() === item.doc?.station?.id?.toString(),
        );
        if (station) {
          if (
            chargingStations.indexOf(station.name) === -1 &&
            station.name !== ''
          ) {
            setChargingStations([...chargingStations, station.name].sort());
          }
        }
      }
      const year = new Date(
        parseInt(item.doc.time_when_created) * 1000,
      ).getFullYear();
      if (years.indexOf(year.toString()) === -1) {
        setYears([...years, year.toString()]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places, tickets, statuses, errors, slas, years, placeNames]);

  // Get months on year change
  useEffect(() => {
    if (filters.year !== '') {
      tickets.forEach(item => {
        const year = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getFullYear();
        const month = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getMonth();
        if (year.toString() === filters.year) {
          if (months.indexOf(month.toString()) === -1) {
            setMonths([...months, month.toString()]);
          }
        }
      });
    } else if (months.length > 0) {
      setMonths([]);
    }
  }, [filters.year, tickets, months]);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          status: '',
          error: '',
          sla: '',
          isActive: '',
          operator: '',
          year: '',
          month: '',
          order: filters.order,
          fmService: '',
          placeNames: '',
          chargingStation: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  // Filter tickets by active
  const FilterActiveButton = () => {
    return (
      <FormControl className="ion-margin-none background-light-gray">
        <NativeSelect
          value={filters.isActive}
          onChange={e => handleFilterChange(e.target.value, 'isActive')}
          inputProps={{
            name: 'active',
            id: 'active',
          }}
          className="filter-active-select">
          <option value="">{t('tickets.active')}</option>
          <option value="false">{t('tickets.history')}</option>
          <option value="all">{t('app.all')}</option>
        </NativeSelect>
      </FormControl>
    );
  };

  const ColorExplanations = () => {
    return (
      <div className="color-explanations">
        <span className="color-border-explanation exp-manual">
          {t('tickets.manual')}
        </span>
        <span className="color-border-explanation exp-manual-ikea">
          {t('tickets.manual_ikea')}
        </span>
      </div>
    );
  };

  // Handle the filtering
  const [filteredTickets, setFilteredTickets] = useState([]);
  useEffect(() => {
    let filteredTicketsCopy = [...tickets];
    if (search !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return (
          item.doc._id.includes(search) ||
          item.doc.resourced?.findIndex(x =>
            x.name?.toLowerCase().includes(search.toLowerCase()),
          ) > -1 ||
          item.doc.place?.name?.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
    if (filters.status !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        if (filters.status === 'faulted') {
          return (
            !item.doc?.ticket_status || item.doc?.ticket_status === 'faulted'
          );
        }
        return item.doc.ticket_status === filters.status;
      });
    }
    if (filters.sla !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.sla?.toString() === filters.sla;
      });
    }
    if (filters.error !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.error.name === filters.error;
      });
    }
    if (filters.operator !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        const place =
          places[
            places.findIndex(
              place => place.doc?.site_id === item.doc?.place?.site_id,
            )
          ];
        if (place) {
          return (
            place.doc?.systems?.telecommunications?.values[0]?.text ===
            filters.operator
          );
        } else return null;
      });
    }
    if (filters.year !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        const year = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getFullYear();
        return year.toString() === filters.year;
      });
    }
    if (filters.month !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        const month = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getMonth();
        return month.toString() === filters.month;
      });
    }
    if (filters.fmService !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return (
          item.doc.errors.findIndex(err =>
            err.RADIO_CHANNEL?.includes(filters.fmService),
          ) > -1
        );
      });
    }
    if (filters.placeNames !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        const place = places.find(place =>
          place.doc?.stations.some(
            station =>
              station.id?.toString() ===
                item.doc?.errors[0].STATION_ID?.toString() ||
              station.id?.toString() === item.doc?.station?.id?.toString(),
          ),
        );
        if (place) {
          return place.doc?.name.toString() === filters.placeNames;
        } else {
          const placeByName = places.find(
            place => place.doc?.name === item.doc?.place?.name,
          );
          if (placeByName) {
            return placeByName.doc?.name.toString() === filters.placeNames;
          }
        }
        return false;
      });
    }
    if (filters.chargingStation !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        const place = places.find(place =>
          place.doc?.stations.some(
            station =>
              station.id?.toString() ===
                item.doc?.errors[0].STATION_ID?.toString() ||
              station.id?.toString() === item.doc?.station?.id?.toString(),
          ),
        );
        if (place) {
          const station = place.doc?.stations.find(
            station =>
              station.id?.toString() ===
                item.doc?.errors[0].STATION_ID?.toString() ||
              station.id?.toString() === item.doc?.station?.id?.toString(),
          );
          if (station) {
            return station.name.toString() === filters.chargingStation;
          }
        }
        return false;
      });
    }
    if (filters.isActive === '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.is_active === true;
      });
    } else if (filters.isActive === 'false') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.is_active === false;
      });
    } else if (filters.isActive === 'all') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item;
      });
    }
    setFilteredTickets(filteredTicketsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, tickets, search]);

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [emptyRows, setEmptyRows] = useState(0);
  useEffect(() => {
    setEmptyRows(
      rowsPerPage -
        Math.min(rowsPerPage, filteredTickets?.length - page * rowsPerPage),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTickets]);

  // Handle deleting the ticket
  const deleteTicket = ticketId => {
    databases.tickets
      .get(ticketId)
      .then(result => {
        return databases.tickets.put({
          _id: result._id,
          _rev: result._rev,
          _deleted: true,
        });
      })
      .then(() => {
        console.log(
          `${t('toast.ticket')} #${ticketId} ${t(
            'toast.removed_from_database',
          )}`,
        );
        toast(
          `${t('toast.ticket')} #${ticketId} ${t(
            'toast.removed_from_database',
          )}`,
        );
      })
      .catch(err => {
        console.log(`${t('toast.error_updating_ticket')} #${ticketId}:`, err);
        toast(`${t('toast.error_updating_ticket')} #${ticketId}`);
        Sentry.captureException(err);
      });
  };

  // Set map viewport
  useEffect(() => {
    if (
      segment === 'map' &&
      filteredTickets.length > 0 &&
      places.length > 0 &&
      viewport.latitude === 0 &&
      viewport.longitude === 0
    ) {
      // const place = places.concat(studios).findIndex((place) => place.doc?.site_id === filteredTickets[0].doc?.place?.site_id)
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station.id?.toString() ===
              filteredTickets[0].doc?.errors[0].STATION_ID ||
            station.id?.toString() ===
              filteredTickets[0].doc?.station?.id?.toString(),
        ),
      );
      const lat = place?.doc?.coordinates
        ? parseFloat(place?.doc?.coordinates[0])
        : false;
      const long = place?.doc?.coordinates
        ? parseFloat(place?.doc?.coordinates[1])
        : false;

      if (long && lat) {
        setViewport({
          zoom: 8,
          latitude: lat,
          longitude: long,
        });
      } else {
        setViewport({latitude: 63.978652, longitude: 25.7587, zoom: 8});
      }
    }
  }, [filteredTickets, places, segment, viewport, studios]);

  // Group tickets by site id

  const newGrouped = _.groupBy(filteredTickets, item => {
    const place = places.find(place =>
      place.doc?.stations.some(
        station =>
          station.id?.toString() ===
            item.doc?.errors[0]?.STATION_ID?.toString() ||
          station.id?.toString() === item.doc?.station?.id?.toString(),
      ),
    );
    return place?.id || undefined;
  });

  // Handle ticket status change from badge
  const handleTicketStatusChange = async (chosenTicket, newStatus) => {
    let status = chosenTicket.ticket_status ?? '';
    console.log('status:', status, newStatus);
    await GetNewTicketStatus(
      chosenTicket,
      status,
      false,
      false,
      newStatus,
    ).then(new_status => {
      status = new_status;
      console.log('new status', status);
      // setTicket({ ...ticket, ticket_status: new_status })
      databases.tickets
        .get(chosenTicket._id)
        .then(result => {
          let ticketCopy = {
            ...chosenTicket,
            _rev: result._rev,
            ticket_status: status,
          };
          const timestamp = Math.round(new Date() / 1000).toString();
          let newId =
            chosenTicket.log?.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      chosenTicket.log?.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';

          ticketCopy = {
            ...ticketCopy,
            ticket_status: status,
            log: chosenTicket.log
              ? [
                  ...chosenTicket.log,
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: `Työn tila muutettu: ${status}`,
                  },
                ]
              : [
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: `Työn tila muutettu: ${status}`,
                  },
                ],
          };
          return databases.tickets.put(ticketCopy);
        })
        .then(result => {
          console.log(
            `${t('toast.ticket')} #${chosenTicket._id} ${t('toast.updated')}`,
          );
          toast(
            `${t('toast.ticket')} #${chosenTicket._id} ${t('toast.updated')}`,
          );
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${chosenTicket._id}:`,
            err,
          );
          toast(
            `${t('toast.error_updating_ticket')} #${chosenTicket._id}`,
            err,
          );
          Sentry.captureException(err);
        });
    });
    console.log('current status', status);
  };

  const GetErrorName = props => {
    const ticket = props.item;
    let errorName = ticket.doc?.error?.name ?? '';
    if (errorName === '') {
      if (ticket?.doc?.error?.id === '' && guides?.length > 0) {
        const alertname = ticket.doc?.errors
          ? ticket.doc?.errors[0].ALERTNAME
          : '';
        if (alertname && alertname !== '') {
          let alertTypes = [...guides];
          if (alertname.match('Kempower')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('DC -'));
          } else if (alertname.match('Enersense')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('AC -'));
          }
          let alertNameFound = false;
          alertTypes.forEach((val, i) => {
            const guideErrorName = val.doc?.name?.split(/AC - |DC - /).pop();
            if (alertname.toLowerCase().match(guideErrorName.toLowerCase())) {
              errorName = val.doc?.name;
              alertNameFound = true;
              return;
            }
          });
          if (!alertNameFound) {
            let chargerType = '';
            if (alertname.match('Kempower')) {
              chargerType = 'DC';
            } else if (alertname.match('Enersense')) {
              chargerType = 'AC';
            }
            const guide = guides.find(
              guide => guide.doc.name === chargerType + ' - Faulted',
            );
            errorName = guide.doc?.name;
          }
        }
      }
    }
    return <IonText>{errorName !== '' ? errorName : '-'}</IonText>;
  };

  return (
    <IonPage>
      <Header
        title={t('tickets.ticket_list')}
        segments={[
          {name: t('tickets.segment_list'), value: 'list'},
          {name: t('tickets.segment_map'), value: 'map'},
        ]}
        segment={segment}
        setSegment={setSegment}
        searchBar
        search={search}
        setSearch={setSearch}
        placeholder={t('tickets.search')}
        permissions={permissions}
        buttons={[
          {
            link: '/new-ticket',
            permission: 'tickets',
            name: t('tickets.new_ticket'),
          },
          // { link: "/create-report", permission: "reports", name: t("tickets.segment_map")}
        ]}
      />
      <IonContent>
        {!isFetching ? (
          <IonGrid>
            <IonRow>
              <IonCol
                className="ion-padding-left ion-margin-bottom ion-margin-right ion-padding-right "
                size="auto">
                <FilterActiveButton />
              </IonCol>
              <IonCol className="ion-padding-left ion-padding-right">
                <ColorExplanations />
              </IonCol>
              <IonCol className="ion-padding-left ion-padding-right">
                {clearButton}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                {segment === 'list' && (
                  <TableContainer>
                    <Table className="tickets-table">
                      <TableHead className="tickets-table-head">
                        <TableRow>
                          <TableCell>
                            <span style={{position: 'relative', top: '2px'}}>
                              {t('tickets.id')}
                            </span>
                          </TableCell>
                          <TableCell>
                            <FormControl className="">
                              <InputLabel htmlFor="status">
                                {t('tickets.status')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.status}
                                onChange={e =>
                                  handleFilterChange(e.target.value, 'status')
                                }
                                inputProps={{
                                  name: 'status',
                                  id: 'status',
                                }}
                                className={
                                  filters.status === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {status_options
                                  .filter(item => statuses.includes(item.value))
                                  .map((option, i) => {
                                    return (
                                      <option key={i} value={option.value}>
                                        {option.label}
                                      </option>
                                    );
                                  })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl className="">
                              <InputLabel htmlFor="placeNames">
                                {t('places.place')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.placeNames}
                                onChange={e =>
                                  handleFilterChange(
                                    e.target.value,
                                    'placeNames',
                                  )
                                }
                                inputProps={{
                                  name: 'placeNames',
                                  id: 'placeNames',
                                }}
                                className={
                                  filters.placeNames === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {placeNames.map((item, i) => {
                                  return (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl className="">
                              <InputLabel htmlFor="chargingStation">
                                {t('tickets.charging_station')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.chargingStation}
                                onChange={e =>
                                  handleFilterChange(
                                    e.target.value,
                                    'chargingStation',
                                  )
                                }
                                inputProps={{
                                  name: 'chargingStation',
                                  id: 'chargingStation',
                                }}
                                className={
                                  filters.chargingStation === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {chargingStations.map((item, i) => {
                                  return (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl className="">
                              <InputLabel htmlFor="sla">
                                {t('tickets.sla')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.sla}
                                onChange={e =>
                                  handleFilterChange(e.target.value, 'sla')
                                }
                                inputProps={{
                                  name: 'sla',
                                  id: 'sla',
                                }}
                                className={
                                  filters.sla === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {slas.map((item, i) => {
                                  return (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl className="">
                              <InputLabel htmlFor="error">
                                {t('tickets.error')}
                              </InputLabel>
                              <NativeSelect
                                value={filters.error}
                                onChange={e =>
                                  handleFilterChange(e.target.value, 'error')
                                }
                                inputProps={{
                                  name: 'error',
                                  id: 'error',
                                }}
                                className={
                                  filters.error === ''
                                    ? 'input-color-transparent'
                                    : ''
                                }>
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {errors.map((item, i) => {
                                  return (
                                    <option key={i} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell>{t('tickets.errors')}</TableCell>
                          <TableCell>{t('tickets.handlers')}</TableCell>
                          <TableCell>
                            <span
                              className="triangle-wrap"
                              onClick={() => {
                                if (filters.order === '') {
                                  setFilters({...filters, order: 'ascending'});
                                } else {
                                  setFilters({...filters, order: ''});
                                }
                              }}>
                              {t('tickets.created')}
                              <span
                                className={
                                  filters.order === ''
                                    ? 'triangle-down'
                                    : 'triangle-up'
                                }
                              />
                            </span>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredTickets
                          .sort((a, b) => {
                            if (filters.order === '') {
                              return parseInt(b.id) - parseInt(a.id);
                            } else {
                              return parseInt(a.id) - parseInt(b.id);
                            }
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map((item, i) => {
                            // Place operator
                            // const place = places.find((place) => place.doc?.site_id === item.doc?.place?.site_id)
                            let place = places.find(place =>
                              place.doc?.stations.some(
                                station =>
                                  station.id?.toString() ===
                                    item.doc?.errors[0]?.STATION_ID?.toString() ||
                                  station.id?.toString() ===
                                    item.doc?.station?.id?.toString(),
                              ),
                            );
                            if (
                              !place &&
                              item.doc?.place?.site_id !== '' &&
                              item.doc?.place?.site_id !== undefined
                            ) {
                              place = places.find(
                                place =>
                                  place.doc?.site_id ===
                                  item.doc?.place?.site_id,
                              );
                            }
                            // let operator;
                            let station;
                            if (place) {
                              // operator = place.doc?.systems?.telecommunications?.values[0]?.text
                              station = place.doc?.stations.find(
                                station =>
                                  station.id?.toString() ===
                                    item.doc?.errors[0]?.STATION_ID?.toString() ||
                                  station.id?.toString() ===
                                    item.doc?.station?.id?.toString(),
                              );
                            }

                            // Ticket Handlers
                            let handlers = [];
                            if (item.doc?.log?.length > 0) {
                              item.doc.log.forEach(logElem => {
                                if (
                                  handlers.filter(
                                    h => h.name === logElem.user.name,
                                  ).length < 1
                                ) {
                                  handlers.push({
                                    name: logElem.user.name,
                                    metadata: {
                                      firstname_lastname:
                                        logElem.user.metadata
                                          ?.firstname_lastname ?? '-',
                                    },
                                  });
                                }
                              });
                            }

                            const firstLog = item.doc?.log?.find(
                              l => l.id === '1',
                            );

                            return (
                              <TableRow
                                key={i}
                                className={`
                                  ${
                                    item.doc.is_active === false &&
                                    filters.isActive === 'all'
                                      ? 'history-row'
                                      : ''
                                  } ${
                                  firstLog?.event === 'created_ikea'
                                    ? 'manual-ikea'
                                    : ''
                                } ${
                                  firstLog?.event === 'created_manual'
                                    ? 'manual-linkapp'
                                    : ''
                                }`}
                                component="tr"
                                hover={true}
                                title={
                                  firstLog?.event === 'created_ikea'
                                    ? 'Created by end customer'
                                    : firstLog?.event === 'created_manual'
                                    ? 'Manually created'
                                    : ''
                                }>
                                <TableCell>
                                  <Link to={`/tickets/${item.doc._id}`}>
                                    {item.doc._id}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {/* <Link to={`/tickets/${item.doc._id}`}> */}
                                  {/* <Badge doc={item.doc} /> */}
                                  <StatusBadge
                                    doc={item.doc}
                                    handleTicketStatusChange={
                                      handleTicketStatusChange
                                    }
                                  />
                                  {/* </Link> */}
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item?.doc?._id}`}>
                                    {place?.doc?.name ? place.doc?.name : '-'}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item?.doc?._id}`}>
                                    {station?.name ? station.name : '-'}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item.doc?._id}`}>
                                    {item.doc?.sla === '' && '-'}
                                    {StartSlaCounter(
                                      item.doc?.sla,
                                      item.doc?.time_when_created,
                                    ) ? (
                                      <>
                                        {item.doc?.sla !== '' &&
                                          item.doc?.sla !== undefined &&
                                          item.doc?.is_active === false && (
                                            <SlaDiff
                                              id={item.doc?._id}
                                              end={
                                                item.doc?.time_when_completed
                                              }
                                              start={
                                                item.doc?.time_when_created
                                              }
                                              sla={item.doc?.sla}
                                            />
                                          )}
                                        {item.doc?.sla !== '' &&
                                          item.doc?.sla !== undefined &&
                                          item.doc?.time_when_completed !==
                                            '' &&
                                          item.doc?.is_active === true && (
                                            <SlaDiff
                                              id={item.doc?._id}
                                              end={
                                                item.doc?.time_when_completed
                                              }
                                              start={
                                                item.doc?.time_when_created
                                              }
                                              sla={item.doc?.sla}
                                            />
                                          )}
                                        {item.doc?.sla !== '' &&
                                          item.doc?.sla !== undefined &&
                                          item.doc?.time_when_completed ===
                                            '' &&
                                          item.doc?.time_when_created !== '' &&
                                          item.doc?.is_active === false && (
                                            <IonBadge color="light">
                                              {item.doc?.sla}
                                            </IonBadge>
                                          )}
                                        {item.doc?.sla !== '' &&
                                          item.doc?.sla !== undefined &&
                                          item.doc?.time_when_completed ===
                                            '' &&
                                          item.doc?.time_when_created !== '' &&
                                          item.doc?.is_active === true &&
                                          GetSla(
                                            item.doc?.sla,
                                            item.doc.time_when_created,
                                            true,
                                          )}
                                      </>
                                    ) : (
                                      <IonBadge color="light">
                                        {t('tickets.sla')} {item.doc?.sla}
                                      </IonBadge>
                                    )}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item.doc?._id}`}>
                                    <GetErrorName item={item} />
                                    {/* <IonText color={item.doc?.error.name && "danger"}>{item.doc?.error.name ? item.doc?.error.name : "-"}</IonText> */}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item.doc?._id}`}>
                                    {item.doc?.errors.length > 0
                                      ? item.doc?.errors.length
                                      : '-'}
                                  </Link>
                                </TableCell>
                                {/* <TableCell>
																	<Link to={`/tickets/${item.doc._id}`}>
																		{item.doc.resourced.length === 1 && item.doc.resourced[0].name}
																		{item.doc.resourced.length > 1 && `${item.doc.resourced[0].name} + ${item.doc.resourced.length - 1}`}
																		{item.doc.resourced.length === 0 && "-"}
																	</Link>
																</TableCell> */}
                                <TableCell className="ticket-table-handlers">
                                  <Link to={`/tickets/${item.doc?._id}`}>
                                    {handlers.map((handler, j) => {
                                      return (
                                        <IonButton
                                          routerLink={`/contacts/${handler.name}`}
                                          fill="clear"
                                          style={{height: 'unset'}}
                                          size={'small'}
                                          key={'handler-' + i + '-' + j}>
                                          <div
                                            className="icon-wrap primary ion-margin-none"
                                            slot="start">
                                            {handler.metadata &&
                                            handler.metadata
                                              .firstname_lastname &&
                                            handler.metadata
                                              .firstname_lastname !== '' &&
                                            handler.metadata.firstname_lastname.split(
                                              ' ',
                                            ).length >= 1 ? (
                                              <>
                                                {handler.metadata.firstname_lastname.split(
                                                  ' ',
                                                ).length >= 1 &&
                                                  handler.metadata.firstname_lastname
                                                    .split(' ')[0]
                                                    .charAt(0)}
                                                {handler.metadata.firstname_lastname.split(
                                                  ' ',
                                                ).length > 1 &&
                                                  handler.metadata.firstname_lastname
                                                    .split(' ')[1]
                                                    .charAt(0)}
                                              </>
                                            ) : (
                                              '?'
                                            )}
                                          </div>
                                          {/* <IonIcon className="ion-margin-left-half" slot="end" icon={ellipsisVertical} color="medium" style={{ fontSize: "22px" }} /> */}
                                        </IonButton>
                                      );
                                    })}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link to={`/tickets/${item.doc?._id}`}>
                                    <DateTime
                                      timestamp={item.doc?.time_when_created}
                                    />
                                  </Link>
                                </TableCell>
                                <TableCell align="right">
                                  {item.doc?.is_active && (
                                    <IonButton
                                      size="default"
                                      fill="clear"
                                      color="medium"
                                      disabled={!permissions?.canDelete}
                                      onClick={() =>
                                        alert(
                                          t('alert.notice'),
                                          `${t(
                                            'alert.are_you_sure_you_want_to_delete',
                                          )} ${t('alert.ticket')} #${
                                            item.doc?._id
                                          }?`,
                                          [
                                            t('app.go_back'),
                                            {
                                              text: 'Ok',
                                              handler: () =>
                                                deleteTicket(item.doc?._id),
                                            },
                                          ],
                                        )
                                      }>
                                      <IonIcon
                                        style={{height: '22px'}}
                                        slot="icon-only"
                                        icon={trashOutline}
                                      />
                                    </IonButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={11} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      colSpan={9}
                      rowsPerPageOptions={[2, 10, 15, 50]}
                      component="div"
                      count={filteredTickets.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelDisplayedRows={({from, to, count}) => {
                        return (
                          '' +
                          from +
                          '-' +
                          to +
                          ' yhteensä ' +
                          count +
                          ' tuloksesta'
                        );
                      }}
                      labelRowsPerPage={'Riviä sivulla:'}
                    />
                  </TableContainer>
                )}
                {segment === 'map' && (
                  <ReactMapGL
                    className="places-map"
                    {...viewport}
                    width="100%"
                    height={`${height - 226}px`}
                    style={{position: 'relative'}}
                    onViewportChange={setViewport}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    mapStyle={
                      mapStyle === 'default'
                        ? 'mapbox://styles/mapbox/outdoors-v11'
                        : 'mapbox://styles/mapbox/satellite-v9'
                    }>
                    {Object.values(newGrouped).map((item, i) => {
                      const ticket = item[0];
                      // const place = places.find((place) => place?.doc?.site_id === ticket?.doc?.place?.site_id)
                      const place = places.find(place =>
                        place.doc?.stations.some(
                          station =>
                            station.id?.toString() ===
                              ticket.doc?.errors[0].STATION_ID?.toString() ||
                            station.id?.toString() ===
                              ticket.station?.id?.toString(),
                        ),
                      );

                      if (
                        item.length > 1 &&
                        place &&
                        place.doc?.coordinates &&
                        place.doc?.coordinates[0] !== '' &&
                        place.doc?.coordinates[1] !== ''
                      ) {
                        return (
                          <Marker
                            key={i}
                            latitude={parseFloat(place.doc.coordinates[0])}
                            longitude={parseFloat(place.doc.coordinates[1])}
                            offsetLeft={-20}
                            offsetTop={-48}>
                            <button
                              className={
                                ticket?.doc?.is_active
                                  ? 'marker-list-indicator'
                                  : 'marker-list-indicator primary'
                              }
                              onClick={() => setModal(`modal-${i + 1}`)}>
                              <p>{item.length}</p>
                            </button>
                          </Marker>
                        );
                      } else if (
                        place &&
                        place.doc?.coordinates &&
                        place.doc?.coordinates[0] !== '' &&
                        place.doc?.coordinates[1] !== ''
                      ) {
                        return (
                          <Marker
                            key={i}
                            latitude={parseFloat(place.doc.coordinates[0])}
                            longitude={parseFloat(place.doc.coordinates[1])}
                            offsetLeft={-24}
                            offsetTop={-60}>
                            <div className="marker-inner-wrap">
                              <Link to={`/tickets/${ticket?.doc?._id}`}>
                                <MarkerIcon doc={ticket?.doc} />
                                <div className="marker-status">
                                  {ticket?.doc?.is_active ? (
                                    <>
                                      {ticket?.doc?.time_when_paused !== '' && (
                                        <IonIcon
                                          className="marker-icon"
                                          icon={pauseCircleOutline}
                                        />
                                      )}
                                      {ticket?.doc?.time_when_documented !==
                                        '' &&
                                        ticket?.doc?.time_when_completed !==
                                          '' && (
                                          <IonIcon
                                            className="marker-icon"
                                            icon={checkmarkDoneCircleOutline}
                                          />
                                        )}
                                      {ticket?.doc?.time_when_completed !==
                                        '' &&
                                        ticket?.doc?.time_when_documented ===
                                          '' && (
                                          <IonIcon
                                            className="marker-icon"
                                            icon={checkmarkCircleOutline}
                                          />
                                        )}
                                      {ticket?.doc?.time_when_started !== '' &&
                                        ticket?.doc?.time_when_paused === '' &&
                                        ticket?.doc?.time_when_completed ===
                                          '' &&
                                        ticket?.doc?.time_when_documented ===
                                          '' && (
                                          <IonIcon
                                            className="marker-icon"
                                            icon={playCircleOutline}
                                          />
                                        )}
                                      {ticket?.doc?.time_when_paused === '' &&
                                        ticket?.doc?.time_when_completed ===
                                          '' &&
                                        ticket?.doc?.time_when_started ===
                                          '' && (
                                          <IonIcon
                                            className="marker-icon"
                                            icon={stopCircleOutline}
                                          />
                                        )}
                                    </>
                                  ) : (
                                    <IonIcon
                                      className="marker-icon"
                                      icon={fileTrayFullOutline}
                                    />
                                  )}
                                </div>
                                <div className="marker-title">
                                  <p
                                    className={
                                      mapStyle === 'default' ? 'map' : 'sat'
                                    }>
                                    {place.doc.name}
                                  </p>
                                  <p
                                    className={
                                      mapStyle === 'default' ? 'map' : 'sat'
                                    }>
                                    Työ #{ticket?.doc?._id}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </Marker>
                        );
                      } else return null;
                    })}
                    <NavigationControl
                      style={{top: '16px', right: '16px'}}
                      showCompass={false}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '94px',
                        right: '16px',
                      }}>
                      <div className="mapboxgl-ctrl mapboxgl-ctrl-group overflow-hidden">
                        <button
                          onClick={() => {
                            mapStyle === 'default'
                              ? setMapStyle('satellite')
                              : setMapStyle('default');
                          }}
                          className="mapboxgl-ctrl-map-style">
                          <StaticMap
                            {...viewport}
                            width="40px"
                            height="40px"
                            onViewportChange={setViewport}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            mapStyle={
                              mapStyle === 'satellite'
                                ? 'mapbox://styles/mapbox/outdoors-v11'
                                : 'mapbox://styles/mapbox/satellite-v9'
                            }
                          />
                          {mapStyle === 'default' ? <p>sat</p> : <p>map</p>}
                        </button>
                      </div>
                    </div>
                  </ReactMapGL>
                )}
                {Object.values(newGrouped).map((item, i) => {
                  const place = places.find(place =>
                    place.doc?.stations.some(
                      station =>
                        station.id?.toString() ===
                          item[0].doc?.errors[0].STATION_ID?.toString() ||
                        station.id?.toString() ===
                          item[0].doc?.station?.id?.toString(),
                    ),
                  );
                  return (
                    <IonModal
                      key={i}
                      isOpen={modal === `modal-${i + 1}`}
                      onDidDismiss={() => setModal('')}
                      cssClass="marker-list-modal">
                      <div className="table-wrap">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('tickets.id')}</TableCell>
                              <TableCell>{t('tickets.status')}</TableCell>
                              <TableCell>{t('places.place')}</TableCell>
                              <TableCell>
                                <span
                                  className="triangle-wrap"
                                  onClick={() => {
                                    if (filters.order === '') {
                                      setFilters({
                                        ...filters,
                                        order: 'ascending',
                                      });
                                    } else {
                                      setFilters({...filters, order: ''});
                                    }
                                  }}>
                                  {t('tickets.created')}
                                  <span
                                    className={
                                      filters.order === ''
                                        ? 'triangle-down'
                                        : 'triangle-up'
                                    }
                                  />
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item
                              .sort((a, b) => {
                                if (filters.order === '') {
                                  return parseInt(b.id) - parseInt(a.id);
                                } else {
                                  return parseInt(a.id) - parseInt(b.id);
                                }
                              })
                              .map((ticket, i) => {
                                return (
                                  <TableRow
                                    onClick={() => setModal('')}
                                    key={i}
                                    className={
                                      ticket.doc.is_active === false &&
                                      filters.isActive === 'all'
                                        ? 'history-row'
                                        : ''
                                    }
                                    component="tr"
                                    hover={true}>
                                    <TableCell>
                                      <Link to={`/tickets/${ticket.doc._id}`}>
                                        {ticket.doc._id}
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link to={`/tickets/${ticket.doc._id}`}>
                                        <Badge doc={ticket.doc} />
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link to={`/tickets/${ticket.doc._id}`}>
                                        {ticket.doc?.place?.name &&
                                        ticket.doc?.place?.name !== ''
                                          ? ticket.doc.place.name
                                          : place
                                          ? place.doc?.name
                                          : '-'}
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link to={`/tickets/${ticket.doc._id}`}>
                                        <DateTime
                                          timestamp={
                                            ticket.doc.time_when_created
                                          }
                                        />
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </div>
                      <IonButton color="danger" onClick={() => setModal('')}>
                        {t('app.close')}
                      </IonButton>
                    </IonModal>
                  );
                })}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Tickets;
