import React, {useState, useContext, useEffect} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonItem,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {NativeSelect, FormControl} from '@material-ui/core';
import {volumeHighOutline, volumeMuteOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import Loading from '../../components/utils/Loading';
import {AuthContext} from '../../components/context/AuthContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import useWindowSize from '../../hooks/useWindowSize';
import ControlRoomTickets from '../../components/tickets/ControlRoomTickets';
import ControlRoomMonitoring from '../../components/tickets/ControlRoomMonitoring';
import {ReactComponent as ScreenIcon} from '../../assets/screen.svg';
import {ReactComponent as MultiScreenIcon} from '../../assets/multiscreen.svg';
import {UserContext} from '../../components/context/UserContext';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const ControlRoom = props => {
  const {t} = useTranslation('link_app');
  const {match, location} = props;
  const [, height] = useWindowSize();
  const [multiScreen, setMultiScreen] = useState(false);
  const [alertSounds, setAlertSounds] = useState(true);
  const [mutedTickets, setMutedTickets] = useState([]);
  const [user] = useContext(UserContext);

  const alertSound =
    require('../../assets/sounds/control_room_alert.wav').default;
  const audioPlayer = React.useRef();

  useTitleEffect(t('nav.control_room'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Filters state
  const [filters, setFilters] = useState({
    status: '',
    isActive: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch tickets
  const [tickets, setTickets] = useState(undefined);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        let docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        docs = docs.sort((a, b) => parseInt(b.id) - parseInt(a.id));
        // docs = docs.slice(0, 25);
        let faultedTickets = docs.filter(
          val =>
            !val.doc?.ticket_status || val.doc?.ticket_status === 'faulted',
        );
        let otherTickets = docs.filter(
          val => val.doc?.ticket_status && val.doc?.ticket_status !== 'faulted',
        );
        faultedTickets = faultedTickets.concat(otherTickets);
        setTickets(faultedTickets);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Fetch all guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.guides]);

  const [statusTypes, setStatusTypes] = useState([]);

  useEffect(() => {
    tickets?.forEach(item => {
      if (item.doc) {
        if (
          statusTypes.indexOf(item.doc?.ticket_status) === -1 &&
          item.doc?.ticket_status !== '' &&
          item.doc?.ticket_status !== undefined
        ) {
          setStatusTypes([...statusTypes, item.doc?.ticket_status]);
        } else if (
          statusTypes.indexOf('faulted') === -1 &&
          (item.doc?.ticket_status === '' ||
            item.doc?.ticket_status === undefined ||
            item.doc?.ticket_status === 'faulted')
        ) {
          setStatusTypes([...statusTypes, 'faulted']);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets]);

  const [filteredTickets, setFilteredTickets] = useState([]);

  useEffect(() => {
    if (tickets === undefined) {
      return;
    }
    let ticketsToFilter = [...tickets];
    if (filters.status !== '') {
      if (filters.status === 'faulted') {
        ticketsToFilter = ticketsToFilter.filter(
          ticket =>
            !ticket.doc?.ticket_status ||
            ticket.doc?.ticket_status === 'faulted',
        );
      } else {
        ticketsToFilter = ticketsToFilter.filter(
          ticket => ticket.doc.ticket_status === filters.status,
        );
      }
    }
    if (filters.isActive === '') {
      ticketsToFilter = ticketsToFilter.filter(item => {
        return item.doc.is_active === true;
      });
    } else if (filters.isActive === 'false') {
      ticketsToFilter = ticketsToFilter.filter(item => {
        return item.doc.is_active === false;
      });
    } else if (filters.isActive === 'all') {
      ticketsToFilter = ticketsToFilter.filter(item => {
        return item;
      });
    }
    setFilteredTickets(ticketsToFilter);
  }, [tickets, filters]);

  // Variables for multiscreen split
  const [ticketsNew, setNewTickets] = useState([]);
  const [ticketsOld, setOldTickets] = useState([]);

  useEffect(() => {
    if (multiScreen) {
      const new_tickets = tickets?.filter(
        val => !val.doc?.ticket_status || val.doc?.ticket_status === 'faulted',
      );
      const old_tickets = tickets?.filter(
        val => val.doc?.ticket_status && val.doc?.ticket_status !== 'faulted',
      );
      setNewTickets(new_tickets);
      setOldTickets(old_tickets);
    }
  }, [tickets, multiScreen]);

  // Fetch places
  const [places, setPlaces] = useState([]);
  const [currentPlace, setCurrentPlace] = useState(null);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
        setCurrentPlace({
          site_id: 'all',
          name: 'Kaikki',
        });
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.places, match]);

  const [currentACPanelIndex, setCurrentACPanelIndex] = useState(0);
  const [currentDCPanelIndex, setCurrentDCPanelIndex] = useState(0);
  const [stationPanels, setStationPanels] = useState([]);

  useEffect(() => {
    if (currentPlace !== null && currentPlace.site_id !== 'all') {
      const stationsWithMonitoring = currentPlace.stations.filter(
        station => station?.monitoring_panels?.control_room,
      );
      const ACStations = stationsWithMonitoring.filter(
        station => station.type === 'uc',
      );
      const DCStations = stationsWithMonitoring.filter(
        station => station.type === 'kempower' || station.type === 'ocpp',
      );

      let acPanels = [];
      let dcPanels = [];

      if (ACStations.length > 0) {
        ACStations.forEach((station, i) => {
          acPanels.push({
            type: station.type,
            name: station.name,
            panelID: station.monitoring_panels.control_room,
            station_alias: station.station_alias ?? null,
          });
        });
      }

      if (DCStations.length > 0) {
        DCStations.forEach((station, i) => {
          if (station.monitoring_panels.control_room)
            dcPanels.push({
              type: station.type,
              name: station.name,
              panelID: station.monitoring_panels.control_room,
              station_alias: station.station_alias ?? null,
            });
          if (station.monitoring_panels.control_room_satellite_status)
            dcPanels.push({
              type: station.type,
              name: station.name,
              panelID: station.monitoring_panels.control_room_satellite_status,
              station_alias: station.station_alias ?? null,
            });
        });
      }

      setStationPanels({ac: acPanels, dc: dcPanels});

      setCurrentACPanelIndex(0);
      setCurrentDCPanelIndex(0);
    }
    if (currentPlace !== null && currentPlace.site_id === 'all') {
      setStationPanels({
        ac: [
          {
            type: 'uc',
            name: 'AC Latausasemat',
            panelID: '5',
          },
        ],
        dc: [
          {
            type: 'ocpp',
            name: 'DC Latausasemat',
            panelID: '50',
          },
        ],
      });
    }
  }, [currentPlace]);

  const [ticketsUpdated, setTicketsUpdated] = useState([]);
  useEffect(() => {
    setTicketsUpdated(props.ticketsUpdated);
  }, [props.ticketsUpdated]);

  const handleACMonitorStationChange = index => {
    if (index < stationPanels?.ac?.length) {
      setCurrentACPanelIndex(index);
      // setCurrentPlace(station)
    } else {
      console.warn('No panel ID found');
    }
  };
  const handleDCMonitorStationChange = index => {
    if (index < stationPanels?.dc?.length) {
      setCurrentDCPanelIndex(index);
      // setCurrentPlace(station)
    } else {
      console.warn('No panel ID found');
    }
  };

  const handleMonitorPlaceChange = placeId => {
    if (placeId === 'all') {
      setCurrentPlace({
        site_id: 'all',
        name: 'Kaikki',
      });
    } else if (placeId !== null) {
      let newPlace = places.find(
        place => place.doc.site_id.toString() === placeId.toString(),
      );
      setCurrentPlace(newPlace.doc);
    } else {
      console.warn('No panel ID found');
    }
  };

  const handleScreenStateChange = screenValue => {
    setMultiScreen(screenValue);
    if (screenValue === 'true') {
      window.open('/multiscreen-monitoring', '_blank');
    }
  };

  const toggleAllAlertSounds = () => {
    setAlertSounds(!alertSounds);
  };

  // Filter tickets by active
  const FilterActiveButton = () => {
    return (
      <FormControl className="row-input">
        <NativeSelect
          value={filters.isActive}
          onChange={e => handleFilterChange(e.target.value, 'isActive')}
          inputProps={{
            name: 'active',
            id: 'active',
          }}
          className="filter-active-select">
          <option value="">{t('tickets.active')}</option>
          <option value="false">{t('tickets.history')}</option>
          <option value="all">{t('app.all')}</option>
        </NativeSelect>
      </FormControl>
    );
  };

  return (
    <IonPage>
      {location.pathname === '/control-room' && (
        <IonContent className="control-room">
          {!isFetching ? (
            <IonGrid className="ion-padding-none">
              {multiScreen.toString() === 'false' && (
                <IonRow>
                  <IonCol
                    size="auto"
                    className="control-room-tickets ion-padding-left ion-padding-right">
                    <IonRow>
                      <IonCol className="control-room-monitoring-header">
                        <IonCol>
                          <h1>{t('tickets.errors_segment')}</h1>
                        </IonCol>

                        <IonCol>
                          <IonItem>
                            {/* <FormControl className="row-input">
                              <NativeSelect
                                value={filters.status}
                                onChange={e =>
                                  handleFilterChange(e.target.value, 'status')
                                }
                                variant="filled">
                                <option aria-label="All" value="">
                                  {t('app.all')}
                                </option>
                                {statusTypes.map((type, i) => {
                                  return (
                                    <option key={i} value={type}>
                                      {t('tickets.' + type)}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl> */}
                            <FilterActiveButton />
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonButton
                            className="ion-margin-none"
                            fill="clear"
                            color="light"
                            size="small"
                            onClick={() => toggleAllAlertSounds()}>
                            {alertSounds ? (
                              <IonIcon
                                icon={volumeHighOutline}
                                color={
                                  user.metadata?.dark_mode ? 'primary' : 'dark'
                                }
                                slot="end"
                              />
                            ) : (
                              <IonIcon
                                icon={volumeMuteOutline}
                                color={
                                  user.metadata?.dark_mode ? 'primary' : 'dark'
                                }
                                slot="end"
                              />
                            )}
                          </IonButton>
                        </IonCol>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        height: `${height - 150}px`,
                        overflow: 'auto',
                        paddingRight: '1rem',
                      }}>
                      <IonCol>
                        <ControlRoomTickets
                          user={user}
                          tickets={filteredTickets}
                          places={places}
                          multiScreen={multiScreen}
                          setMultiScreen={setMultiScreen}
                          guides={guides}
                          alertSounds={alertSounds}
                          mutedTickets={mutedTickets}
                          setMutedTickets={setMutedTickets}
                          ticketsUpdated={ticketsUpdated}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    className="half-n-half control-room-monitoring"
                    size="">
                    <ControlRoomMonitoring
                      user={user}
                      match={match}
                      places={places}
                      stationPanels={stationPanels}
                      currentACPanelIndex={currentACPanelIndex}
                      currentDCPanelIndex={currentDCPanelIndex}
                      handleACMonitorStationChange={
                        handleACMonitorStationChange
                      }
                      handleDCMonitorStationChange={
                        handleDCMonitorStationChange
                      }
                      currentPlace={currentPlace}
                      handleMonitorPlaceChange={handleMonitorPlaceChange}
                      multiScreen={multiScreen}
                      setMultiScreen={setMultiScreen}
                    />
                  </IonCol>
                </IonRow>
              )}
              {multiScreen.toString() === 'true' && (
                <IonRow>
                  <IonCol
                    size="6"
                    className="control-room-tickets ion-padding-left ion-padding-right">
                    <IonRow>
                      <IonCol className="control-room-monitoring-header multiscreen ion-padding-none">
                        <IonCol>
                          <h1>{t('tickets.not_reacted')}</h1>
                        </IonCol>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        height: `${height - 150}px`,
                        overflow: 'auto',
                        paddingRight: '1rem',
                      }}>
                      <IonCol>
                        <ControlRoomTickets
                          tickets={ticketsNew}
                          places={places}
                          multiScreen={multiScreen}
                          setMultiScreen={setMultiScreen}
                          ticketsListIndex={0}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    className="half-n-half"
                    size="6"
                    style={{height: `${height - 130}px`}}>
                    <IonRow>
                      <IonCol className="control-room-monitoring-header multiscreen ion-padding-none">
                        <IonCol>
                          <h1>{t('tickets.processing_started')}</h1>
                        </IonCol>
                        <IonCol className="flex-shrink">
                          <IonSegment
                            onIonChange={e =>
                              handleScreenStateChange(e.detail.value)
                            }
                            value={multiScreen.toString()}
                            color="primary"
                            className="screen-icons">
                            <IonSegmentButton value="false">
                              <ScreenIcon className="screen-icon" />
                            </IonSegmentButton>
                            <IonSegmentButton value="true">
                              <MultiScreenIcon className="multi-screen-icon" />
                            </IonSegmentButton>
                          </IonSegment>
                        </IonCol>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        height: `${height - 150}px`,
                        overflow: 'auto',
                        paddingRight: '1rem',
                      }}>
                      <IonCol>
                        <ControlRoomTickets
                          tickets={ticketsOld}
                          places={places}
                          multiScreen={multiScreen}
                          setMultiScreen={setMultiScreen}
                          ticketsListIndex={1}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              )}
              <audio id="alert-sound" ref={audioPlayer}>
                <source src={alertSound} type="audio/wav"></source>
                Audio file not supported
              </audio>
            </IonGrid>
          ) : (
            <Loading />
          )}
        </IonContent>
      )}
    </IonPage>
  );
};

export default ControlRoom;
