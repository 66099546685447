import React, {useContext} from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import {DatabaseContext} from '../context/DatabaseContext';
import {UserContext} from '../context/UserContext';
import {MobileStepper, Button} from '@material-ui/core';
import {chevronBack, chevronForward} from 'ionicons/icons';
import {useTheme} from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';

const StepperFooter = props => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const match = props.match;
  const permissions = props.permissions;
  const [user] = useContext(UserContext);
  const toast = useToast();
  const theme = useTheme();

  // Databases
  const databases = useContext(DatabaseContext);

  const moveToHistory = () => {
    const ticket = {...props.currentState};

    databases.tickets
      .get(ticket._id)
      .then(doc => {
        const timestamp = Math.round(new Date() / 1000).toString();
        let newId =
          doc.log?.length > 0
            ? (
                parseInt(
                  Math.max.apply(
                    Math,
                    doc.log?.map(x => x.id),
                  ),
                ) + 1
              ).toString()
            : '1';
        const docCopy = {
          ...doc,
          is_active: false,
          ticket_status: 'closed',
          time_when_completed: timestamp,
          log: doc.log
            ? [
                ...doc.log,
                {
                  id: newId,
                  time: timestamp,
                  user: user,
                  app: 'management',
                  event: 'move_to_history',
                },
              ]
            : [
                {
                  id: newId,
                  time: timestamp,
                  user: user,
                  app: 'management',
                  event: 'move_to_history',
                },
              ],
        };
        return databases.tickets.put(docCopy);
      })
      .then(() => {
        props.setIsEdited(false);
        return databases.tickets.get(ticket._id);
      })
      .then(doc => {
        const ticketCopy = {
          _id: doc._id,
          is_active: doc.is_active,
          error: doc.error,
          errors: doc.errors,
          place: doc.place,
          category: doc.category,
          warehouse: doc.warehouse,
          resourced: doc.resourced,
          maintenance_phases: doc.maintenance_phases,
          accessories: doc.accessories,
          more_info: doc.more_info,
          summary: doc.summary,
          documentation_guide: doc.documentation_guide,
          time_when_created: doc.time_when_created,
          time_when_resourced: doc.time_when_resourced,
          time_when_paused: doc.time_when_paused,
          time_when_started: doc.time_when_started,
          time_when_accessories: doc.time_when_accessories,
          time_when_at_place: doc.time_when_at_place,
          time_when_documented: doc.time_when_documented,
          time_when_completed: doc.time_when_completed,
          flow_step: doc.flow_step,
          flow: doc.flow,
          comments: doc.comments,
          images: doc.images,
          log: doc.log,
          alert_messages: doc.alert_messages,
          site: doc.site,
          sla: doc.sla,
          ticket_status: doc.ticket_status,
        };
        return databases.ticket_history.put(ticketCopy);
      })
      .then(() => {
        console.log(
          `${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`,
        );
        toast(`${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`);
        props.setIsEdited(false);
        return databases.tickets.get(ticket._id);
      })
      .then(doc => {
        // Update edited ticket state to match new _rev
        props.setTicket(doc);
        props.setActiveStep(0);
        props.returnToTickets(doc);
      })
      .catch(err => {
        console.log(`${t('toast.error_updating_ticket')} #${ticket._id}:`, err);
        toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
        Sentry.captureException(err);
      });
  };

  const reOpenTicket = () => {
    const ticket = {...props.currentState};
    databases.ticket_history
      .get(ticket._id)
      .then(doc => {
        const docCopy = {...doc, _deleted: true};
        return databases.ticket_history.put(docCopy);
      })
      .then(result => {
        updateTicket();
      })
      .catch(err => {
        toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
        Sentry.captureException(err);
      });

    const updateTicket = () => {
      databases.tickets
        .get(ticket._id)
        .then(doc => {
          const timestamp = Math.round(new Date() / 1000).toString();
          let newId =
            doc.log?.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      doc.log?.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';
          const docCopy = {
            ...doc,
            is_active: true,
            ticket_status: 'open',
            time_when_completed: '',
            log: doc.log
              ? [
                  ...doc.log,
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: 'reopen_ticket',
                  },
                ]
              : [
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: 'reopen_ticket',
                  },
                ],
          };
          return databases.tickets.put(docCopy);
        })
        .then(() => {
          console.log(
            `${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`,
          );
          toast(`${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`);
          props.setIsEdited(false);
          return databases.tickets.get(ticket._id);
        })
        .then(doc => {
          // Update edited ticket state to match new _rev
          props.setTicket(doc);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${ticket._id}:`,
            err,
          );
          toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
          Sentry.captureException(err);
        });
    };
  };

  return (
    <div className="stepper-footer">
      <div>
        {/* <IonButton routerLink="/tickets" className="ion-margin-right" fill="outline" color="danger">
					{t("app.abort")}
				</IonButton> */}
        {((props.currentState.is_active === true && props.activeStep !== 3) ||
          (props.currentState.is_active === false &&
            props.activeStep === 4)) && (
          <IonButton
            disabled={!props.isEdited}
            onClick={() =>
              props.activeStep === 4
                ? props.handleSave(props.currentState, [], true)
                : props.activeStep === 2
                ? props.handleResourcingAlert()
                : props.handleSave(props.currentState)
            }
            className="ion-margin-right"
            fill="solid"
            color="success">
            {props.activeStep === 2
              ? t('tickets.resource_and_save')
              : t('app.save')}
          </IonButton>
        )}
        {props.currentState.is_active === true &&
          props.activeStep === 3 &&
          props.alertUseType === 'mail' && (
            <IonButton
              disabled={
                props.currentState.is_active === true && props.isButtonActive
                  ? props.alertMessages.length === 0 &&
                    props.manualMessages.length === 0
                    ? true
                    : false
                  : true
              }
              color="success"
              onClick={() => props.sendMessages()}>
              {t('tickets.save_and_send')}
            </IonButton>
          )}
        {props.currentState.is_active === true &&
          props.activeStep === 3 &&
          props.alertUseType === 'teams' && (
            <IonButton
              disabled={
                props.currentState.is_active === true && props.isButtonActive
                  ? props.teamsAlertMessageRecipient === ''
                    ? true
                    : false
                  : true
              }
              color="success"
              onClick={() => props.sendMessages()}>
              {t('tickets.save_and_send')}
            </IonButton>
          )}
        {match.url !== '/new-ticket' &&
          props.currentState.is_active === false && (
            <IonButton
              onClick={() => reOpenTicket()}
              color="success"
              className="ion-margin-right"
              disabled={!permissions?.tickets}>
              {t('app.reopen_ticket')}
            </IonButton>
          )}
        {props.currentState.is_active === true &&
          props.activeStep === 4 &&
          props.currentState?.ticket_status !== 'repair_finished' && (
            <IonButton
              disabled={!permissions.tickets}
              onClick={() => props.handleSave(props.currentState)}
              className="ion-margin-right"
              fill="solid"
              color="success">
              {t('tickets.repair_finished')}
            </IonButton>
          )}
        {props.currentState.is_active === true &&
          props.activeStep === 4 &&
          props.currentState?.ticket_status === 'repair_finished' && (
            <IonButton
              disabled={!permissions.tickets}
              onClick={() => moveToHistory()}
              className="ion-margin-right"
              fill="solid"
              color="success">
              {t('app.close')} {t('tickets.work')}
            </IonButton>
          )}
        {/* {props.activeStep === props.steps.length-1 && match.url !== "/new-ticket" && props.currentState.is_active === true && (
					<IonButton onClick={() => moveToHistory()} color="success" className="ion-margin-right" disabled={!permissions?.tickets}>
						{t("app.save_and_close")}
					</IonButton>
				)} */}
      </div>
      <div>
        {/* <IonButton disabled={props.activeStep === 0} onClick={() => props.handleActiveStep(props.activeStep - 1)} fill="outline" color="primary" className="ion-margin-right">
					{t("app.back")}
				</IonButton>
				{props.activeStep <= props.steps.length - 1 && (
					<IonButton color="primary" onClick={() => props.handleActiveStep(props.activeStep + 1)}>
						{t("app.next")}
					</IonButton>
				)} */}
        <MobileStepper
          steps={props.steps.length}
          position="static"
          variant="text"
          activeStep={props.activeStep}
          nextButton={
            <Button
              size="small"
              onClick={() => props.handleActiveStep(props.activeStep + 1)}
              disabled={props.activeStep === props.steps.length - 1}>
              {theme.direction === 'rtl' ? (
                <IonIcon
                  slot="start"
                  color={
                    props.activeStep === props.steps.length - 1
                      ? 'medium'
                      : 'primary'
                  }
                  icon={chevronBack}
                />
              ) : (
                <IonIcon
                  slot="start"
                  color={
                    props.activeStep === props.steps.length - 1
                      ? 'medium'
                      : 'primary'
                  }
                  icon={chevronForward}
                />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => props.handleActiveStep(props.activeStep - 1)}
              disabled={props.activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <IonIcon
                  slot="start"
                  color={props.activeStep === 0 ? 'medium' : 'primary'}
                  icon={chevronForward}
                />
              ) : (
                <IonIcon
                  slot="start"
                  color={props.activeStep === 0 ? 'medium' : 'primary'}
                  icon={chevronBack}
                />
              )}
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default StepperFooter;
