import React, {useState, useEffect, useContext} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import Header from '../../../components/navigation/Header';
import {Link} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {trashOutline} from 'ionicons/icons';
import {UserContext} from '../../../components/context/UserContext';
import useToast from '../../../hooks/useToast';
import useAlert from '../../../hooks/useAlert';
import Loading from '../../../components/utils/Loading';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import {AuthContext} from '../../../components/context/AuthContext';
import useTitleEffect from '../../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const RfidUsers = props => {
  const {t} = useTranslation('link_app_ikea');
  const {match, permissions} = props;
  const toast = useToast();
  const alert = useAlert();

  useTitleEffect(t('nav.rfid'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Get current user from user context
  const [user] = useContext(UserContext);

  // Filters state
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    title: '',
    company: '',
    role: '',
  });

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch contacts
  const [lmDeliveryTags, setLmDeliveryTags] = useState([]);
  useEffect(() => {
    databases.lm_delivery_tags
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setLmDeliveryTags(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.lm_delivery_tags, match, props.isLoading, setIsAuth]);

  // Contact property filtering
  let filteredRfidUsers = [...lmDeliveryTags];
  if (search !== '') {
    filteredRfidUsers = filteredRfidUsers.filter(item => {
      return (
        item.doc.name.toLowerCase().includes(search.toLowerCase()) ||
        item.doc.rfid.toLowerCase().includes(search.toLocaleLowerCase()) ||
        item.doc.place.toLowerCase().includes(search.toLowerCase())
      );
    });
  }

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          title: '',
          company: '',
          role: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  const deleteRfidUser = selectedUser => {
    databases.lm_delivery_tags
      .get(selectedUser._id)
      .then(result => {
        result._deleted = true;
        return databases.lm_delivery_tags.put(result);
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_contact')} "${selectedUser.name}":`,
          err,
        );
        toast(
          `${t('toast.error_updating_contact')} "${selectedUser.name}"`,
          err,
        );
        Sentry.captureException(err);
      });
  };

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, lmDeliveryTags.length - page * rowsPerPage);

  return (
    <IonPage>
      <Header
        title={t('contacts.contact_list')}
        searchBar
        search={search}
        setSearch={setSearch}
        placeholder={t('contacts.search')}
        buttons={[
          {
            link: '/new-rfid-user',
            permission: 'contacts',
            name: t('ikea.rfid_users.new_rfid_user'),
          },
        ]}
        permissions={permissions}
      />
      <IonContent>
        {!isFetching ? (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                {clearButton}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                <TableContainer>
                  <Table className="contacts-table">
                    <TableHead className="contacts-table-head">
                      <TableRow>
                        <StyledTableHeadCell>
                          {t('ikea.rfid_users.name')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          {t('ikea.rfid_users.rfid_tag')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          {t('ikea.rfid_users.place')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell></StyledTableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRfidUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((item, i) => {
                          const link =
                            item.doc._id !== user.name
                              ? `/rfid-users/${item.doc._id}`
                              : '';
                          return (
                            <TableRow key={i} component="tr" hover={true}>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.name !== '' ? item.doc.name : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.rfid !== '' ? item.doc.rfid : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.place !== '' ? item.doc.place : '-'}
                                </Link>
                              </TableCell>
                              <TableCell align="right">
                                <IonButton
                                  size="default"
                                  fill="clear"
                                  color="medium"
                                  onClick={() =>
                                    alert(
                                      t('alert.notice'),
                                      `${t(
                                        'alert.are_you_sure_you_want_to_delete',
                                      )} ${t('alert.contact')} "${
                                        item.doc.name
                                      }"`,
                                      [
                                        t('app.go_back'),
                                        {
                                          text: 'Ok',
                                          handler: () =>
                                            deleteRfidUser(item.doc),
                                        },
                                      ],
                                    )
                                  }>
                                  <IonIcon
                                    slot="icon-only"
                                    color="medium"
                                    style={{height: '22px'}}
                                    icon={trashOutline}
                                  />
                                </IonButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                          <TableCell colSpan={7} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    colSpan={7}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={filteredRfidUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default RfidUsers;
