import React, {useState, useCallback, useEffect} from 'react';
import {IonContent, IonPage, IonButton} from '@ionic/react';
import {ReactComponent as LogoBlack} from '../../assets/logo-black.svg';
import {ReactComponent as LogoWhite} from '../../assets/logo-white.svg';
import {useTranslation} from 'react-i18next';
import {Paper} from '@material-ui/core';
import useToast from '../../hooks/useToast';
import useConfig from '../../hooks/useConfig';
import axios from 'axios';
import {Link} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const ForgotPassword = () => {
  const {t} = useTranslation('link_app');
  const [username, setUsername] = useState('');
  const toast = useToast();
  const {integrator, mgmt} = useConfig();

  const passwordReset = useCallback(
    async e => {
      e.preventDefault();
      axios
        .post(
          `${integrator}/reset-password`,
          {
            username: username,
            url: mgmt,
            language: 'fi',
          },
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          if (res.status === 204) {
            toast(t('toast.reset_password_sent'));
            setUsername('');
          } else {
            console.log('Something went wrong', res);
            toast(t('toast.unhandled_error'));
          }
        })
        .catch(err => {
          console.log(err);
          toast(t('toast.unhandled_error'));
          Sentry.captureException(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, username, toast],
  );

  useEffect(() => {
    const listener = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        passwordReset(e);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [passwordReset]);

  return (
    <IonPage>
      <IonContent>
        <div className="login">
          {/* Assuming your Paper component and Logo components are used */}
          <Paper>
            <div className="login-wrap ion-text-center">
              <LogoBlack className="ion-margin-bottom-half logo-black" />
              <LogoWhite className="ion-margin-bottom-half logo-white" />
              <p>Forgot password</p>
              <form
                onSubmit={e => passwordReset(e)}
                className="ion-text-center">
                <input
                  className="ion-margin-bottom normal-padding"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  autoComplete="off"
                  type="text"
                  placeholder={t('app.username')}
                />
                <IonButton className="ion-margin-top" type="submit">
                  Reset Password
                </IonButton>
              </form>
            </div>
            <div className="ion-margin-top ion-text-end">
              <Link to={`/login`}>Back to login</Link>
            </div>
          </Paper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
