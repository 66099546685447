import React, {useEffect, useContext, useState} from 'react';
import Header from '../../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonItem,
  IonList,
} from '@ionic/react';
import {Paper, FormControl, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {AuthContext} from '../../../components/context/AuthContext';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import useToast from '../../../hooks/useToast';
import useTitleEffect from '../../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const NewRfidUser = props => {
  const {match, permissions} = props;
  const {t} = useTranslation('link_app_ikea');
  const [doc, setDoc] = useState({
    name: '',
    rfid: '',
    place: '',
  });

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);
  useTitleEffect(t('contacts.new_contact'));

  useEffect(() => {
    databases.contacts.info().then(res => {
      if (res.error === 'unauthorized') {
        setIsAuth(false);
      }
    });
  }, [match, t, databases.contacts, setIsAuth]);

  const handleSave = () => {
    const rfidUserCopy = {...doc};
    databases.lm_delivery_tags
      .post(rfidUserCopy)
      .then(() => {
        console.log(
          `${t('toast.contact')} "${doc.name}" ${t('toast.updated')}`,
        );
        toast(`${t('toast.contact')} "${doc.name}" ${t('toast.updated')}`);
        setIsEdited(false);

        props.history.push('/rfid-users');
      })
      .catch(err => {
        console.log(`${t('toast.error_updating_contact')} "${doc.name}":`, err);
        toast(`${t('toast.error_updating_contact')} "${doc.name}"`, err);
        Sentry.captureException(err);
      });
  };

  return (
    <IonPage>
      <Header title={t('contacts.new_contact')} backButton />
      <IonContent>
        <div className="container-narrow">
          <Paper style={{marginTop: '32px'}}>
            <IonList>
              <IonItem
                lines="none"
                className="ion-margin-top ion-margin-bottom">
                <IonText>
                  <h4 className="ion-margin-none">{t('contacts.details')}</h4>
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <div className="row">
                  <FormControl className="row-input">
                    <div>
                      <label htmlFor="name">{t('ikea.rfid_users.name')}</label>
                    </div>
                    <TextField
                      id="name"
                      value={doc.name}
                      onChange={e => {
                        setIsEdited(true);
                        setDoc({...doc, name: e.target.value});
                      }}
                      disabled={!permissions?.contacts}
                    />
                  </FormControl>
                </div>
              </IonItem>

              <IonItem lines="none">
                <div className="row">
                  <FormControl className="row-input">
                    <div>
                      <label htmlFor="company">
                        {t('ikea.rfid_users.rfid_tag')}
                      </label>
                    </div>
                    <TextField
                      id="rfid"
                      value={doc.rfid}
                      onChange={e => {
                        setIsEdited(true);
                        setDoc({...doc, rfid: e.target.value});
                      }}
                      disabled={!permissions?.contacts}
                    />
                  </FormControl>
                </div>
              </IonItem>

              <IonItem lines="none">
                <div className="row">
                  <FormControl className="row-input">
                    <div>
                      <label htmlFor="phone-number">
                        {t('ikea.rfid_users.place')}
                      </label>
                    </div>
                    <TextField
                      id="place"
                      value={doc.place}
                      onChange={e => {
                        setIsEdited(true);
                        setDoc({...doc, place: e.target.value});
                      }}
                      disabled={!permissions?.contacts}
                    />
                  </FormControl>
                </div>
              </IonItem>
            </IonList>
          </Paper>
          <div>
            <IonButton
              style={{marginTop: '16px', marginBottom: '32px'}}
              disabled={!isEdited && !doc.rfid !== ''}
              onClick={() => handleSave()}
              className="ion-margin-right"
              fill="solid"
              color="success">
              {t('app.save')}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewRfidUser;
