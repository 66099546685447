import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import AccessoryInfo from '../../components/accessories/AccessoryInfo';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const Accessory = props => {
  // Misc vars
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the accessory
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.accessories
      .get(match.params.id)
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.accessories, match, props.isLoading, setIsAuth]);

  useTitleEffect(doc.name);

  return (
    <IonPage>
      <Header title={doc.name} backButton />
      <IonContent>
        {doc._id ? (
          <div className="container-narrow">
            <AccessoryInfo
              doc={doc}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Accessory;
