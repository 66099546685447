import React from 'react';
import {Paper} from '@material-ui/core';
import clsx from 'clsx';

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: 8,
  },
  /* Styles applied to the root element if `position="bottom"`. */
  positionBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="top"`. */
  positionTop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="static"`. */
  positionStatic: {},
});

const HeaderStepper = props => {
  const {
    backButton,
    classes,
    className,
    LinearProgressProps,
    nextButton,
    position = 'bottom',
    steps,
    variant = 'text',
    label = '',
    ...other
  } = props;

  return (
    <Paper
      square
      elevation={0}
      className={clsx(
        styles.root,
        styles[
          `position${position.charAt(0).toUpperCase() + position.slice(1)}`
        ],
        className,
      )}
      {...other}>
      {backButton}
      {variant === 'text' && <React.Fragment>{label}</React.Fragment>}

      {nextButton}
    </Paper>
  );
};

export default HeaderStepper;
