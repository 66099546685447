import React, {useState, useEffect} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  NativeSelect,
} from '@material-ui/core';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonButton,
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import {chevronDownOutline, caretDownOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import axios from 'axios';
import hoursMinutesAndSeconds from '../utils/HoursMinutesAndSeconds';
import useConfig from '../../hooks/useConfig';
import * as Sentry from '@sentry/react';

const TicketDeviceManagement = props => {
  const {t} = useTranslation('link_app');
  // const match = props.match
  const permissions = props.permissions;

  // Copy place and station to local state
  const [place] = useState(props.place);
  const [station] = useState(props.station);
  const [alertBox] = useIonAlert();
  const toast = useToast();

  // Edited state
  // const [isEdited, ] = useState(false)

  // const [chargeTypeValue, setChargeTypeValue] = useState("uc")
  // const [chargeStationSelected, setChargeStationSelected] = useState(null)

  const [connectors, setConnectors] = useState([]);
  const [connectorSelected, setConnectorSelected] = useState('');

  const [latestTransactions, setLatestTransactions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const {integrator} = useConfig();

  useEffect(() => {
    const checkDataDC = async () => {
      await fetch(
        `${integrator}/stations/${station.id}/logs?ended=true&limit=10`,
        {
          method: 'GET',
          headers: {
            Authorization:
              'Basic ' +
              Buffer.from(
                process.env.REACT_APP_IG_USERNAME +
                  ':' +
                  process.env.REACT_APP_IG_PASSWORD,
                'utf-8',
              ).toString('base64'),
          },
        },
      )
        .then(res => {
          return res.json();
        })
        .then(res => {
          setLatestTransactions(res.data);
          setIsLoading(false);
        })
        .catch(err => {
          console.log('Error fetching ticket data: ', err);
          Sentry.captureException(err);
          setIsLoading(false);
        });
    };
    const checkDataAC = async () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const fromTime = date.getTime();

      await fetch(
        `${integrator}/stations/${station.id}/logs?fromtime=${fromTime}`,
        {
          method: 'GET',
          headers: {
            Authorization:
              'Basic ' +
              Buffer.from(
                process.env.REACT_APP_IG_USERNAME +
                  ':' +
                  process.env.REACT_APP_IG_PASSWORD,
                'utf-8',
              ).toString('base64'),
          },
        },
      )
        .then(res => {
          return res.json();
        })
        .then(res => {
          const lastTenTransaction = res.value
            ? res.value?.slice(0, 10).reverse()
            : [];
          setLatestTransactions(lastTenTransaction);
          setIsLoading(false);
        })
        .catch(err => {
          console.log('Error fetching ticket data: ', err);
          Sentry.captureException(err);
          setIsLoading(false);
        });
    };
    if (
      station !== null &&
      (station.type === 'kempower' || station.type === 'ocpp')
    ) {
      setIsLoading(true);
      checkDataDC();
    }
    if (station !== null && station.type === 'uc') {
      setIsLoading(true);
      checkDataAC();
    }
  }, [integrator, station]);

  const charger_actions = [
    {
      action: 'start',
      label: t('tickets.charger_action_start'),
    },
    {
      action: 'stop',
      label: t('tickets.charger_action_stop'),
    },
    {
      action: 'unlock_cable',
      label: t('tickets.charger_action_unlock_cable'),
    },
    {
      action: 'reset_device_soft',
      label: t('tickets.charger_action_reset_device_soft'),
    },
    {
      action: 'reset_device_hard',
      label: t('tickets.charger_action_reset_device_hard'),
    },
  ];

  const [chargerActionSelected, setChargerActionSelected] = useState('');

  useEffect(() => {
    if (
      station?.connectorsList &&
      Object.keys(station?.connectorsList)?.length > 0
    ) {
      setConnectors(station.connectorsList);
      setConnectorSelected('');
      if (station.type !== 'uc' && chargerActionSelected === '') {
        setChargerActionSelected(charger_actions[0].action);
      }
    } else {
      setConnectors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerActionSelected, station]);

  const resetDevice = station => {
    if (station.type === 'uc') {
      console.log('uc reset');
    } else if (station.type === 'kempower') {
      console.log('kempower reset');
    } else if (station.type === 'ocpp') {
      console.log('ocpp reset');
    } else {
      toast(
        `${t('alerts.not_recognized')} ${station.name} ${t(
          'alerts.command_failed',
        )}`,
      );
    }

    if (
      window.location.host.includes('localhost') ||
      window.location.host.includes('staging')
    ) {
      console.log('Reset not available in localhost or staging');
    } else {
      console.log('not localhost');
      axios
        .post(
          `https://${window.location.host.replace(
            /ikea\.ev|mgmt\.enersense/g,
            'ig.enersense',
          )}/stations/${station.id}/reboot`,
          {},
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          console.log(res);
          toast(
            `${t('places.charging_station')} ${station.name} ${t(
              'alerts.command_running',
            )}`,
          );
        })
        .catch(err => {
          console.log(err);
          toast(`${station.name} ${t('alerts.command_failed')}`);
          Sentry.captureException(err);
        });
    }
  };

  const sofRestDevice = station => {
    if (station?.id) {
      axios
        .post(
          `https://${window.location.host.replace(
            /ikea\.ev|mgmt\.enersense/g,
            'ig.enersense',
          )}/stations/${station.id}/reboot-soft`,
          {},
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          console.log(res);
          toast(
            `${t('places.charging_station')} ${station.name} ${t(
              'alerts.command_running',
            )}`,
          );
        })
        .catch(err => {
          console.log(err);
          toast(`${station.name} ${t('alerts.command_failed')}`);
          Sentry.captureException(err);
        });
    } else {
      console.log('Station missing');
      toast(
        `${t('alerts.not_recognized')} ${station.name} ${t(
          'alerts.command_failed',
        )}`,
      );
    }
  };
  const unlockDeviceConnector = (station, connector) => {
    if (station?.id && connector !== '') {
      axios
        .post(
          `https://${window.location.host.replace(
            /ikea\.ev|mgmt\.enersense/g,
            'ig.enersense',
          )}/stations/${station.id}/${connector}/unlock`,
          {},
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          console.log(res);
          toast(
            `${t('places.charging_station')} ${station.name} ${t(
              'alerts.command_running',
            )}`,
          );
        })
        .catch(err => {
          console.log(err);
          toast(`${station.name} ${t('alerts.command_failed')}`);
          Sentry.captureException(err);
        });
    } else {
      console.log('Station or connector missing');
      toast(`${station.name} ${t('alerts.station_or_connector_missing')}`);
    }
  };
  const startDeviceTransaction = (station, connector) => {
    if (station?.id && connector !== '') {
      axios
        .post(
          `https://${window.location.host.replace(
            /ikea\.ev|mgmt\.enersense/g,
            'ig.enersense',
          )}/stations/${station.id}/${connector}/tx-start`,
          {},
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          console.log(res);
          toast(
            `${t('places.charging_station')} ${station.name} ${t(
              'alerts.command_running',
            )}`,
          );
        })
        .catch(err => {
          console.log(err);
          toast(`${station.name} ${t('alerts.command_failed')}`);
          Sentry.captureException(err);
        });
    } else {
      console.log('Station or connector missing');
      toast(`${station.name} ${t('alerts.station_or_connector_missing')}`);
    }
  };
  const stopDeviceTransaction = (station, connector) => {
    if (station?.id && connector !== '') {
      axios
        .post(
          `https://${window.location.host.replace(
            /ikea\.ev|mgmt\.enersense/g,
            'ig.enersense',
          )}/stations/${station.id}/${connector}/tx-stop`,
          {},
          {
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        )
        .then(res => {
          console.log(res);
          toast(
            `${t('places.charging_station')} ${station.name} ${t(
              'alerts.command_running',
            )}`,
          );
        })
        .catch(err => {
          console.log(err);
          toast(`${station.name} ${t('alerts.command_failed')}`);
          Sentry.captureException(err);
        });
    } else {
      console.log('Station or connector missing');
      toast(`${station.name} ${t('alerts.station_or_connector_missing')}`);
    }
  };

  const handleChargerAction = (action, station, connector) => {
    if (station.type === 'uc') {
      console.log('UC Reset');
      toast(
        `${t('alerts.not_recognized')} ${station.name} ${t(
          'alerts.command_failed',
        )}`,
      );
    } else {
      console.log('DC reset');
      if (
        window.location.host.includes('localhost') ||
        window.location.host.includes('staging')
      ) {
        console.log('Actions not available in localhost or staging');
      } else {
        switch (action) {
          case 'start':
            startDeviceTransaction(station, connector);
            break;
          case 'stop':
            stopDeviceTransaction(station, connector);
            break;
          case 'reset_device_soft':
            sofRestDevice(station);
            break;
          case 'reset_device_hard':
            resetDevice(station);
            break;
          case 'unlock_cable':
            unlockDeviceConnector(station, connector);
            break;
          default:
            toast(
              `${t('alerts.not_recognized')} ${station.name} ${t(
                'alerts.command_failed',
              )}`,
            );
            break;
        }
      }
    }
  };

  const GetNiceDateForTransaction = dateTime => {
    const date = new Date(dateTime);
    const dateStr = date.toLocaleDateString('fi-FI', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const timeStr = date.toLocaleTimeString('fi-FI', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const dateTimeStr = `${timeStr.replace(/\./g, ':')}, ${dateStr}`;
    if (dateStr === 'Invalid Date' || timeStr === 'Invalid Date') {
      return 'Ei aikaa saatavilla';
    }
    return dateTimeStr;
  };

  const TransactionDataRow = props => {
    return (
      <IonRow>
        <IonCol size="4">
          <IonText>{t(`place.${props.dataKey}`)}</IonText>
        </IonCol>
        <IonCol size="8">
          <IonText className="ion-margin-none ion-margin-bottom-half">
            {props.value}
          </IonText>
        </IonCol>
      </IonRow>
    );
  };

  const getNameForStationConnector = (stationId, connector) => {
    const station = place.stations.find(station => station.id === stationId);
    if (station) {
      return station.connector_names
        ? station.connector_names[connector]
        : connector;
    }
    return 'Station not found';
  };

  const energyToKWh = w => {
    return w / 1000 ?? '-';
  };

  const LatestTransactionDataDC = props => {
    return (
      <>
        <TransactionDataRow
          dataKey={'txId'}
          value={props.transaction?.id ?? '-'}
        />
        <TransactionDataRow
          dataKey={'idToken'}
          value={props.transaction?.attributes?.idtag ?? '-'}
        />
        <TransactionDataRow
          dataKey={'evseId'}
          value={props.transaction?.attributes?.connectorid ?? '-'}
        />
        {/* <TransactionDataRow
          dataKey={'evseType'}
          value={props.transaction?.evseType ?? '-'}
        /> */}
        <TransactionDataRow
          dataKey={'evseName'}
          value={
            getNameForStationConnector(
              props.transaction?.attributes?.stationid,
              props.transaction?.attributes?.connectorid,
            ) ??
            props.transaction?.evseName ??
            '-'
          }
        />
        <TransactionDataRow
          dataKey={'status'}
          value={props.transaction?.attributes?.status ?? '-'}
        />
        <TransactionDataRow
          dataKey={'startTime'}
          value={
            GetNiceDateForTransaction(
              props.transaction?.attributes?.starttime,
            ) ?? '-'
          }
        />
        <TransactionDataRow
          dataKey={'duration'}
          value={
            ((props.transaction?.attributes?.duration / 1000 / 60)?.toFixed(
              0,
            ) ?? '-') + ' min'
          }
        />
        {/* <TransactionDataRow
          dataKey={'chargingStartTime'}
          value={
            GetNiceDateForTransaction(props.transaction?.chargingStartTime) ??
            '-'
          }
        /> */}
        <TransactionDataRow
          dataKey={'chargedEnergyKwh'}
          value={
            (energyToKWh(props.transaction?.attributes?.energy) ?? '-') + ' kWh'
          }
        />
        <TransactionDataRow
          dataKey={'powerkW'}
          value={props.transaction?.attributes?.power ?? '-'}
        />
        {/* <TransactionDataRow
          dataKey={'maxPowerKw'}
          value={props.transaction?.maxPowerKw ?? '-'}
        /> */}
        <TransactionDataRow
          dataKey={'powerAvg'}
          value={
            ((
              energyToKWh(props.transaction?.attributes?.energy) /
              (props.transaction?.attributes?.duration / 1000 / 60 / 60)
            ).toFixed(3) ?? '-') + ' kWh'
          }
        />
        <TransactionDataRow
          dataKey={'soc'}
          value={props.transaction?.attributes?.soc ?? '-'}
        />
        <TransactionDataRow
          dataKey={'socStart'}
          value={props.transaction?.attributes?.initial_soc ?? '-'}
        />
      </>
    );
  };
  const LatestTransactionDataAC = props => {
    return (
      <>
        <TransactionDataRow
          dataKey={'flags'}
          value={props.transaction?.flags ?? '-'}
        />
        <TransactionDataRow
          dataKey={'chargedEnergyKwh'}
          value={
            props.transaction?.kwh
              ? props.transaction?.kwh.toFixed(2) + ' kWh'
              : '-'
          }
        />
        <TransactionDataRow
          dataKey={'duration'}
          value={
            props.transaction?.time
              ? hoursMinutesAndSeconds(props.transaction.time)
              : '-'
          }
        />
        <TransactionDataRow
          dataKey={'chargingStartTime'}
          value={GetNiceDateForTransaction(props.transaction?.date) ?? '-'}
        />
      </>
    );
  };

  const LatestTransactionsList = props => {
    return (
      <Accordion>
        <AccordionSummary
          className="transaction-summary-datetime"
          expandIcon={
            <IonIcon
              className="ion-margin-right-half"
              slot="start"
              icon={caretDownOutline}
              color="primary"
            />
          }
          aria-controls={`panel-${
            props.transaction.id ?? props.transaction.txId
          }`}
          id={`panel-${props.transaction.id ?? props.transaction.txId}`}>
          <IonText
            className="ion-margin-right"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}>
            {station.type === 'kempower' || station.type === 'ocpp'
              ? GetNiceDateForTransaction(
                  props.transaction?.attributes?.endtime ??
                    props.transaction?.attributes?.starttime,
                ) ?? '-'
              : GetNiceDateForTransaction(props.transaction?.date) ?? '-'}
          </IonText>
        </AccordionSummary>
        <AccordionDetails>
          <IonGrid className="transaction-details">
            <IonCol>
              <IonGrid className="ticket-data-table ion-padding-none">
                {station.type === 'kempower' || station.type === 'ocpp' ? (
                  <LatestTransactionDataDC transaction={props.transaction} />
                ) : (
                  <LatestTransactionDataAC transaction={props.transaction} />
                )}
              </IonGrid>
            </IonCol>
          </IonGrid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <IonGrid className="ion-padding-none ion-margin-top ticket-device-management">
      <IonRow style={{marginBottom: '12px'}}>
        <IonCol size="12">
          <div className="ion-margin-top">
            {place?.stations && place.stations.length > 0 && (
              <IonRow
                style={{marginBottom: '12px'}}
                className="device-management-stations">
                <IonCol size="12">
                  <Accordion expanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <IonIcon
                          className="ion-margin-right-half"
                          slot="start"
                          icon={chevronDownOutline}
                        />
                      }>
                      <IonText
                        className="ion-margin-right"
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                        }}>
                        <IonText>
                          <h5 className="ion-margin-none">
                            {t('place.device_functions')}
                          </h5>
                        </IonText>
                      </IonText>
                    </AccordionSummary>
                    <AccordionDetails>
                      <IonItem
                        className="ion-padding-none ion-margin-none"
                        lines="none">
                        <IonText>
                          {station.name}, UID {station.id}
                        </IonText>
                        {station.type === 'kempower' ||
                        station.type === 'ocpp' ? (
                          <>
                            <NativeSelect
                              className="ion-margin-right"
                              value={chargerActionSelected}
                              onChange={e => {
                                setChargerActionSelected(e.target.value);
                              }}
                              inputProps={{
                                name: 'charger-action',
                                id: 'charger-action',
                              }}
                              slot="end">
                              {charger_actions.map((action, i) => {
                                return (
                                  <option key={i} value={action.action}>
                                    {action.label}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                            {(chargerActionSelected === 'start' ||
                              chargerActionSelected === 'stop' ||
                              chargerActionSelected === 'unlock_cable') && (
                              <NativeSelect
                                className="ion-margin-right"
                                value={connectorSelected}
                                onChange={e => {
                                  setConnectorSelected(e.target.value);
                                }}
                                inputProps={{
                                  name: 'charger-action-select-connector',
                                  id: 'charger-action-select-connector',
                                }}
                                slot="end">
                                <option key="default-connector" value="">
                                  Select cable ...
                                </option>
                                {Object.keys(connectors).map((key, i) => {
                                  return (
                                    <option key={key} value={key}>
                                      Latauskaapeli {key}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            )}
                            <IonButton
                              className=""
                              fill="solid"
                              slot="end"
                              size="default"
                              onClick={() =>
                                alertBox({
                                  cssClass: 'confirm-alert-box',
                                  header: `${t(
                                    `alerts.${chargerActionSelected}`,
                                  )}`,
                                  message: `${t(
                                    `alerts.${chargerActionSelected}_are_you_sure`,
                                  )} ${connectorSelected}`,
                                  buttons: [
                                    `${t('alerts.cancel')}`,
                                    {
                                      text: `${t('alerts.yes')}`,
                                      handler: d => {
                                        handleChargerAction(
                                          chargerActionSelected,
                                          station,
                                          connectorSelected,
                                        );
                                      },
                                    },
                                  ],
                                  onDidDismiss: e =>
                                    console.log('Reset action dismissed'),
                                })
                              }
                              disabled={!permissions?.places}>
                              {t('tickets.charger_action_run')}
                            </IonButton>
                          </>
                        ) : (
                          <IonButton
                            className="ion-margin-left"
                            fill="solid"
                            slot="end"
                            size="default"
                            // onClick={() => console.log('Resetoi laite')}
                            onClick={() =>
                              alertBox({
                                cssClass: 'confirm-alert-box',
                                header: `${t('alerts.restart')} ${
                                  station.name
                                } ${t('alerts.again')}`,
                                message: `${t('alerts.reset_are_you_sure')}`,
                                buttons: [
                                  `${t('alerts.cancel')}`,
                                  {
                                    text: `${t('alerts.yes')}`,
                                    handler: d => {
                                      resetDevice(station);
                                    },
                                  },
                                ],
                                onDidDismiss: e =>
                                  console.log('Reset action dismissed'),
                              })
                            }
                            disabled={!permissions?.tickets}>
                            {t('tickets.charger_action_reset_device')}
                          </IonButton>
                        )}
                      </IonItem>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <IonIcon
                          className="ion-margin-right-half"
                          slot="start"
                          icon={chevronDownOutline}
                        />
                      }>
                      <IonText
                        className="ion-margin-right"
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                        }}>
                        <IonText>
                          <h5 className="ion-margin-none">
                            {station.type === 'uc'
                              ? t('place.device_functions_ac_last')
                              : t('place.device_functions_dc_last')}
                          </h5>
                        </IonText>
                      </IonText>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                      {latestTransactions?.length > 0 && (
                        <IonGrid className="ion-padding-none">
                          <IonCol>
                            <IonGrid className="ticket-data-table ion-padding-none">
                              {latestTransactions?.map((val, i) => {
                                return (
                                  <LatestTransactionsList
                                    transaction={val}
                                    key={i}
                                  />
                                );
                              })}
                            </IonGrid>
                          </IonCol>
                        </IonGrid>
                      )}
                      {latestTransactions?.length < 1 && isLoading && (
                        <IonSpinner name="dots"></IonSpinner>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </IonCol>
              </IonRow>
            )}
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TicketDeviceManagement;
