import React, {useState, useEffect, useRef, useContext} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonButton,
  IonModal,
  IonItem,
  IonThumbnail,
  IonImg,
} from '@ionic/react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  NativeSelect,
} from '@material-ui/core';
import {
  trashOutline,
  imageOutline,
  syncOutline,
  saveOutline,
  chevronDownOutline,
} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import useToast from '../../../hooks/useToast';
import Compressor from 'compressorjs';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import * as Sentry from '@sentry/react';

const NewTicketModal = props => {
  const {permissions, user, places} = props;
  const {t} = useTranslation('link_app_ikea');
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  const [newIssue, setNewIssue] = useState({
    location: '',
    charging_station: '',
    connector: '',
    alert_name: '',
    severity: '',
    description: '',
    station: {},
    images: [],
  });

  const [place, setPlace] = useState(null);
  useEffect(() => {
    if (!_.isEmpty(props.place) && props.place !== place) {
      setPlace(props.place);
      setNewIssue({
        ...newIssue,
        location: props.place?.doc?.site_id,
        station: {},
        connector: '',
        severity: '',
        alert_name: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.place]);

  const [stations, setStations] = useState([]);
  useEffect(() => {
    if (place?.doc?.stations?.length > 0) {
      setStations(place.doc.stations);
    } else {
      setStations([]);
    }
  }, [place]);

  const [connectors, setConnectors] = useState(null);
  useEffect(() => {
    setNewIssue({...newIssue, connector: ''});
    if (newIssue.station?.id) {
      let selectedStation = [...stations];
      selectedStation = selectedStation.find(station => {
        return newIssue.station?.id === station.id;
      });
      setConnectors(selectedStation?.connectorsList);
    } else {
      setConnectors(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIssue.station]);

  const [guides, setGuides] = useState(null);
  useEffect(() => {
    if (!_.isEmpty(props.guides)) {
      setGuides(props.guides);
    }
  }, [props.guides]);

  const setNewPlaceLocation = placeId => {
    const newPlace = places.find(
      place => place.doc?.site_id.toString() === placeId.toString(),
    );
    setPlace(newPlace);
    setNewIssue({
      ...newIssue,
      location: newPlace.doc?.site_id,
      station: {},
      connector: '',
    });
  };

  // Image upload and comment variables
  const fileInput = useRef(null);
  const [newImage, setNewImage] = useState({});
  const [imageCaption, setImageCaption] = useState('');

  // Compress image before upload
  const handleCompressedUpload = e => {
    const img = e.target.files[0];
    new Compressor(img, {
      quality: 0.6,
      success: result => {
        setNewImage(result);
      },
    });
  };

  // Add image and caption to ticket
  const handleImageUpload = () => {
    if (
      newIssue.images.findIndex(x => x.attachment_id === newImage.name) === -1
    ) {
      const timestamp = Math.round(new Date() / 1000).toString();
      const attachments = {
        ...newIssue._attachments,
        [newImage.name !== 'image.jpg'
          ? newImage.name.replace(/ /g, '-')
          : `${timestamp}.jpg`]: {
          content_type: newImage.type,
          data: newImage,
        },
      };
      const images = [...newIssue.images];
      let newId =
        images.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  images.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const newImages = [
        ...images,
        {
          id: newId,
          attachment_id:
            newImage.name !== 'image.jpg'
              ? newImage.name.replace(/ /g, '-')
              : `${timestamp}.jpg`,
          text: imageCaption !== '' ? imageCaption : '',
        },
      ];

      const updatedTicket = {
        ...newIssue,
        _attachments: attachments,
        images: newImages,
      };

      setNewIssue(updatedTicket);
      setNewImage({});
      setImageCaption('');
    } else {
      toast(
        `${t('toast.image')} "${newImage.name}" ${t(
          'toast.image_already_added',
        )}`,
      );
      setNewImage({});
      setImageCaption('');
    }
  };

  // Handle image delete
  const handleImageDelete = imageId => {
    const images = [...newIssue.images];
    const imageIndex = images.findIndex(
      image => image.attachment_id === imageId,
    );
    images.splice(imageIndex, 1);
    const attachments = newIssue._attachments;
    delete attachments[imageId];
    setNewIssue({...newIssue, images: images, _attachments: attachments});
  };

  const checkSLA = (alert_name, severity) => {
    let correctSLA = '';
    if (alert_name === 'Kempower') {
      switch (severity) {
        case '1':
          correctSLA = 3;
          break;
        case '2':
          correctSLA = 24;
          break;
        case '3':
          correctSLA = 48;
          break;
        case '4':
          correctSLA = 72;
          break;
        case '5':
          break;
        default:
          break;
      }
    } else if (alert_name === 'Enersense') {
      switch (severity) {
        case '1':
          correctSLA = 3;
          break;
        case '2':
          correctSLA = 24;
          break;
        case '3':
          correctSLA = 72;
          break;
        case '4':
          correctSLA = 120;
          break;
        case '5':
          break;
        default:
          break;
      }
    }
    return correctSLA;
  };

  const saveModalChanges = () => {
    databases.tickets
      .allDocs()
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        const id =
          docs.length > 0
            ? (
                parseInt(
                  Math.max.apply(
                    Math,
                    docs.map(x => x.id),
                  ),
                ) + 1
              ).toString()
            : '1';
        const currentDateTime = new Date();
        const created = Math.round(currentDateTime / 1000).toString();

        const issuePlace = places.find(
          place => place.doc?.site_id === newIssue.location,
        );

        let error = {
          name: 'test',
          id: '1',
        };

        if (newIssue.alert_name === 'Enersense') {
          const correctGuide = guides.find(
            guide => guide.doc?.name === 'AC - Malfunction reported',
          );
          if (correctGuide) {
            error = {name: correctGuide.doc?.name, id: correctGuide.id};
          }
        } else if (newIssue.alert_name === 'Kempower') {
          const correctGuide = guides.find(
            guide => guide.doc?.name === 'DC - Malfunction reported',
          );
          if (correctGuide) {
            error = {name: correctGuide.doc?.name, id: correctGuide.id};
          }
        }

        const newTicketErrors = [
          {
            ALARM: 'on',
            STATION_ID: newIssue.station.id ?? '',
            ALARM_END_TIME: '0001-01-01T00:00:00Z',
            ALARM_START_TIME: currentDateTime.toISOString().slice(0, -5) + 'Z',
            CONNECTOR: newIssue.connector ?? '-',
            DESCRIPTION: issuePlace.doc?.name + ' created issue',
            SUMMARY: newIssue.station.name,
            APP_TYPE: newIssue.station.name,
            ALERTNAME: newIssue.alert_name + ' End user error',
            CATEGORY: issuePlace.doc?.name,
            SEVERITY: newIssue.severity,
            ALERT_TYPE: issuePlace.doc?.name + ' created issue',
          },
        ];

        let newTicket = {
          _id: id,
          is_active: true,
          error: error,
          errors: newTicketErrors,
          category: 'EV',
          sla: checkSLA(newIssue.alert_name, newIssue.severity),
          place: {name: issuePlace.doc?.name, site_id: issuePlace.doc?.site_id},
          fm_service: {},
          resourced: [],
          maintenance_phases: [],
          flow: {},
          flow_step: '',
          accessories: [],
          more_info: '',
          summary: '',
          documentation_guide: '',
          time_when_created: created,
          time_when_resourced: '',
          time_when_paused: '',
          time_when_started: '',
          time_when_accessories: '',
          time_when_at_place: '',
          time_when_completed: '',
          time_when_documented: '',
          comments: newIssue.description
            ? [
                {
                  id: 1,
                  time: created,
                  contact_id: user.name,
                  text: newIssue.description,
                },
              ]
            : [],
          images: newIssue.images ?? [],
          warehouse: {},
          alert_messages: [],
          stepper_step: '0',
          log: [],
          is_scheduled: false,
          scheduled_selected_frequencies: [],
          scheduled_start: '',
          scheduled_end: '',
          scheduled_alt_start: '',
          scheduled_alt_end: '',
          using_alt_date: false,
          station: newIssue.station?.id
            ? {
                id: newIssue.station?.id ?? '',
                name: newIssue.station?.name ?? '',
              }
            : {},
          ticket_status: '',
          resource_status: '',
          _attachments: newIssue._attachments ?? {},
        };

        let newId =
          newTicket.log?.length > 0
            ? (
                parseInt(
                  Math.max.apply(
                    Math,
                    newTicket.log?.map(x => x.id),
                  ),
                ) + 1
              ).toString()
            : '1';
        newTicket = {
          ...newTicket,
          log: newTicket.log
            ? [
                ...newTicket.log,
                {
                  id: newId,
                  time: created,
                  user: user,
                  app: 'ikea',
                  event: `created_ikea`,
                },
              ]
            : [
                {
                  id: newId,
                  time: created,
                  user: user,
                  app: 'ikea',
                  event: `created_ikea`,
                },
              ],
        };

        databases.tickets
          .post(newTicket)
          .then(() => {
            console.log(
              `${t('toast.ticket')} #${newTicket._id} ${t('toast.updated')}`,
            );
            toast(
              `${t('toast.ticket')} #${newTicket._id} ${t('toast.updated')}`,
            );
            setNewIssue({});
          })
          .catch(err => {
            console.log(
              `${t('toast.error_updating_ticket')} #${newTicket._id}:`,
              err,
            );
            toast(`${t('toast.error_updating_ticket')} #${newTicket._id}`, err);
            Sentry.captureException(err);
          });
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
    props.setModal('');
  };

  const [expandedSeverity, setExpandedSeverity] = React.useState(true);

  const SeverityClassifications = props => {
    const infoType =
      newIssue.alert_name === 'Kempower' ? 'lm_delivery' : 'customer_charging';
    return (
      <Accordion
        className="ikea-form-accordion"
        expanded={newIssue.alert_name !== '' && expandedSeverity}
        onChange={() => setExpandedSeverity(!expandedSeverity)}>
        <AccordionSummary
          expandIcon={
            <IonIcon
              className="ion-margin-right-half"
              slot="start"
              icon={chevronDownOutline}
            />
          }>
          <IonText>{t('ikea.modal.severity_info')}</IonText>
        </AccordionSummary>
        <AccordionDetails>
          <IonGrid className="severity-specifications">
            <IonCol>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_1`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_1_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_2`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_2_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_3`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_3_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_4`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_4_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_5`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_5_example`)}
              </p>
            </IonCol>
          </IonGrid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <IonModal
      key={'modal-new-ticket'}
      id={`modal-new-ticket`}
      isOpen={props.modal === `new-ticket-modal`}
      onDidDismiss={() => props.setModal('')}
      cssClass="station-panel-modal">
      <IonGrid className="ion-margin ion-padding">
        <IonCol>
          <h2>{t('ikea.modal.heading')}</h2>
          <h3 className="text-light">{t('ikea.modal.subheading')}</h3>
          <p className="ion-margin-top">
            <b>{t('ikea.modal.info_heading')}</b>
          </p>
          <p className="ion-margin-top">
            <b>{t('ikea.modal.info_text')}</b>
          </p>
          <IonRow className="row ion-margin-top">
            <FormControl className="row-input ion-margin-top">
              <div>
                <label htmlFor="location">{t('ikea.modal.location')} *</label>
              </div>
              {user?.metadata?.ikea_location === 'manager' ||
              permissions?.places ? (
                <NativeSelect
                  value={newIssue.location}
                  onChange={e => {
                    setNewIssue({...newIssue, location: e.target.value});
                    setNewPlaceLocation(e.target.value);
                  }}
                  inputProps={{
                    name: 'active',
                    id: 'active',
                  }}
                  className="ikea-modal-place-select">
                  {places.map((place, i) => {
                    return (
                      <option key={i} value={place.doc?.site_id}>
                        {place.doc?.name}, {place.doc?.address}
                      </option>
                    );
                  })}
                </NativeSelect>
              ) : (
                <TextField
                  id="location"
                  value={newIssue.location}
                  onChange={e => {
                    setNewIssue({...newIssue, location: e.target.value});
                  }}
                  disabled={!permissions?.places}
                />
              )}
            </FormControl>
          </IonRow>
          <IonRow className="row half ion-margin-top">
            <FormControl className="row-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="station">
                  {t('ikea.modal.charging_station')}
                </label>
              </div>
              <NativeSelect
                value={
                  newIssue.station
                    ? JSON.stringify(newIssue.station)
                    : JSON.stringify({
                        id: newIssue.id,
                        name: newIssue.name,
                        type: newIssue.type,
                      })
                }
                onChange={e => {
                  setNewIssue({
                    ...newIssue,
                    station: JSON.parse(e.target.value),
                  });
                }}
                inputProps={{
                  name: 'station',
                  id: 'station',
                }}>
                <option value={JSON.stringify({})}>
                  {t('ikea.modal.charging_station_if_known')}...
                </option>
                {!_.isEmpty(newIssue.station) && (
                  <option value={JSON.stringify(newIssue.station)}>
                    {newIssue.station.name}
                  </option>
                )}
                {stations &&
                  stations.length > 0 &&
                  stations
                    .filter(station => {
                      return newIssue.station?.id !== station.id;
                    })
                    .map((station, i) => {
                      return (
                        <option
                          key={i}
                          value={JSON.stringify({
                            id: station.id,
                            name: station.name,
                            type: station.type,
                          })}>
                          {station.name}
                        </option>
                      );
                    })}
              </NativeSelect>
            </FormControl>
            <FormControl className="row-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="connector">{t('ikea.modal.connector')}</label>
              </div>
              <NativeSelect
                value={newIssue.connector}
                onChange={e => {
                  setNewIssue({
                    ...newIssue,
                    connector: e.target.value,
                  });
                }}
                disabled={_.isEmpty(newIssue.station)}
                inputProps={{
                  name: 'connector',
                  id: 'connector',
                }}>
                <option value={''}>
                  {t('ikea.modal.choose')}{' '}
                  {t('ikea.modal.connector').toLowerCase()}
                </option>
                {newIssue.connector !== '' && (
                  <option value={newIssue.connector}>
                    {newIssue.connector}
                  </option>
                )}
                {connectors &&
                  Object.keys(connectors)
                    .filter(connector => newIssue.connector !== connector)
                    .map((connector, i) => {
                      return (
                        <option key={i} value={connector}>
                          {connector}
                        </option>
                      );
                    })}
              </NativeSelect>
            </FormControl>
          </IonRow>
          <IonRow className="row ion-margin-top">
            <FormControl className="row-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="choose_issue">
                  {t('ikea.modal.choose_charging_area')} *
                </label>
              </div>
              <NativeSelect
                value={newIssue.alert_name}
                onChange={e => {
                  setExpandedSeverity(true);
                  setNewIssue({
                    ...newIssue,
                    alert_name: e.target.value,
                  });
                }}
                inputProps={{
                  name: 'alert_name',
                  id: 'alert_name',
                }}>
                <option value={''}>{t('ikea.modal.select_area')}</option>
                <option key={'Kempower'} value="Kempower">
                  Last mile delivery
                </option>
                <option key={'Enersense'} value="Enersense">
                  Customer charging
                </option>
              </NativeSelect>
            </FormControl>
          </IonRow>
          <IonRow>
            <SeverityClassifications />
          </IonRow>
          <IonRow className="row ion-margin-top">
            <FormControl className="row-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="choose_issue">
                  {t('ikea.modal.choose_severity')} *
                </label>
              </div>
              <NativeSelect
                value={newIssue.severity}
                onChange={e => {
                  setNewIssue({
                    ...newIssue,
                    severity: e.target.value,
                  });
                }}
                inputProps={{
                  name: 'severity',
                  id: 'severity',
                }}>
                <option value={''}>{t('ikea.modal.select_type')}</option>
                {[1, 2, 3, 4, 5].map(number => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </IonRow>
          <IonRow className="row ion-margin-top">
            <FormControl className="row-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="description">
                  {t('ikea.modal.description')} *
                </label>
              </div>
              <textarea
                id="description"
                name="description"
                value={newIssue.description}
                onChange={e => {
                  setNewIssue({...newIssue, description: e.target.value});
                }}
              />
            </FormControl>
          </IonRow>
          <IonRow className="row ion-margin-top half">
            {newIssue._attachments &&
              newIssue.images.length > 0 &&
              newIssue.images.map((image, i) => {
                let attachment;
                if (newIssue._attachments && image.attachment_id !== '') {
                  attachment = newIssue._attachments[image.attachment_id];
                }
                return (
                  <IonItem key={i} lines="none" className="ion-margin-bottom">
                    <IonThumbnail slot="start">
                      {attachment && attachment.digest && (
                        <IonImg
                          src={`data:${attachment.content_type};base64,${attachment.data}`}
                          alt=""
                        />
                      )}
                      {attachment && !attachment.digest && (
                        <IonImg
                          src={window.URL.createObjectURL(attachment.data)}
                          alt=""
                        />
                      )}
                    </IonThumbnail>
                    <IonText className="ion-margin-left">
                      <p className="ion-margin-none">{image.text}</p>
                    </IonText>
                    <IonButton
                      disabled={false}
                      className="ion-margin-none delete-button"
                      slot="end"
                      onClick={() => handleImageDelete(image.attachment_id)}
                      size="small"
                      fill="clear"
                      color="medium">
                      <IonIcon slot="icon-only" icon={trashOutline} />
                    </IonButton>
                  </IonItem>
                );
              })}
            <div className="file-upload">
              <div className="file-input-container">
                <div className="file-input-wrap">
                  {newImage.name && (
                    <p className="ion-margin-none ion-margin-right-half">
                      {newImage.name}
                    </p>
                  )}
                  {!newImage.name && (
                    <>
                      <IonButton
                        size="default"
                        fill="outline"
                        color={'dark'}
                        onClick={() => fileInput.current.click()}>
                        <IonIcon
                          className="ion-margin-right-half"
                          slot="end"
                          icon={newImage.name ? syncOutline : imageOutline}
                        />
                        {t('ikea.modal.upload_images')}
                      </IonButton>
                      <input
                        ref={fileInput}
                        type="file"
                        accept="image/*"
                        hidden
                        value=""
                        onChange={e => handleCompressedUpload(e)}
                      />
                    </>
                  )}
                </div>
                {newImage.name && (
                  <div className="file-input-wrap">
                    <FormControl className="row-input">
                      <div>
                        <label htmlFor="location">
                          {t('ikea.modal.image_desc')}
                        </label>
                      </div>
                      <TextField
                        id="image-desc"
                        placeholder={t('ikea.modal.image_desc')}
                        value={imageCaption}
                        onChange={e => setImageCaption(e.target.value)}
                        autoComplete="off"
                      />
                    </FormControl>
                    <IonButton
                      className="image-upload-button"
                      size="large"
                      fill="clear"
                      onClick={() => handleImageUpload()}>
                      {t('ikea.modal.save_image')}
                      <IonIcon slot="end" icon={saveOutline} />
                    </IonButton>
                  </div>
                )}
              </div>
            </div>
          </IonRow>
        </IonCol>
      </IonGrid>
      <IonRow className="ion-justify-content-end ion-align-items-center">
        <IonButton
          className="ion-margin"
          size="medium"
          color="primary"
          fill="outline"
          onClick={() => props.setModal('')}>
          {t('ikea.modal.cancel').toUpperCase()}
        </IonButton>
        <IonButton
          className="ion-margin-right"
          size="medium"
          color="primary"
          fill="solid"
          onClick={() => saveModalChanges()}>
          {t('ikea.modal.submit').toUpperCase()}
        </IonButton>
      </IonRow>
    </IonModal>
  );
};
export default NewTicketModal;
