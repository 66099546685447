import React, {useState, useEffect} from 'react';
import {IonContent, IonPage, IonButton, IonIcon, IonText} from '@ionic/react';
import {ReactComponent as LogoBlack} from '../../assets/logo-black.svg';
import {ReactComponent as LogoWhite} from '../../assets/logo-white.svg';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {Paper} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import useToast from '../../hooks/useToast';
import useConfig from '../../hooks/useConfig';
import * as Sentry from '@sentry/react';

const ResetPassword = props => {
  const {match} = props;
  const {t} = useTranslation('link_app');
  const toast = useToast();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [token, setToken] = useState('');
  const [tokenError, setTokenError] = useState('');

  useEffect(() => {
    const tryToken = match.params.token;
    console.log(tryToken);
    setToken(tryToken);
  }, [match, t]);

  useEffect(() => {
    if (token !== '') {
      checkToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const checkToken = async token => {
    setTokenError('');
    const fetchUrl = `${integrator}/reset-password`;
    await fetch(fetchUrl + '/' + token, {
      method: 'GET',
      headers: {
        Authorization:
          'Basic ' +
          Buffer.from(
            process.env.REACT_APP_IG_USERNAME +
              ':' +
              process.env.REACT_APP_IG_PASSWORD,
            'utf-8',
          ).toString('base64'),
      },
    })
      .then(res => {
        if (res.status === 204) {
          console.log('Token validated');
        } else {
          console.log('Token not found or already used:', res.status);
          setTokenError('Password reset token not found or already used');
        }
      })
      .catch(err => {
        console.log('Token not found or already used: ', err);
        setTokenError('Password reset token not found or already used');
        Sentry.captureException(err);
      });
  };
  const {integrator} = useConfig();
  const handlePasswordReset = async e => {
    e.preventDefault();
    // Add logic for handling password reset
    // You can send a request to your server to update the password
    // Also, include logic to validate passwords and handle errors
    const fetchUrl = `${integrator}/reset-password`;
    await fetch(fetchUrl + '/' + token, {
      method: 'POST',
      body: JSON.stringify({
        password: password,
      }),
      headers: {
        Authorization:
          'Basic ' +
          Buffer.from(
            process.env.REACT_APP_IG_USERNAME +
              ':' +
              process.env.REACT_APP_IG_PASSWORD,
            'utf-8',
          ).toString('base64'),
      },
    })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          console.log('Password reset succesfull');
          toast(t('toast.password_changed'));
          setPassword('');
          setConfirmPassword('');
        } else {
          console.log('Password reset error:', res.status);
          toast(t('toast.unhandled_error'));
        }
      })
      .catch(err => {
        console.log('Password reset error:', err);
        toast(t('toast.unhandled_error'));
        Sentry.captureException(err);
      });
  };

  return (
    <IonPage>
      <IonContent>
        <div className="login">
          {/* Assuming your Paper component and Logo components are used */}
          <Paper>
            <div className="login-wrap ion-text-center">
              <LogoBlack className="ion-margin-bottom-half logo-black" />
              <LogoWhite className="ion-margin-bottom-half logo-white" />
              <p>Password Reset</p>
              {tokenError !== '' ? (
                <IonText>{tokenError}</IonText>
              ) : (
                <form
                  onSubmit={handlePasswordReset}
                  className="ion-text-center">
                  <input
                    className={`ion-margin-bottom normal-padding ${
                      !passwordsMatch ? 'red-border' : ''
                    }`}
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                      setPasswordsMatch(e.target.value === confirmPassword);
                    }}
                    autoComplete="off"
                    type={isPwVisible ? 'text' : 'password'}
                    placeholder="New Password"
                  />
                  <div
                    className={`pw-wrap ion-margin-bottom ${
                      !passwordsMatch ? 'red-border' : ''
                    }`}>
                    <input
                      className="ion-margin-none"
                      value={confirmPassword}
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                        setPasswordsMatch(e.target.value === password);
                      }}
                      autoComplete="off"
                      type={isPwVisible ? 'text' : 'password'}
                      placeholder="Confirm Password"
                    />
                    <IonButton
                      onClick={() => setIsPwVisible(!isPwVisible)}
                      fill="clear"
                      color="medium">
                      <IonIcon
                        style={{fontSize: '22px'}}
                        slot="icon-only"
                        icon={
                          isPwVisible ? eyeOffOutline : eyeOutline
                        }></IonIcon>
                    </IonButton>
                  </div>
                  {!passwordsMatch && (
                    <IonText color="danger">
                      {t('toast.password_match')}
                    </IonText>
                  )}
                  <IonButton className="ion-margin-top" type="submit">
                    Reset Password
                  </IonButton>
                </form>
              )}
            </div>
            <div className="ion-margin-top ion-text-end">
              <Link to={`/login`}>Back to login</Link>
            </div>
          </Paper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
