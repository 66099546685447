/* eslint-disable import/no-webpack-loader-syntax */
import React, {useState, useContext, useEffect} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {FormControl, NativeSelect} from '@material-ui/core';
import {alertCircleOutline} from 'ionicons/icons';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import {AuthContext} from '../../../components/context/AuthContext';
import {UserContext} from '../../../components/context/UserContext';
import IkeaMonitoring from './IkeaMonitoring';
import Events from './Events';
import IkeaTickets from './IkeaTickets';
import NewTicketModal from './NewTicketModal';
import EditTicketModal from './EditTicketModal';
import useTitleEffect from '../../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const Dashboard = props => {
  const {t} = useTranslation('link_app_ikea');
  const [user] = useContext(UserContext);
  const {match, permissions} = props;
  const [segment, setSegment] = useState('lm_delivery');

  useTitleEffect(t('nav.tickets'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  const [modal, setModal] = useState('');

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch tickets
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
        attachments: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        setTickets(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, match, setIsAuth]);

  // Fetch places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    if (user.metadata !== undefined) {
      databases.places
        .allDocs({
          include_docs: true,
        })
        .then(result => {
          let docs = result.rows.filter(row => {
            return !row.id.includes('_design');
          });
          if (
            user?.metadata?.ikea_location &&
            user?.metadata?.ikea_location !== null &&
            user?.metadata?.ikea_location !== 'manager'
          ) {
            docs = docs.filter(
              place =>
                place.doc.site_id.toString() === user.metadata.ikea_location,
            );
          }
          setPlaces(docs);
        })
        .catch(err => {
          console.log(err);
          Sentry.captureException(err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databases.places, match, setIsAuth]);

  // Fetch all guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.guides]);

  // Filter options
  const [statuses, setStatuses] = useState([]);
  const [placeNames, setPlaceNames] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);

  // Get place and tickets
  useEffect(() => {
    tickets.forEach(item => {
      if (
        statuses.indexOf(item.doc?.ticket_status) === -1 &&
        item.doc?.ticket_status !== ''
      ) {
        if (!item.doc?.ticket_status || item.doc?.ticket_status === 'faulted') {
          if (statuses.indexOf('faulted') === -1) {
            setStatuses([...statuses, 'faulted']);
          }
        } else {
          setStatuses([...statuses, item.doc?.ticket_status]);
        }
      }
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station?.id?.toString() ===
            item?.doc?.errors[0].STATION_ID?.toString(),
        ),
      );
      if (place) {
        if (
          placeNames.indexOf(place.doc?.name) === -1 &&
          place.doc?.name !== ''
        ) {
          setPlaceNames([...placeNames, place.doc?.name].sort());
        }
        const station = place.doc?.stations.find(
          station =>
            station.id.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        );
        if (station) {
          if (
            chargingStations.indexOf(station.name) === -1 &&
            station.name !== ''
          ) {
            setChargingStations([...chargingStations, station.name].sort());
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places, tickets]);

  const [selectedPlace, setSelectedPlace] = useState(null);
  useEffect(() => {
    if (places.length > 0) {
      setSelectedPlace(places[0]);
    }
  }, [places]);

  const handlePlaceSelectChange = id => {
    const newPlace = places.filter(place => place.id === id);
    setSelectedPlace(newPlace[0]);
  };

  // Filter tickets by active
  const SelectPlaceInput = () => {
    return (
      <FormControl className="ion-margin-none">
        <NativeSelect
          value={selectedPlace?.id}
          onChange={e => handlePlaceSelectChange(e.target.value)}
          inputProps={{
            name: 'active',
            id: 'active',
          }}
          className="ikea-place-select">
          {places.map((place, i) => {
            return (
              <option key={i} value={place.id}>
                {place.doc?.name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    );
  };

  const [modalTicket, setModalTicket] = useState();
  const openEditTicketModal = ticket => {
    if (ticket) {
      setModalTicket(ticket.doc);
      setModal('edit-ticket-modal');
    }
  };

  return (
    <IonPage className="ikea">
      <IonContent>
        <IonGrid>
          <IonRow style={{flexWrap: 'nowrap'}}>
            <IonCol
              style={{flexGrow: 0}}
              className="ion-padding-left ion-margin-bottom ion-margin-top ion-margin-right ion-padding-right ">
              <SelectPlaceInput />
            </IonCol>
            <IonCol className="ion-padding-left ion-padding-right ion-margin-top">
              <IonButton
                fill="clear"
                className="ikea-report-button ion-float-right"
                onClick={() => {
                  setModal('new-ticket-modal');
                }}
                disabled={false}>
                {t('ikea.dashboard.report_a_problem')}
              </IonButton>
              <IonItem
                className="ikea-info-box ion-float-right ion-margin-right"
                lines="none">
                <IonIcon
                  slot="start"
                  color="danger"
                  icon={alertCircleOutline}
                  className="ion-margin-right-half"
                />
                <IonText>
                  {t('ikea.dashboard.in_case_of_emergency')}
                  <span className="phone-number"> +358 29 020 9500</span>
                </IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-left ion-margin-top-half">
            <IonSegment
              scrollable
              onIonChange={e => setSegment(e.detail.value)}
              value={segment}
              className="segment-blue ikea-segment-select">
              <IonSegmentButton value="lm_delivery">
                <IonLabel>{t('ikea.lm_delivery_charging')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="customer">
                <IonLabel>{t('ikea.customer_charging')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="events">
                <IonLabel>{t('ikea.events.events')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonRow>
          <IonRow>
            <IonCol>
              {segment === 'customer' && (
                <>
                  <div className="ikea-tickets ion-margin-top">
                    <IkeaTickets
                      tickets={tickets}
                      place={selectedPlace}
                      permissions={permissions}
                      chargerTypes={['uc']}
                      openEditTicketModal={openEditTicketModal}
                    />
                  </div>
                  <div className="charging-graphs ion-margin-top">
                    <IonRow className="ion-padding-left">
                      <h2 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                        {t('place.monitoring_panels')}
                      </h2>
                    </IonRow>
                    <IkeaMonitoring
                      match={match}
                      permissions={permissions}
                      place={selectedPlace}
                      chargerTypes={['uc']}
                    />
                  </div>
                </>
              )}
              {segment === 'lm_delivery' && (
                <>
                  <div className="ikea-tickets ion-margin-top">
                    <IkeaTickets
                      tickets={tickets}
                      place={selectedPlace}
                      permissions={permissions}
                      isFetching={isFetching}
                      chargerTypes={['kempower', 'ocpp']}
                      openEditTicketModal={openEditTicketModal}
                    />
                  </div>
                  <div className="charging-graphs ion-margin-top">
                    <IonRow className="ion-padding-left">
                      <h2 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
                        {t('place.monitoring_panels')}
                      </h2>
                    </IonRow>
                    <IkeaMonitoring
                      match={match}
                      permissions={permissions}
                      place={selectedPlace}
                      chargerTypes={['kempower', 'ocpp']}
                    />
                  </div>
                </>
              )}
              {segment === 'events' && (
                <div className="ion-margin-top">
                  <Events
                    match={match}
                    permissions={permissions}
                    place={selectedPlace}
                    tickets={tickets}
                  />
                </div>
              )}
            </IonCol>
          </IonRow>
          <NewTicketModal
            modal={modal}
            setModal={setModal}
            place={selectedPlace}
            user={user}
            places={places}
            permissions={permissions}
            guides={guides}
          />
          <EditTicketModal
            modal={modal}
            setModal={setModal}
            place={selectedPlace}
            user={user}
            places={places}
            permissions={permissions}
            guides={guides}
            modalTicket={modalTicket}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
