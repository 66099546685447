import React, {useEffect, useState, useContext} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@material-ui/core';
import {
  chevronDownOutline,
  checkmarkOutline,
  alertCircleOutline,
  pinOutline,
  volumeHighOutline,
  peopleOutline,
} from 'ionicons/icons';
import {DatabaseContext} from '../context/DatabaseContext';
import DateTime from '../utils/DateTime';
import {useTranslation} from 'react-i18next';
import * as Sentry from '@sentry/react';

const PlaceLog = props => {
  const {place} = props;
  const {t} = useTranslation('link_app');

  // Databases
  const databases = useContext(DatabaseContext);

  // Fetch ticket history
  const [ticketHistory, setTicketHistory] = useState([]);
  useEffect(() => {
    databases.ticket_history
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return (
            !row.id.includes('_design') &&
            row.doc.place.site_id === place.site_id
          );
        });

        setTicketHistory(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.ticket_history, props.isLoading, place]);

  return (
    <IonGrid className="ion-padding-none">
      <IonRow style={{marginBottom: '12px'}}>
        <IonCol size="12">
          <div className="ion-margin-top">
            {ticketHistory.length > 0 ? (
              ticketHistory.map((item, i) => {
                return (
                  <IonRow key={i} style={{marginBottom: '12px'}}>
                    <IonCol size="12">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <IonIcon
                              className="ion-margin-right-half"
                              slot="start"
                              icon={chevronDownOutline}
                            />
                          }>
                          <IonIcon
                            className="ion-margin-right-half"
                            style={{fontSize: '20px'}}
                            icon={alertCircleOutline}
                            color="danger"
                            slot="start"
                          />
                          <IonText
                            className="ion-margin-right"
                            style={{display: 'flex', alignItems: 'center'}}>
                            <p className="ion-margin-none">
                              <DateTime
                                timestamp={item.doc?.time_when_created}
                              />
                            </p>
                          </IonText>
                          {item.doc?.time_when_completed && (
                            <>
                              <IonIcon
                                className="ion-margin-right-half"
                                style={{fontSize: '20px'}}
                                icon={checkmarkOutline}
                                color="success"
                                slot="start"
                              />
                              <IonText
                                style={{display: 'flex', alignItems: 'center'}}>
                                <p className="ion-margin-none">
                                  <DateTime
                                    timestamp={item.doc?.time_when_completed}
                                  />
                                </p>
                              </IonText>
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          {item?.doc?.summary !== '' && (
                            <IonText>
                              <p className="ion-margin-none ion-margin-bottom-half">
                                <b>{t('place.log_summary')}: </b>
                                {item?.doc?.summary}
                              </p>
                            </IonText>
                          )}
                          <br />
                          {item?.doc?.more_info !== '' && (
                            <IonText>
                              <p className="ion-margin-none ion-margin-bottom-half">
                                <b>{t('place.log_more_info')}: </b>
                                {item?.doc?.more_info}
                              </p>
                            </IonText>
                          )}
                          <IonList>
                            {item?.doc?.error?.id && item?.doc?.error?.name && (
                              <IonItem lines="none">
                                <IonIcon
                                  icon={alertCircleOutline}
                                  color="danger"
                                  slot="start"
                                />
                                <IonLabel color="danger">
                                  {item?.doc?.error?.name}
                                </IonLabel>
                              </IonItem>
                            )}
                            {item?.doc?.site?.id && item?.doc?.site?.name && (
                              <IonItem lines="none" target="_blank">
                                <IonIcon
                                  icon={pinOutline}
                                  color="primary"
                                  slot="start"
                                />
                                <IonLabel>{item?.doc?.site?.name}</IonLabel>
                              </IonItem>
                            )}
                            {item?.doc?.fm_service?.id &&
                              item?.doc?.fm_service?.name && (
                                <IonItem lines="none">
                                  <IonIcon
                                    icon={volumeHighOutline}
                                    color="primary"
                                    slot="start"
                                  />
                                  <IonLabel>
                                    {item?.doc?.fm_service?.name}
                                  </IonLabel>
                                </IonItem>
                              )}
                            {item.doc?.resourced?.length > 0 && (
                              <IonItem lines="none">
                                <IonIcon
                                  icon={peopleOutline}
                                  color="primary"
                                  slot="start"
                                />
                                <IonLabel>
                                  {item.doc.resourced.map((contact, i) => {
                                    return `${contact?.name}${
                                      item.doc?.resourced?.length === i + 1
                                        ? ''
                                        : ', '
                                    }`;
                                  })}
                                </IonLabel>
                              </IonItem>
                            )}
                          </IonList>
                          <IonButton
                            className="ion-margin-none"
                            fill="clear"
                            color="primary"
                            routerLink={`/tickets/${item.doc?._id}`}>
                            {t('place.show_ticket')}
                          </IonButton>
                        </AccordionDetails>
                      </Accordion>
                      <Divider className="ion-margin-top" />
                    </IonCol>
                  </IonRow>
                );
              })
            ) : (
              <IonRow style={{marginBottom: '12px'}}>
                <IonCol size="12">
                  <IonText style={{display: 'flex', alignItems: 'center'}}>
                    <p className="ion-margin-none">
                      {t('helper_texts.no_information')}
                    </p>
                  </IonText>
                </IonCol>
              </IonRow>
            )}
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default PlaceLog;
