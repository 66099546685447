import React, {useState, useContext, useEffect} from 'react';
import Header from '../../components/navigation/Header';
import PlaceInfo from '../../components/places/PlaceInfo';
import PlaceMonitoring from '../../components/utils/PlaceMonitoring';
import PlaceLog from '../../components/places/PlaceLog';
import PlaceDeviceManagement from '../../components/places/PlaceDeviceManagement';
import PlaceChargingStations from '../../components/places/PlaceChargingStations';
import PlaceGeneral from '../../components/places/PlaceGeneral';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSegmentButton,
  IonSegment,
  IonLabel,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import useToast from '../../hooks/useToast';
import useTitleEffect from '../../hooks/useTitle';
import {AuthContext} from '../../components/context/AuthContext';
import Loading from '../../components/utils/Loading';
import PlaceLocation from '../../components/places/PlaceLocation';
import {UserContext} from '../../components/context/UserContext';
import * as Sentry from '@sentry/react';

const Place = props => {
  const {t} = useTranslation('link_app');
  const {match, permissions, history} = props;
  const [, height] = useWindowSize();
  const [user] = useContext(UserContext);
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the place according to type
  const [place, setPlace] = useState({});
  useEffect(() => {
    if (match.params.id !== 'new-site') {
      databases.places
        .get(match.params.id, {attachments: true})
        .then(result => {
          setPlace(result);
        })
        .catch(err => {
          console.log(err);
          Sentry.captureException(err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        });
    } else {
      setPlace({});
    }
  }, [databases.places, match, props.isLoading, setIsAuth]);

  // Fetch contacts
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    databases.contacts
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setContacts(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.contacts, match, props.isLoading, setIsAuth]);

  // Setting title based on what type of place we have open
  const title =
    match.params.id === 'new-site' ? t('places.new_mast') : place.name;
  useTitleEffect(title);

  // Handle saving place to backend
  const savePlaceChanges = placeToSave => {
    if (placeToSave._id === '') {
      // Auto incerement _id
      databases.places
        .allDocs()
        .then(result => {
          const docs = result.rows.filter(row => {
            return !row.id.includes('_design');
          });
          placeToSave._id =
            docs.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      docs.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';

          databases.places
            .put(placeToSave)
            .then(res => {
              return databases.places.get(placeToSave._id, {attachments: true});
            })
            .then(result => {
              setPlace(result);
              console.log(
                `${t('toast.place')} "${result.name}" ${t('toast.updated')}`,
              );
              toast(
                `${t('toast.place')} "${result.name}" ${t('toast.updated')}`,
              );
              if (match.params.id.includes('new')) {
                props.history.replace(
                  `/places/${placeToSave.type}/${placeToSave._id}`,
                );
              }
            })
            .catch(err => {
              console.log(
                `${t('toast.error_updating_place')} "${placeToSave.name}":`,
                err,
              );
              toast(
                `${t('toast.error_updating_place')} "${placeToSave.name}"`,
                err,
              );
              Sentry.captureException(err);
            });
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_place')} "${placeToSave.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_place')} "${placeToSave.name}"`,
            err,
          );
          Sentry.captureException(err);
        });
    } else {
      databases.places
        .get(placeToSave._id)
        .then(result => {
          placeToSave._rev = result._rev;
          databases.places
            .put(placeToSave)
            .then(res => {
              return databases.places.get(placeToSave._id, {attachments: true});
            })
            .then(result => {
              setPlace(result);
              console.log(
                `${t('toast.place')} "${result.name}" ${t('toast.updated')}`,
              );
              toast(
                `${t('toast.place')} "${result.name}" ${t('toast.updated')}`,
              );
              if (match.params.id.includes('new')) {
                props.history.replace(
                  `/places/${placeToSave.type}/${placeToSave._id}`,
                );
              }
            })
            .catch(err => {
              console.log(
                `${t('toast.error_updating_place')} "${placeToSave.name}":`,
                err,
              );
              toast(
                `${t('toast.error_updating_place')} "${placeToSave.name}"`,
                err,
              );
              Sentry.captureException(err);
            });
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_place')} "${placeToSave.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_place')} "${placeToSave.name}"`,
            err,
          );
          Sentry.captureException(err);
        });
    }
  };

  const [detailValue, setDetailValue] = useState(1);

  const handleChange = newValue => {
    setDetailValue(parseInt(newValue, 10));
  };

  return (
    <IonPage>
      <Header title={title} backButton backToPlaces />
      <IonContent>
        {place._id || match.params?.id?.includes('new') ? (
          <IonGrid className="ion-padding-none">
            <IonRow>
              <IonCol
                className="half-n-half"
                size="5"
                style={{height: `${height - 130}px`}}>
                <div className="half-inner-wrap">
                  <PlaceInfo
                    key="PlaceInfo"
                    match={match}
                    history={props.history}
                    place={place}
                    setPlace={setPlace}
                    savePlaceChanges={savePlaceChanges}
                    permissions={permissions}
                    user={user}
                    contacts={contacts}
                  />
                  <PlaceGeneral
                    match={match}
                    history={history}
                    place={place}
                    setPlace={setPlace}
                    savePlaceChanges={savePlaceChanges}
                    selectionType={'site'}
                    permissions={permissions}
                  />
                  <PlaceLocation
                    key="PlaceLocation"
                    match={match}
                    history={history}
                    place={place}
                    setPlace={setPlace}
                    savePlaceChanges={savePlaceChanges}
                    permissions={permissions}
                  />
                </div>
              </IonCol>
              <IonCol
                className="half-n-half background-light-gray"
                size="7"
                style={{height: `${height - 130}px`}}>
                <div className="half-fixed-header">
                  <IonSegment
                    scrollable
                    onIonChange={e => handleChange(e.detail.value)}
                    value={detailValue}
                    className="segment-blue">
                    <IonSegmentButton value="1">
                      <IonLabel>{t('place.charging_stations')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="2">
                      <IonLabel>{t('place.log')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="3">
                      <IonLabel>{t('place.monitoring')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="4">
                      <IonLabel>{t('place.device_management')}</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </div>

                {!match.params.id.includes('new') && (
                  <div className="half-inner-wrap ion-margin-top-half">
                    {detailValue === 1 && (
                      <PlaceChargingStations
                        match={match}
                        history={history}
                        place={place}
                        setPlace={setPlace}
                        savePlaceChanges={savePlaceChanges}
                        selectionType={'site'}
                        permissions={permissions}
                      />
                    )}
                    {detailValue === 2 && (
                      <PlaceLog
                        match={match}
                        history={history}
                        place={place}
                        setPlace={setPlace}
                        savePlaceChanges={savePlaceChanges}
                        selectionType={'site'}
                        permissions={permissions}
                      />
                    )}
                    {detailValue === 3 && (
                      <PlaceMonitoring
                        key={place.id}
                        match={match}
                        place={place}
                        permissions={permissions}
                      />
                    )}
                    {detailValue === 4 && (
                      <PlaceDeviceManagement
                        match={match}
                        place={place}
                        setPlace={setPlace}
                        savePlaceChanges={savePlaceChanges}
                        selectionType={'site'}
                        permissions={permissions}
                      />
                    )}
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Place;
