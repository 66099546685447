import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ErrorTypeInfo from '../../components/accessories/ErrorTypeInfo';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const ErrorType = props => {
  // Misc vars
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the error type
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.guides
      .get(match.params.id)
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.guides, match, props.isLoading, setIsAuth]);

  useTitleEffect(doc.name);

  // Fetch accessories
  const [accessories, setAccessories] = useState([]);
  useEffect(() => {
    databases.accessories
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setAccessories(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [databases.accessories]);

  return (
    <IonPage>
      <Header title={doc.name} backButton />
      <IonContent>
        {doc._id ? (
          <div className="container-narrow">
            <ErrorTypeInfo
              doc={doc}
              match={match}
              history={props.history}
              accessories={accessories}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ErrorType;
