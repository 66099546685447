import React, {useState, useEffect, useContext} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonText,
  IonReorderGroup,
  IonReorder,
} from '@ionic/react';
import {
  trashOutline,
  addOutline,
  arrowForwardOutline,
  reorderTwoOutline,
} from 'ionicons/icons';
import {Paper, FormControl, Slide} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import {DatabaseContext} from '../context/DatabaseContext';
import * as Sentry from '@sentry/react';

const FlowInfo = props => {
  // Misc vars
  const match = props.match;
  const permissions = props.permissions;

  const {t} = useTranslation('link_app');
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Copy error type to a new var
  const [errorType, setErrorType] = useState(props.doc);
  useEffect(() => {
    setErrorType(props.doc);
  }, [match, props.doc]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // Save edited or new flow
  const handleSave = () => {
    // Save error type with updated flow
    databases.guides
      .get(errorType._id)
      .then(() => {
        return databases.guides.put(errorType);
      })
      .then(() => {
        console.log(
          `${t('toast.flow')} "${errorType.flows[props.flowIndex].title}" ${t(
            'toast.updated',
          )}`,
        );
        toast(
          `${t('toast.flow')} "${errorType.flows[props.flowIndex].title}" ${t(
            'toast.updated',
          )}`,
        );
        setIsEdited(false);
        return databases.guides.get(errorType._id, {attachments: true});
      })
      .then(doc => {
        // Update edited accessory state to match new _rev
        if (match.path !== '/error-types/:id/new-flow') {
          setErrorType(doc);
          props.setDoc(doc);
        } else {
          props.setDoc({});
          props.setFlowIndex(null);
          props.history.push('/accessories');
        }
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_flow')} "${
            errorType.flows[props.flowIndex].title
          }":`,
          err,
        );
        toast(
          `${t('toast.error_updating_flow')} "${
            errorType.flows[props.flowIndex].title
          }"`,
          err,
        );
        Sentry.captureException(err);
      });
  };

  // Add blank question to state
  const addQuestion = () => {
    const questions = [...errorType.flows[props.flowIndex].questions];
    let newId =
      questions.length > 0
        ? (
            parseInt(
              Math.max.apply(
                Math,
                questions.map(x => x.id),
              ),
            ) + 1
          ).toString()
        : '1';
    questions.push({id: newId, question: '', answers: []});
    const flows = [...errorType.flows];
    flows[props.flowIndex] = {
      ...errorType.flows[props.flowIndex],
      questions: questions,
    };
    setErrorType({
      ...errorType,
      flows: flows,
    });
    setIsEdited(true);
  };

  // Remove question from state
  const removeQuestion = questionId => {
    const questions = [...errorType.flows[props.flowIndex].questions];
    questions.splice(
      questions.findIndex(question => question.id === questionId),
      1,
    );
    const flows = [...errorType.flows];
    flows[props.flowIndex] = {
      ...errorType.flows[props.flowIndex],
      questions: questions,
    };
    setErrorType({
      ...errorType,
      flows: flows,
    });
    setIsEdited(true);
  };

  // Handle question reordering
  const handleReorder = e => {
    const questions = [...errorType.flows[props.flowIndex].questions];
    const tmp = questions[e.detail.from];
    questions[e.detail.from] = questions[e.detail.to];
    questions[e.detail.to] = tmp;
    e.detail.complete();
    const flows = [...errorType.flows];
    flows[props.flowIndex].questions = questions;
    setErrorType({
      ...errorType,
      questions: questions,
    });
  };

  // Add blank answer to question
  const addAnswer = question => {
    const questions = [...errorType.flows[props.flowIndex].questions];
    const questionIndex = questions.findIndex(x => x.id === question.id);
    const answers = [...question.answers];
    let newId =
      answers.length > 0
        ? (parseInt(answers[answers.length - 1].id) + 1).toString()
        : '1';
    const newAnswers = [...answers, {id: newId, text: '', leads_to: ''}];
    questions[questionIndex] = {
      ...questions[questionIndex],
      answers: newAnswers,
    };
    const flows = [...errorType.flows];
    flows[props.flowIndex] = {
      ...errorType.flows[props.flowIndex],
      questions: questions,
    };
    setErrorType({
      ...errorType,
      flows: flows,
    });
    setIsEdited(true);
  };

  // Remove answer from question
  const removeAnswer = (question, answer) => {
    const questions = [...errorType.flows[props.flowIndex].questions];
    const questionIndex = questions.findIndex(x => x.id === question.id);
    const answers = [...question.answers];
    const answerIndex = answers.findIndex(x => x.id === answer.id);
    answers.splice(answerIndex, 1);
    questions[questionIndex] = {
      ...questions[questionIndex],
      answers: [...answers],
    };
    const flows = [...errorType.flows];
    flows[props.flowIndex] = {
      ...errorType.flows[props.flowIndex],
      questions: questions,
    };
    setErrorType({
      ...errorType,
      flows: flows,
    });
    setIsEdited(true);
  };

  return (
    <>
      <Paper style={{marginTop: '32px'}}>
        <IonGrid className="accessory-info ion-padding-none ion-padding-top ion-padding-right ion-padding-left">
          <IonRow>
            <IonCol className="ion-margin-none">
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="title">{t('flow.name')}</label>
                  </div>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    autoComplete="off"
                    value={errorType.flows[props.flowIndex].title}
                    onChange={e => {
                      const flows = [...errorType.flows];
                      flows[props.flowIndex].title = e.target.value;
                      setErrorType({
                        ...errorType,
                        flows: flows,
                      });
                      setIsEdited(true);
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="description">{t('flow.description')}</label>
                  </div>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    value={errorType.flows[props.flowIndex].description}
                    autoComplete="off"
                    onChange={e => {
                      const flows = [...errorType.flows];
                      flows[props.flowIndex].description = e.target.value;
                      setErrorType({
                        ...errorType,
                        flows: flows,
                      });
                      setIsEdited(true);
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          <div className="ticket-step-input ion-margin-left ion-margin-top">
            <label>{t('flow.questions')}</label>
          </div>
          <IonReorderGroup
            disabled={!permissions?.guides}
            onIonItemReorder={e => handleReorder(e)}>
            {errorType.flows[props.flowIndex].questions.map(
              (question, questionIndex) => {
                return (
                  <Slide direction="up" in={true} key={question.id}>
                    <IonItem lines="full">
                      <IonText
                        className="ion-margin-right question-number ion-weight-500"
                        slot="start">
                        {questionIndex + 1}.
                      </IonText>
                      <IonLabel className="ion-padding-top-half ion-padding-bottom-half ion-padding-left ion-margin-left question-label">
                        <div>
                          <textarea
                            value={question.question}
                            placeholder={t('flow.question')}
                            onChange={e => {
                              const questions = [
                                ...errorType.flows[props.flowIndex].questions,
                              ];
                              const questionIndex = questions.findIndex(
                                x => x.id === question.id,
                              );
                              questions[questionIndex].question =
                                e.target.value;
                              const flows = [...errorType.flows];
                              flows[props.flowIndex] = {
                                ...errorType.flows[props.flowIndex],
                                questions: questions,
                              };
                              setErrorType({
                                ...errorType,
                                flows: flows,
                              });
                              setIsEdited(true);
                            }}
                            disabled={!permissions?.guides}
                          />
                          <IonList>
                            <div className="ticket-step-input ion-margin-left ion-margin-top ion-margin-bottom-half">
                              <label>{t('flow.answers')}</label>
                            </div>
                            {question.answers.map(answer => {
                              return (
                                <Slide direction="up" in={true} key={answer.id}>
                                  <IonItem lines="none">
                                    <IonLabel className="ion-padding-none ion-margin-none">
                                      <div className="ticket-step-row half">
                                        <input
                                          className="ticket-step-input"
                                          type="text"
                                          placeholder={t('flow.answer')}
                                          value={answer.text}
                                          onChange={e => {
                                            const questions = [
                                              ...errorType.flows[
                                                props.flowIndex
                                              ].questions,
                                            ];
                                            const questionIndex =
                                              questions.findIndex(
                                                x => x.id === question.id,
                                              );
                                            const answers = [
                                              ...question.answers,
                                            ];
                                            const answerIndex =
                                              answers.findIndex(
                                                x => x.id === answer.id,
                                              );
                                            answers[answerIndex] = {
                                              id: answer.id,
                                              text: e.target.value,
                                              leads_to: answer.leads_to,
                                            };
                                            questions[questionIndex] = {
                                              ...questions[questionIndex],
                                              answers: [...answers],
                                            };
                                            const flows = [...errorType.flows];
                                            flows[props.flowIndex] = {
                                              ...errorType.flows[
                                                props.flowIndex
                                              ],
                                              questions: questions,
                                            };
                                            setErrorType({
                                              ...errorType,
                                              flows: flows,
                                            });
                                            setIsEdited(true);
                                          }}
                                          disabled={!permissions?.guides}
                                        />
                                        <IonIcon
                                          icon={arrowForwardOutline}
                                          style={{
                                            fontSize: '18px',
                                            transform: 'translateY(4px)',
                                            flexShrink: '0',
                                          }}
                                          color="medium"
                                        />
                                        <input
                                          className="ticket-step-input ion-margin-left-half"
                                          type="text"
                                          placeholder={t('flow.leads_to')}
                                          value={answer.leads_to}
                                          onChange={e => {
                                            const questions = [
                                              ...errorType.flows[
                                                props.flowIndex
                                              ].questions,
                                            ];
                                            const questionIndex =
                                              questions.findIndex(
                                                x => x.id === question.id,
                                              );
                                            const answers = [
                                              ...question.answers,
                                            ];
                                            const answerIndex =
                                              answers.findIndex(
                                                x => x.id === answer.id,
                                              );
                                            answers[answerIndex] = {
                                              id: answer.id,
                                              text: answer.text,
                                              leads_to: e.target.value,
                                            };
                                            questions[questionIndex] = {
                                              ...questions[questionIndex],
                                              answers: [...answers],
                                            };
                                            const flows = [...errorType.flows];
                                            flows[props.flowIndex] = {
                                              ...errorType.flows[
                                                props.flowIndex
                                              ],
                                              questions: questions,
                                            };
                                            setErrorType({
                                              ...errorType,
                                              flows: flows,
                                            });
                                            setIsEdited(true);
                                          }}
                                          disabled={!permissions?.guides}
                                        />
                                        <IonButton
                                          fill="clear"
                                          slot="end"
                                          onClick={() =>
                                            removeAnswer(question, answer)
                                          }
                                          disabled={!permissions?.guides}>
                                          <IonIcon
                                            slot="icon-only"
                                            color="medium"
                                            icon={trashOutline}
                                          />
                                        </IonButton>
                                      </div>
                                    </IonLabel>
                                  </IonItem>
                                </Slide>
                              );
                            })}
                            <IonButton
                              onClick={() => addAnswer(question)}
                              fill="clear"
                              color="primary"
                              disabled={!permissions?.guides}>
                              <IonIcon
                                slot="start"
                                color="primary"
                                icon={addOutline}
                              />
                              {t('flow.add_answer')}
                            </IonButton>
                          </IonList>
                        </div>
                      </IonLabel>
                      <IonButton
                        className="question-remove ion-margin-left-half"
                        fill="clear"
                        slot="end"
                        onClick={() => removeQuestion(question.id)}
                        disabled={!permissions?.guides}>
                        <IonIcon
                          slot="icon-only"
                          color="medium"
                          icon={trashOutline}
                        />
                      </IonButton>
                      <IonReorder
                        slot="end"
                        className="question-reorder ion-margin-left-half">
                        <IonIcon
                          color="medium"
                          icon={reorderTwoOutline}
                          style={{fontSize: '24px'}}
                        />
                      </IonReorder>
                    </IonItem>
                  </Slide>
                );
              },
            )}
          </IonReorderGroup>
        </IonList>
        {match.url !== '/new-flow' && (
          <IonButton
            onClick={() => addQuestion()}
            fill="clear"
            color="primary"
            disabled={!permissions?.guides}>
            <IonIcon slot="start" color="primary" icon={addOutline} />
            {t('flow.add_question')}
          </IonButton>
        )}
      </Paper>
      <div>
        {/* <IonButton style={{ marginTop: "16px", marginBottom: "32px" }} routerLink={`/error-types/${errorType._id}`} className="ion-margin-right" fill="outline" color="danger">
					{t("app.abort")}
				</IonButton> */}
        <IonButton
          style={{marginTop: '16px', marginBottom: '32px'}}
          disabled={!isEdited}
          onClick={() => handleSave()}
          className="ion-margin-right"
          fill="solid"
          color="success">
          {t('app.save')}
        </IonButton>
      </div>
    </>
  );
};

export default FlowInfo;
