import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import FlowInfo from '../../components/accessories/FlowInfo';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import _ from 'lodash';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const NewFlow = props => {
  // Misc vars
  const {match, permissions} = props;
  const {t} = useTranslation('link_app');

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  useTitleEffect(t('error_types.new_flow'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Fetch the error type
  const [doc, setDoc] = useState({});
  useEffect(() => {
    if (_.isEmpty(doc)) {
      databases.guides
        .get(match.params.id)
        .then(result => {
          let newId =
            result.flows.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      result.flows.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';
          const newFlow = {
            id: newId,
            title: '',
            description: '',
            questions: [],
          };
          setDoc({...result, flows: [...result.flows, newFlow]});
        })
        .catch(err => {
          console.log(err);
          Sentry.captureException(err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        });
    }
  }, [databases.guides, match, props.isLoading, doc, setIsAuth]);

  // Get flow index
  const [flowIndex, setFlowIndex] = useState(null);
  useEffect(() => {
    if (doc._id && doc.flows && doc.flows.length > 0) {
      setFlowIndex(doc.flows.length - 1);
    }
  }, [doc]);

  return (
    <IonPage>
      <Header title={t('error_types.new_flow')} backButton />
      <IonContent>
        {doc._id && flowIndex !== null ? (
          <div className="container-narrow">
            <FlowInfo
              doc={doc}
              setDoc={setDoc}
              flowIndex={flowIndex}
              setFlowIndex={setFlowIndex}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewFlow;
