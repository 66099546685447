import React, {useEffect, useState} from 'react';
import {IonText, IonRow, IonCol, IonGrid, IonSpinner} from '@ionic/react';
import {Divider} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useConfig from '../../hooks/useConfig';

const Monitoring = props => {
  const {t} = useTranslation('link_app');
  // const match = props.match

  // API call
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  const [station] = useState(props.station ?? null);

  const {monitoring: grafanaUrls} = useConfig();

  // Copy place to local state
  // const [place, setPlace] = useState()
  // useEffect(() => {
  // 	if (!_.isEmpty(props.place)) {
  // 		setPlace(props.place)
  // 	}
  // }, [match, props.place])

  // Monitoring panels
  const [monitoringPanels, setMonitoringPanels] = useState([]);
  useEffect(() => {
    if (station?.monitoring_panels && station?.monitoring_panels !== '') {
      if (station.type === 'uc') {
        let panels = Object.entries(station.monitoring_panels);
        panels = panels.filter(val => !val[0].includes('control_room'));
        setMonitoringPanels(panels);
      } else {
        let panels = Object.entries(station.monitoring_panels);
        panels = panels.filter(
          val =>
            !val[0].includes('control_room') &&
            !val[0].includes('panel_station_connector'),
        );
        setMonitoringPanels(panels);
      }
    }
  }, [station]);

  const GetSinglePanels = props => {
    if (props.type === 'kempower') {
      return (
        <div
          key={props.index}
          className={`ticket-monitoring-container-panel ${props.val[0]}`}>
          {props.val[0] === 'connectors' ? (
            props.val[1].map(connector => {
              return Object.keys(connector).map(key => {
                return (
                  <object
                    className={key}
                    data={`${grafanaUrls[props.type]}${connector[key]}`}
                    title="Monitoring data"></object>
                );
              });
            })
          ) : (
            <object
              data={`${grafanaUrls[props.type]}${props.val[1]}`}
              title="Monitoring data"></object>
          )}
        </div>
      );
    } else if (props.type === 'ocpp') {
      return (
        <>
          {props.val[1].map(connector => {
            return (
              <div className={`ticket-monitoring-panels-inner panel-type-ocpp`}>
                {Object.keys(connector).map(key => {
                  return (
                    <object
                      key={`monitoring-${key}`}
                      className={key}
                      data={`${grafanaUrls[props.type]}${connector[key]}`}
                      title="Monitoring data"></object>
                  );
                })}
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <div
          key={props.index}
          className={`ticket-monitoring-container-panel ${props.val[0]}`}>
          <object
            data={`${grafanaUrls[props.type]}${props.val[1]}`}
            title="Monitoring data"></object>
        </div>
      );
    }
  };

  return (
    <IonGrid className="ion-padding-none ion-margin-top">
      <IonRow
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <IonCol size="2">
          <IonText>
            <h5>{t('place.monitoring')}</h5>
          </IonText>
        </IonCol>
        <IonCol size="10" style={{display: 'flex', alignItems: 'center'}}>
          <p>
            {station?.name}, UID {station?.id}
          </p>
        </IonCol>
      </IonRow>
      <Divider className="ion-margin-bottom" />

      {station && (
        <div className={`ticket-monitoring-panels panel-type-${station.type}`}>
          {monitoringPanels.length > 0 &&
            monitoringPanels
              .filter(val => val[1] !== '')
              .map((val, i) => {
                return (
                  <GetSinglePanels
                    key={i}
                    val={val}
                    index={i}
                    type={station.type}
                  />
                );
              })}
        </div>
      )}
      {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};
export default Monitoring;
