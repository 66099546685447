import React, {useState} from 'react';
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonRouterOutlet,
  IonIcon,
} from '@ionic/react';
import ErrorType from '../../pages/accessories/ErrorType';
import NewErrorType from '../../pages/accessories/NewErrorType';
import Flow from '../../pages/accessories/Flow';
import NewFlow from '../../pages/accessories/NewFlow';
import Contacts from '../../pages/contacts/Contacts';
import Contact from '../../pages/contacts/Contact';
import NewContact from '../../pages/contacts/NewContact';
import Tickets from '../../pages/tickets/Tickets';
import Ticket from '../../pages/tickets/Ticket';
import Places from '../../pages/places/Places';
import Place from '../../pages/places/Place';
import PlaceAttachment from '../../pages/places/PlaceAttachment';
import User from '../../pages/contacts/User';
import TicketAttachment from '../../pages/tickets/TicketAttachment';
import Accessories from '../../pages/accessories/Accessories';
import Accessory from '../../pages/accessories/Accessory';
import AccessoryAttachment from '../../pages/accessories/AccessoryAttachment';
import NewAccessory from '../../pages/accessories/NewAccessory';
import Manual from '../../pages/accessories/Manual';
import NewManual from '../../pages/accessories/NewManual';
import {Redirect, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Network} from '@capacitor/network';
import {cloudOfflineOutline} from 'ionicons/icons';
import ControlRoom from '../../pages/controlroom/ControlRoom';
import MultiScreenMonitoring from '../../pages/controlroom/MultiScreenMonitoring';
import RfidUsers from '../../pages/ikea/rfid/RfidUsers';
import NewRfidUser from '../../pages/ikea/rfid/NewRfidUser';
import RfidUser from '../../pages/ikea/rfid/RfidUser';
import DocumentPreview from '../../pages/DocumentPreview';
import Dashboard from '../../pages/ikea/dashboard/Dashboard';
import Reports from '../../pages/ikea/reports/Reports';

const Tabs = ({
  isTicketsLoading,
  isPlacesLoading,
  isAccessoriesLoading,
  isGuidesLoading,
  isContactsLoading,
  permissions,
  isIkea,
  ticketsUpdated,
}) => {
  const {t} = useTranslation('link_app');

  // Network status
  const [isConnected, setIsConnected] = useState(true);

  Network.addListener('networkStatusChange', status => {
    status.connected ? setIsConnected(true) : setIsConnected(false);
  });

  return (
    <>
      <Route
        path="/multiscreen-monitoring"
        exact
        render={props => {
          return (
            <MultiScreenMonitoring
              {...props}
              isLoading={isTicketsLoading}
              permissions={permissions}
            />
          );
        }}
      />
      {isIkea ? (
        <IonTabs>
          <IonTabBar slot="top" className="main-header">
            <IonTabButton tab="dashboard" href="/dashboard">
              <IonLabel color="secondary">{t('nav.dashboard')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="reports" href="/reports">
              <IonLabel color="secondary">{t('nav.reports')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="rfid-tags" href="/rfid-users">
              <IonLabel color="secondary">{t('nav.rfid')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
          <IonRouterOutlet>
            <Route
              path="/dashboard"
              exact
              render={props => {
                return (
                  <Dashboard
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/reports"
              exact
              render={props => {
                return (
                  <Reports
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/rfid-users"
              exact
              render={props => {
                return (
                  <RfidUsers
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/rfid-users/:id"
              exact
              render={props => {
                return (
                  <RfidUser
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/new-rfid-user"
              exact
              render={props => {
                return (
                  <NewRfidUser
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/user"
              exact
              render={props => {
                return (
                  <User
                    {...props}
                    isLoading={isTicketsLoading}
                    permissions={permissions}
                  />
                );
              }}
            />
            <Route
              path="/user-manual"
              exact
              render={props => {
                return <DocumentPreview {...props} permissions={permissions} />;
              }}
            />
            <Redirect from="/" to="/dashboard" exact />
            <Redirect from="/login" to="/dashboard" exact />
            <Redirect from="/tickets" to="/dashboard" exact />
          </IonRouterOutlet>
        </IonTabs>
      ) : (
        <>
          <IonTabs>
            <IonTabBar slot="top" className="main-header">
              <IonTabButton tab="control-room" href="/control-room">
                <IonLabel color="secondary">{t('nav.control_room')}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tickets" href="/tickets">
                <IonLabel color="secondary">{t('nav.tickets')}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="places" href="/places">
                <IonLabel color="secondary">{t('nav.places')}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="contacts" href="/contacts">
                <IonLabel color="secondary">{t('nav.contacts')}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="accessories" href="/accessories">
                <IonLabel color="secondary">{t('nav.accessories')}</IonLabel>
              </IonTabButton>
            </IonTabBar>
            <IonRouterOutlet>
              <Route
                path="/control-room"
                exact
                render={props => {
                  return (
                    <ControlRoom
                      {...props}
                      isLoading={isTicketsLoading}
                      permissions={permissions}
                      ticketsUpdated={ticketsUpdated}
                    />
                  );
                }}
              />
              <Route
                path="/tickets"
                exact
                render={props => {
                  return (
                    <Tickets
                      {...props}
                      isLoading={isTicketsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/tickets/:id"
                exact
                render={props => {
                  return (
                    <Ticket
                      {...props}
                      isLoading={isTicketsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/places"
                exact
                render={props => {
                  return (
                    <Places
                      {...props}
                      isLoading={isPlacesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/new-ticket"
                exact
                render={props => {
                  return (
                    <Ticket
                      {...props}
                      isLoading={isTicketsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/places/:id"
                exact
                render={props => {
                  return (
                    <Place
                      {...props}
                      isLoading={isPlacesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/places/:type/:id/attachments/:attachmentId"
                exact
                render={props => {
                  return (
                    <PlaceAttachment
                      {...props}
                      isLoading={isPlacesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/user"
                exact
                render={props => {
                  return (
                    <User
                      {...props}
                      isLoading={isContactsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/tickets/:id/attachments/:attachmentId"
                exact
                render={props => {
                  return (
                    <TicketAttachment
                      {...props}
                      isLoading={isTicketsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/accessories"
                exact
                render={props => {
                  return (
                    <Accessories
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/accessories/:id"
                exact
                render={props => {
                  return (
                    <Accessory
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/new-accessory/"
                exact
                render={props => {
                  return (
                    <NewAccessory
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/accessories/:id/attachments/:attachmentId"
                exact
                render={props => {
                  return (
                    <AccessoryAttachment
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/accessories/:id/manuals/:manualId"
                exact
                render={props => {
                  return (
                    <Manual
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/accessories/:id/new-manual"
                exact
                render={props => {
                  return (
                    <NewManual
                      {...props}
                      isLoading={isAccessoriesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/error-types/:id"
                exact
                render={props => {
                  return (
                    <ErrorType
                      {...props}
                      isLoading={isGuidesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/new-error-type"
                exact
                render={props => {
                  return (
                    <NewErrorType
                      {...props}
                      isLoading={isGuidesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/error-types/:id/flows/:flowId"
                exact
                render={props => {
                  return (
                    <Flow
                      {...props}
                      isLoading={isGuidesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/error-types/:id/new-flow"
                exact
                render={props => {
                  return (
                    <NewFlow
                      {...props}
                      isLoading={isGuidesLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/contacts"
                exact
                render={props => {
                  return (
                    <Contacts
                      {...props}
                      isLoading={isContactsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/contacts/:id"
                exact
                render={props => {
                  return (
                    <Contact
                      {...props}
                      isLoading={isContactsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Route
                path="/new-contact"
                exact
                render={props => {
                  return (
                    <NewContact
                      {...props}
                      isLoading={isContactsLoading}
                      permissions={permissions}
                    />
                  );
                }}
              />
              <Redirect from="/" to="/tickets" exact />
              <Redirect from="/login" to="/tickets" exact />
              <Redirect from="/dashboard" to="/tickets" exact />
            </IonRouterOutlet>
          </IonTabs>
        </>
      )}
      {!isConnected && (
        <>
          <div className="no-connection-background" />
          <div className="no-connection-notice">
            <IonIcon
              className="ion-margin-right-half"
              icon={cloudOfflineOutline}
            />
            {t('app.no_connection')}
          </div>
        </>
      )}
    </>
  );
};

export default Tabs;
