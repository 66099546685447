import React, {useState, useEffect, useContext} from 'react';
import Header from '../../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonItem,
  IonList,
} from '@ionic/react';
import {Paper, FormControl, TextField} from '@material-ui/core';
import Loading from '../../../components/utils/Loading';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import {AuthContext} from '../../../components/context/AuthContext';
import useToast from '../../../hooks/useToast';
import {useTranslation} from 'react-i18next';
import useTitleEffect from '../../../hooks/useTitle';
import * as Sentry from '@sentry/react';

const RfidUser = props => {
  const {t} = useTranslation('link_app_ikea');
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const toast = useToast();

  // Fetch contact
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.lm_delivery_tags
      .get(match.params.id)
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.lm_delivery_tags, match, props.isLoading, setIsAuth]);

  useTitleEffect(doc.name);

  // Save edited contact
  const handleSave = () => {
    databases.lm_delivery_tags
      .get(doc._id)
      .then(() => {
        return databases.lm_delivery_tags.put(doc);
      })
      .then(() => {
        console.log(
          `${t('toast.contact')} "${doc.name}" ${t('toast.updated')}`,
        );
        toast(`${t('toast.contact')} "${doc.name}" ${t('toast.updated')}`);
        setIsEdited(false);
        return databases.lm_delivery_tags.get(doc._id);
      })
      .then(doc => {
        // Update edited contact state to match new _rev
        setDoc(doc);
      })
      .catch(err => {
        console.log(`${t('toast.error_updating_contact')} "${doc.name}":`, err);
        toast(`${t('toast.error_updating_contact')} "${doc.name}"`, err);
        Sentry.captureException(err);
      });
  };

  return (
    <IonPage>
      <Header title={doc.name} backButton />
      <IonContent>
        {doc._id ? (
          <div className="container-narrow">
            <Paper style={{marginTop: '32px'}}>
              <IonList>
                <IonItem
                  lines="none"
                  className="ion-margin-top ion-margin-bottom">
                  <IonText>
                    <h4 className="ion-margin-none">{t('contacts.details')}</h4>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <div className="row">
                    <FormControl className="row-input">
                      <div>
                        <label htmlFor="name">
                          {t('ikea.rfid_users.name')}
                        </label>
                      </div>
                      <TextField
                        id="name"
                        value={doc.name}
                        onChange={e => {
                          setIsEdited(true);
                          setDoc({...doc, name: e.target.value});
                        }}
                        disabled={!permissions?.contacts}
                      />
                    </FormControl>
                  </div>
                </IonItem>

                <IonItem lines="none">
                  <div className="row">
                    <FormControl className="row-input">
                      <div>
                        <label htmlFor="company">
                          {t('ikea.rfid_users.rfid_tag')}
                        </label>
                      </div>
                      <TextField
                        id="rfid"
                        value={doc.rfid}
                        onChange={e => {
                          setIsEdited(true);
                          setDoc({...doc, rfid: e.target.value});
                        }}
                        disabled={!permissions?.contacts}
                      />
                    </FormControl>
                  </div>
                </IonItem>

                <IonItem lines="none">
                  <div className="row">
                    <FormControl className="row-input">
                      <div>
                        <label htmlFor="phone-number">
                          {t('ikea.rfid_users.place')}
                        </label>
                      </div>
                      <TextField
                        id="place"
                        value={doc.place}
                        onChange={e => {
                          setIsEdited(true);
                          setDoc({...doc, place: e.target.value});
                        }}
                        disabled={!permissions?.contacts}
                      />
                    </FormControl>
                  </div>
                </IonItem>
              </IonList>
            </Paper>
            <div>
              <IonButton
                style={{marginTop: '16px', marginBottom: '32px'}}
                disabled={!isEdited && !doc.rfid !== ''}
                onClick={() => handleSave()}
                className="ion-margin-right"
                fill="solid"
                color="success">
                {t('app.save')}
              </IonButton>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default RfidUser;
