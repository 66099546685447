import React, {useEffect, useState} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonSpinner,
  IonIcon,
  IonCard,
} from '@ionic/react';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {chevronDownOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import useConfig from '../../../hooks/useConfig';

const IkeaMonitoring = props => {
  const {t} = useTranslation('link_app_ikea');
  const match = props.match;

  // API call
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  const [stations, setStations] = useState(null);
  const [needPanelsInfo, setNeedPanelsInfo] = useState(true);

  const {ikea_monitoring: grafanaUrls} = useConfig();

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place) && props.place !== place) {
      setPlace(props.place);
      setNeedPanelsInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, props.place]);

  const [chargerTypes, setChargerTypes] = useState(['uc']);
  useEffect(() => {
    setChargerTypes(props.chargerTypes);
  }, [place, props.chargerTypes]);

  useEffect(() => {
    const getPanelInfo = async () => {
      let allStationsPanels = place.doc?.stations
        ?.filter(station => props.chargerTypes.includes(station.type))
        .map((station, i) => {
          if (station?.monitoring_panels && station?.monitoring_panels !== '') {
            let panels = Object.entries(station.monitoring_panels);
            panels = panels.filter(val => !val[0].includes('control_room'));
            return {
              name: station.name,
              type: station.type,
              stationPanels: panels,
              id: station.id,
              station_alias: station.station_alias ?? 'No name set',
            };
          }
          return true;
        });
      setStations(allStationsPanels);
    };
    if (place && needPanelsInfo) {
      getPanelInfo();
      setNeedPanelsInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place, chargerTypes, needPanelsInfo]);

  const GetSinglePanels = React.memo(props => {
    if (props.type === 'kempower') {
      return (
        <div
          key={props.index}
          className={`ikea-monitoring-container-panel ${props.val[0]}`}>
          {props.val[0] === 'connectors' ? (
            props.val[1].map(connector => {
              return Object.keys(connector).map(key => {
                return (
                  <object
                    key={props.val[0] + '-' + key}
                    className={key}
                    data={`${props.grafanaUrls[props.type]}${connector[key]}`}
                    title="Monitoring data"></object>
                );
              });
            })
          ) : (
            <object
              key={props.val[0]}
              data={`${props.grafanaUrls[props.type]}${props.val[1]}`}
              title="Monitoring data"></object>
          )}
        </div>
      );
    } else if (props.type === 'ocpp') {
      return (
        <>
          {props.val[1].map(connector => {
            return (
              <div className={`ikea-monitoring-panels-inner panel-type-ocpp`}>
                {Object.keys(connector).map(key => {
                  return (
                    <object
                      key={`monitoring-${key}`}
                      className={key}
                      data={`${grafanaUrls[props.type]}${connector[key]}`}
                      title="Monitoring data"></object>
                  );
                })}
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <div
          key={props.index}
          className={`ikea-monitoring-container-panel ${props.val[0]}`}>
          <object
            data={`${props.grafanaUrls[props.type]}${props.val[1]}`}
            title="Monitoring data"></object>
        </div>
      );
    }
  });

  return (
    <IonRow>
      {stations &&
        stations.length > 0 &&
        stations.map((station, i) => {
          let tempNumberOfConnectors = null;
          if (station.stationPanels) {
            tempNumberOfConnectors = station?.stationPanels
              .filter(val => val[1] !== '')
              .filter(val => val[0] === 'connectors')
              .map(item => item[1].length)[0];
          }
          return (
            <IonCol
              key={station.id + i}
              className="ion-margin-none ion-padding-left ion-padding-right">
              <IonCard className="ion-padding ion-margin-none ion-margin-bottom ion-margin-top">
                <Accordion key={station.type + '-' + i}>
                  <AccordionSummary
                    expandIcon={
                      <IonIcon
                        className="ion-margin-right-half"
                        slot="start"
                        icon={chevronDownOutline}
                      />
                    }>
                    <IonText color={'black'}>
                      <h5 className="ion-margin-top">
                        {`${station.station_alias} (${station.name})`}
                      </h5>
                    </IonText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className={`ikea-monitoring-panels panel-type-${
                        station.type
                      } ${
                        tempNumberOfConnectors
                          ? 'number-of-connectors-' + tempNumberOfConnectors
                          : 'number-of-connectors-2'
                      }`}>
                      {station?.stationPanels?.length > 0 &&
                        station?.stationPanels
                          .filter(val => val[1] !== '')
                          .map((val, i) => {
                            return (
                              <GetSinglePanels
                                key={station.name + '-' + val[0]}
                                val={val}
                                index={i}
                                type={station.type}
                                grafanaUrls={grafanaUrls}
                              />
                            );
                          })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </IonCard>
            </IonCol>
          );
        })}
      {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonRow>
  );
};

export default IkeaMonitoring;
