import React, {useRef, useState, useEffect} from 'react';
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonSlides,
  IonSlide,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import {
  chevronBack,
  chevronForward,
  play,
  pauseOutline,
  closeCircleOutline,
} from 'ionicons/icons';
import {Button, FormControl, NativeSelect} from '@material-ui/core';
import {ReactComponent as ScreenIcon} from '../../assets/screen.svg';
import {ReactComponent as MultiScreenIcon} from '../../assets/multiscreen.svg';
import {useTranslation} from 'react-i18next';
import useConfig from '../../hooks/useConfig';

const ControlRoomMonitoring = props => {
  const {t} = useTranslation('link_app');
  const {user} = props;
  const slidesRef = useRef(null);
  const slidesRefDC = useRef(null);

  const [autoPlayOn, setAutoPlayOn] = useState(false);

  const places = props.places;
  const currentACPanelIndex = props.currentACPanelIndex ?? 0;
  const currentDCPanelIndex = props.currentDCPanelIndex ?? 0;
  const currentPlace = props.currentPlace;

  const {control_room_monitoring: grafanaUrls} = useConfig();

  const [panels, setPanels] = useState({});

  useEffect(() => {
    setPanels(props.stationPanels);
  }, [props.stationPanels]);

  const [remainingCountdown, setRemainingCountdown] = useState(10);

  useEffect(() => {
    let intervalId = null;
    if (autoPlayOn && (slidesRef || slidesRefDC)) {
      intervalId = setInterval(() => {
        setRemainingCountdown(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setRemainingCountdown(10);
      clearInterval(intervalId);
    }
  }, [autoPlayOn, panels]);

  useEffect(() => {
    if (remainingCountdown === 0) {
      handleSlideChangeStart();
      setRemainingCountdown(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingCountdown]);

  const prevSlide = type => {
    if (type === 'ac') {
      slidesRef.current.slidePrev().then(async val => {
        props.handleACMonitorStationChange(currentACPanelIndex - 1);
      });
    } else if (type === 'dc') {
      slidesRefDC.current.slidePrev().then(async val => {
        props.handleDCMonitorStationChange(currentDCPanelIndex - 1);
      });
    }
  };

  const nextSlide = type => {
    if (type === 'ac') {
      slidesRef.current.slideNext().then(async val => {
        props.handleACMonitorStationChange(currentACPanelIndex + 1);
      });
    } else if (type === 'dc') {
      slidesRefDC.current.slideNext().then(async val => {
        props.handleDCMonitorStationChange(currentDCPanelIndex + 1);
      });
    }
  };

  const handleSlideChangeStart = async () => {
    let index = 0;
    let indexDC = 0;
    const slides = slidesRef.current;
    const slidesDC = slidesRefDC.current;
    if (slides && autoPlayOn) {
      await slides.isEnd().then(async val => {
        if (val) {
          slides.lockSwipes(true);
          if (slidesDC) {
            await slidesDC.getActiveIndex().then(async val => {
              if (val + 1 < panels.dc?.length) {
                nextSlide('dc');
              } else {
                let newPlace = places.findIndex(
                  place => place.doc.site_id === currentPlace.site_id,
                );
                if (newPlace === places.length - 1) {
                  slidesRef.current
                    .stopAutoplay()
                    .then(val => console.log('autoplay stopped'));
                  props.handleMonitorPlaceChange(places[0].doc.site_id);
                } else {
                  slidesRef.current
                    .stopAutoplay()
                    .then(val => console.log('autoplay stopped'));
                  props.handleMonitorPlaceChange(
                    places[newPlace + 1].doc.site_id,
                  );
                }
              }
            });
          } else {
            let newPlace = places.findIndex(
              place => place.doc.site_id === currentPlace.site_id,
            );
            if (newPlace === places.length - 1) {
              slidesRef.current
                .stopAutoplay()
                .then(val => console.log('autoplay stopped'));
              props.handleMonitorPlaceChange(places[0].doc.site_id);
            } else {
              slidesRef.current
                .stopAutoplay()
                .then(val => console.log('autoplay stopped'));
              props.handleMonitorPlaceChange(places[newPlace + 1].doc.site_id);
            }
          }
        } else {
          await slides.getActiveIndex().then(val => (index = val));
          await slidesDC.getActiveIndex().then(val => (indexDC = val));
          props.handleACMonitorStationChange(index);
          if (indexDC + 1 < panels.dc?.length) {
            nextSlide('ac');
            nextSlide('dc');
          } else {
            nextSlide('ac');
          }
        }
      });
    }
  };

  const handleScreenStateChange = screenValue => {
    setAutoPlayOn(false);
    props.setMultiScreen(screenValue);
    if (screenValue === 'true') {
      window.open('/multiscreen-monitoring', '_blank');
    }
  };

  const GetStationName = props => {
    if (panels?.ac?.length > 0 && props.station_type === 'ac') {
      return (
        <>
          <Button
            size="medium"
            onClick={() => prevSlide('ac')}
            disabled={currentACPanelIndex === 0}>
            <IonIcon slot="start" color={'medium'} icon={chevronBack} />
          </Button>
          <h3>
            {panels.ac[currentACPanelIndex]?.station_alias ??
              panels.ac[currentACPanelIndex]?.name}{' '}
            ({currentACPanelIndex + 1} / {panels.ac.length})
          </h3>
          <Button
            size="large"
            onClick={() => nextSlide('ac')}
            disabled={currentACPanelIndex === panels.ac.length - 1}>
            <IonIcon slot="start" color={'medium'} icon={chevronForward} />
          </Button>
        </>
      );
    } else if (panels?.dc?.length > 0 && props.station_type === 'dc') {
      return (
        <>
          <Button
            size="medium"
            onClick={() => prevSlide('dc')}
            disabled={currentDCPanelIndex === 0}>
            <IonIcon slot="start" color={'medium'} icon={chevronBack} />
          </Button>
          <h3>
            {panels.dc[currentDCPanelIndex]?.station_alias ??
              panels.dc[currentDCPanelIndex]?.name}{' '}
            ({currentDCPanelIndex + 1} / {panels.dc.length})
          </h3>
          <Button
            size="large"
            onClick={() => nextSlide('dc')}
            disabled={currentDCPanelIndex === panels.dc.length}>
            <IonIcon slot="start" color={'medium'} icon={chevronForward} />
          </Button>
        </>
      );
    } else {
      return <></>;
    }
  };

  const closeTab = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  return !grafanaUrls ? (
    <></>
  ) : (
    <>
      <IonRow>
        <IonCol className="control-room-monitoring-header">
          <IonCol>
            {currentPlace !== null && (
              <>
                <h1>{currentPlace.name}</h1>
                <p>{currentPlace.address ?? '-'}</p>
              </>
            )}
          </IonCol>
          <IonCol className="flex-shrink">
            {currentPlace !== null && places.length > 0 && (
              <IonCol>
                <IonItem lines="none">
                  <FormControl className="row-input">
                    <NativeSelect
                      value={currentPlace.site_id}
                      onChange={e => {
                        props.handleMonitorPlaceChange(e.target.value);
                      }}
                      variant="filled">
                      <option key={'all'} value={'all'}>
                        Kaikki
                      </option>
                      {places.map((place, i) => {
                        return (
                          <option key={i} value={place.doc.site_id}>
                            {place.doc?.name}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                </IonItem>
              </IonCol>
            )}
            {autoPlayOn ? (
              <IonButton
                size="default"
                fill="solid"
                color="success"
                onClick={() => setAutoPlayOn(false)}>
                <IonIcon slot="start" icon={pauseOutline} />
                &nbsp;&nbsp;Pysäytä
              </IonButton>
            ) : (
              <IonButton
                size="default"
                fill="solid"
                color="success"
                onClick={() => setAutoPlayOn(true)}>
                <IonIcon slot="start" icon={play} />
                &nbsp;&nbsp;Aloita
              </IonButton>
            )}
            {props.match.path !== '/multiscreen-monitoring' ? (
              <IonCol>
                <IonSegment
                  onIonChange={e => handleScreenStateChange(e.detail.value)}
                  value={props.multiScreen.toString()}
                  color="primary"
                  className="screen-icons">
                  <IonSegmentButton value="false">
                    <ScreenIcon className="screen-icon" />
                  </IonSegmentButton>
                  <IonSegmentButton value="true">
                    <MultiScreenIcon className="multi-screen-icon" />
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            ) : (
              <IonButton
                size="large"
                fill="clear"
                color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                title="Close window"
                onClick={() => closeTab()}>
                <IonIcon
                  slot="start"
                  color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                  icon={closeCircleOutline}
                />
              </IonButton>
            )}
          </IonCol>
        </IonCol>
      </IonRow>
      <IonRow className="grafana-monitoring-panels">
        <IonCol className="grafana-monitoring-panel">
          {panels?.ac !== null ? (
            <>
              <IonRow>
                <IonCol size="auto">
                  <h2>{t('place.ac_charging_stations')}</h2>
                </IonCol>
              </IonRow>
              {panels?.ac?.length > 0 && (
                <IonRow className="ion-justify-content-center ion-margin-top">
                  {slidesRef.current && (
                    <IonCol size="auto" class="grafana-monitoring-panel-select">
                      <GetStationName station_type="ac" />
                    </IonCol>
                  )}
                </IonRow>
              )}
              <div className="responsive-iframe-container">
                <IonSlides
                  key={panels?.ac?.map(slide => slide.panelID).join('_')}
                  ref={slidesRef}
                  pager={false}
                  options={{
                    initialSlide: 0,
                    speed: 500,
                  }}>
                  {panels?.ac?.length > 0 &&
                    panels?.ac?.map((panel, i) => {
                      return (
                        <IonSlide key={i}>
                          <object
                            className="responsive-iframe"
                            data={`${grafanaUrls[panel.type]}${panel.panelID}`}
                            title="Monitoring data"></object>
                        </IonSlide>
                      );
                    })}
                </IonSlides>
              </div>
            </>
          ) : (
            <h3>No monitoring panel found</h3>
          )}
        </IonCol>
        <IonCol className="grafana-monitoring-panel">
          {panels?.dc !== null ? (
            <>
              <IonRow>
                <IonCol size="auto">
                  <h2>{t('place.dc_charging_stations')}</h2>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-margin-top">
                {slidesRefDC.current && (
                  <IonCol size="auto" class="grafana-monitoring-panel-select">
                    <GetStationName station_type="dc" />
                  </IonCol>
                )}
              </IonRow>
              <div className="responsive-iframe-container">
                <IonSlides
                  key={panels?.dc?.map(slide => slide.panelID).join('_')}
                  ref={slidesRefDC}
                  pager={false}
                  options={{
                    initialSlide: 0,
                    speed: 500,
                  }}>
                  {panels?.dc?.length > 0 &&
                    panels?.dc?.map((panel, i) => {
                      return (
                        <React.Fragment key={i}>
                          <IonSlide>
                            <object
                              className="responsive-iframe"
                              data={`${grafanaUrls[panel.type]}${
                                panel.panelID
                              }`}
                              title="Monitoring data"></object>
                          </IonSlide>
                        </React.Fragment>
                      );
                    })}
                </IonSlides>
              </div>
            </>
          ) : (
            <h3>No monitoring panel found</h3>
          )}
        </IonCol>
      </IonRow>
    </>
  );
};
export default ControlRoomMonitoring;
